import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import BannerMenuComp from '../components/bannermenu';
import SearchComp from '../components/search';
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import movies from "./dummy";
import { Base64 } from 'js-base64';
import { axiosInstance } from '../Services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function Transaction(props) {

    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    const [loadedapi, setloadedapi] = useState(false);
    const [transactiondetails, settransactiondetails] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        getapplicationformdetails();
        setshowloader(false);
        localStorage.removeItem("refid");
        localStorage.removeItem("tdid");
        localStorage.removeItem("requestid");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    function getapplicationformdetails() {
        setloadedapi(true);
        let userid_o = 0;
        let requestid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
            requestid_o = localStorage.getItem('requestid');
        }
        let mydatas = {
            "userid": userid_o,
        }

        axiosInstance.post('/Transaction', mydatas)
            .then((res) => {
                settransactiondetails(res.data.data);
                setloadedapi(false);
            })
            .catch((err) => {
                setloadedapi(false);
                console.log(err);
            });
    }

    function handeledit(id, status, payment_status) {
        if (payment_status === 'completed') {
            localStorage.setItem('tdid', id);
            navigate("/transactiondetails");
        }
        if (payment_status === 'Due') {
            localStorage.setItem('tdid', id);
            navigate("/transactiondetails");
        }
        if (status === 'draft') {
            localStorage.setItem('refid', id);
            navigate("/editapplicationform");
        }
        if (payment_status === 'initiated') {
            localStorage.setItem('requestid', id);
            navigate("/payment");
        }
        if (status === 'initiated' && payment_status === 'initiated') {
            localStorage.setItem('requestid', id);
            navigate("/payment");
        }
        if (status === 'initiated' && payment_status === 'completed') {
            localStorage.setItem('tdid', id);
            navigate("/transactiondetails");
        }
        if (status === 'cancelled') {
            localStorage.setItem('tdid', id);
            navigate("/transactiondetails");
        }
    }

    const columns = [
        {
            name: "Sl no.",
            selector: (row) => row.SNo,
            width: "6%",
        },
        {
            name: "Reference ID",
            width: "14%",
            cell: (row) => (
                <div className="App">
                    <div className="openbtn text-center" onClick={() => handeledit(row.refid, row.status, row.payment_status)}>
                        <p className='p-0 m-0 text-start'> {row.reference_id}</p>
                    </div>
                </div>
            ),
        },
        {
            name: "Department",
            width: "15%",
            cell: (row) => (
                <div className="App">
                    <div className="text-center">
                        {
                            row.application.map((value, index) => {
                                return (<p className='p-0 m-0 text-start' key={index}> {value.dept_name}</p>)
                            })}
                    </div>
                </div>
            ),
        },
        {
            name: "Service",
            width: "25%",
            cell: (row) => (
                <div className="App">
                    <div className="text-center">
                        {
                            row.application.map((value, index) => {
                                return (<p className='p-0 m-0 text-start' key={index}> {value.serves_name}</p>)
                            })}
                    </div>
                </div>
            )
        },
        {
            name: "Status",
            width: "20%",
            selector: (row) => row.status_desc,
        },
        {
            name: "Total Charges",
            width: "10%",
            cell: (row) => (
                <div className="App">
                    <div className="text-center">
                        <p className='p-0 m-0 text-start'>&#x20b9; {row.total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </div>
                </div>
            )
        },
        {
            name: "Amount Paid",
            width: "10%",
            cell: (row) => (
                <div className="App">
                    <div className="text-center">
                        <p className='p-0 m-0 text-start'>&#x20b9; {row.paid_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </div>
                </div>
            )
        },
        /** {
             button: true,
             name: "Action ",
             width: "120px",
             cell: (row) => (
                 <div className="App">
                     <div className="openbtn text-center" onClick={() => handeledit(row.refid, row.status, row.payment_status)}>
                         {/* <FontAwesomeIcon icon={faEye} color={'#09988e'} /> *//**}
{
row.payable_now_amount > 0 ?
<span className="badge btn-warning p-2 text-black fs-12">
   Make Payment
</span>
: row.status === 'draft' ?
   <span className="badge btn-primary p-2 fs-12">
       Resume Application
   </span>
   :
   <>
       <span className="badge btn-secondary p-2 fs-12">
           View Details
       </span>
   </>
}

</div>
</div>
)
} */
    ];

    return (
        <>
            <HeaderComp />
            <main role="main">
                <section id='main_banner'>
                    <Container fluid className='bg-semi-dark' style={{ paddingBottom: 40 }} >
                        <Container>
                            <div className='intro-section mobintro-section'>
                                <Row>
                                    <Col className='pdb-10'>
                                        <BannerMenuComp />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pdb-20'>
                                        <SearchComp />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner custu_padd">
                                    <h4><span style={{ color: "#6c757d" }}>My Transaction</span></h4>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-cust" style={{ paddingTop: "20px", paddingBottom: "150px" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <div className="card">
                                    <DataTable
                                        columns={columns}
                                        data={transactiondetails}
                                        pagination
                                        highlightOnHover
                                        pointerOnHover
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <FooterComp />
            </main>
        </>
    );
}

export default Transaction;

