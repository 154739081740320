import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import BannerMenuComp from '../components/bannermenu';
import SearchComp from '../components/search';
import { useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import { axiosInstance } from '../Services';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTrash, faUserCircle, faDownload, faRefresh } from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-modal/styles.css';
import BounceLoader from "react-spinners/BounceLoader";
import { Modal } from 'react-responsive-modal';
import * as device from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';

function Transactiondetails(props) {
    const childCompRef = useRef();
    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    const [showcomments, setshowcomments] = useState(false);
    const [loadedapi, setloadedapi] = useState(false);
    const [transactiondetails, settransactiondetails] = useState([]);
    const [commentdetails, setcommentdetails] = useState([]);
    const [transaction_cmd, settransaction_cmd] = useState('');
    const [TotalPayable, setTotalPayable] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerMobile, setCustomerMobile] = useState('');
    const [CustomerEmail, setCustomerEmail] = useState('');

    // set loggin details
    const [loggingstatus, setloggingstatus] = useState(false);
    const [loggedusername, setloggedusername] = useState('');
    const [userid, setuserid] = useState(0);
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        else {
            if (localStorage.getItem('tdid') === null && localStorage.getItem('tdid') === undefined) {
                navigate("/");
            }
            else {
                if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                    setloggingstatus(localStorage.getItem('logging_status'));
                    setloggedusername(Base64.decode(localStorage.getItem('gsun')));
                    setuserid(Base64.decode(localStorage.getItem('gsud')));
                } else {
                    setloggingstatus(false);
                }
                getapplicationformdetails();
                setshowloader(false);
            }
        }

        localStorage.removeItem("refid");
        localStorage.removeItem("requestid");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    function getapplicationformdetails() {
        setloadedapi(true);
        let userid_o = 0;
        let requestid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
            requestid_o = localStorage.getItem('tdid');
        }

        if (requestid_o === 0) {
            navigate('/');
        }
        else {
            let myarrayss = requestid_o.split(',');
            let mydatas = {
                "userid": userid_o,
                "requestid": myarrayss,
            }
            axiosInstance.post('/Transaction/get', mydatas)
                .then((res) => {
                    settransactiondetails(res.data.data);
                    if (res.data.data[0]['commentdetails_array'].length <= 1) {
                        setshowcomments(true);
                    }
                    else {
                        setshowcomments(false);
                    }
                    if (res.data.data.length > 0) {
                        setcommentdetails(res.data.data[0]['commentdetails_array'][0]);
                        settransaction_cmd(res.data.data[0]['transaction_cmd']);
                        setTotalPayable(res.data.data[0]['total_payable']);
                        setCustomerName(res.data.data[0]['customer_name']);
                        setCustomerMobile(res.data.data[0]['customer_mobile']);
                        setCustomerEmail(res.data.data[0]['customer_email']);
                    }
                    setloadedapi(false);
                })
                .catch((err) => {
                    setloadedapi(false);
                    console.log(err);
                });
        }
    }

    function handeleditform() {
        let requestid_o = 0;
        if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
            requestid_o = localStorage.getItem('tdid');
        }
        var url = window.location.href;
        var parts = url.split('/');
        localStorage.setItem('refid', requestid_o);
        navigate("/editapplicationform/");
        if (parts[3] === 'editapplicationform') {
            window.location.reload();
        }
    }

    const [commentsmodal, setcommentsmodal] = useState(false);
    const commentsmodalclose = () => {
        setcommentsmodal(false);
        setFomdatas({
            comment_msg: '',
            comment_id: 0,
            reply_id: 0,
            doc_selected: '',
            doc_selected_name: '',
            doc_url: '',
            selected_file_dd: false,
            data_type: ''
        });
    }
    const commentsmodalopen = () => {
        if (loggingstatus === true || loggingstatus === 'true') {
            setcommentsmodal(true);
            cleardatas();
            setFomdatas({
                ...form_datas,
                comment_id: 0,
                data_type: "insert"
            });
        }
        else {
            // openloginmodal();
        }
    }

    const [form_datas, setFomdatas] = useState({
        comment_msg: '',
        comment_id: 0,
        reply_id: 0,
        doc_selected: '',
        doc_selected_name: '',
        doc_url: '',
        selected_file_dd: false,
        data_type: ''
    });

    const [form_errormsg, setformerrormsg] = useState({
        comment_msg_errormsg: "",
        doc_selected_errormsg: "",
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const handelcommentsonchange = (e) => {
        setFomdatas({
            ...form_datas,
            comment_msg: e.target.value,
        });
    }

    function handeldocumentformonchange(event) {
        setFomdatas({
            ...form_datas,
            doc_selected: event.target.files[0],
            doc_selected_name: event.target.files[0].name,
            doc_url: URL.createObjectURL(event.target.files[0]),
            selected_file_dd: true
        });
    }

    function handelremovedoce(e) {
        setFomdatas({
            ...form_datas,
            doc_selected: '',
            doc_selected_name: '',
            doc_url: '',
            selected_file_dd: false
        });
    }

    function formvalidation() {
        let comment_msg_errormsg = '';
        let doc_selected_errormsg = '';

        if (!form_datas.comment_msg) {
            comment_msg_errormsg = "* Message cannot be blank";
        }

        if (form_datas.doc_selected.size !== undefined && form_datas.doc_selected.size >= 2000000) {
            doc_selected_errormsg = "* File too large. File must be less than 2 megabytes.";
        }

        if (comment_msg_errormsg || doc_selected_errormsg) {
            setformerrormsg({
                doc_selected_errormsg: doc_selected_errormsg,
                comment_msg_errormsg: comment_msg_errormsg
            });
            return false;
        }
        else {
            return true;
        }
    }

    function handlecommentsubmit() {
        const validated = formvalidation();
        if (validated) {
            setshowloader(true);
            setformerrormsg({
                comment_msg_errormsg: "",
            });
            let requestid_o = 0;
            if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
                requestid_o = localStorage.getItem('tdid');
            }
            const formData = new FormData();
            formData.append("comment_id", form_datas.comment_id);
            formData.append("comment_msg", form_datas.comment_msg);
            formData.append("reply_id", form_datas.reply_id);
            formData.append("comments_file", form_datas.doc_selected);
            formData.append("userid", userid);
            formData.append("transaction_id", requestid_o);
            formData.append("doc_url", form_datas.doc_url);
            if (form_datas.data_type === 'insert') {
                axiosInstance.post('/Paymentdetails/insertcomments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status === 'success') {
                            seterror_msgs({
                                show_success_msg: true,
                                success_msg: res.data.msg,
                                show_error_msg: false,
                                error_msg: ''
                            });
                            setFomdatas({
                                comment_msg: '',
                                comment_id: 0,
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            getapplicationformdetails();
                            setshowloader(false);
                            commentsmodalclose();
                        }
                        else if (res.data.status === 'failed') {
                            seterror_msgs({
                                show_success_msg: false,
                                success_msg: '',
                                show_error_msg: true,
                                error_msg: res.data.msg
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            setshowloader(false);
                        }
                    })
                    .catch((err) => {
                        seterror_msgs({
                            ...error_msgs,
                            show_error_msg: true,
                            error_msg: 'Something went wrong please try again'
                        });
                        setshowloader(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    })
            }
            else {
                axiosInstance.post('/Paymentdetails/updatecomments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status === 'success') {
                            seterror_msgs({
                                show_success_msg: true,
                                success_msg: res.data.msg,
                                show_error_msg: false,
                                error_msg: ''
                            });
                            setFomdatas({
                                comment_msg: '',
                                comment_id: 0,
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            getapplicationformdetails();
                            setshowloader(false);
                            commentsmodalclose();
                        }
                        else if (res.data.status === 'failed') {
                            seterror_msgs({
                                show_success_msg: false,
                                success_msg: '',
                                show_error_msg: true,
                                error_msg: res.data.msg
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            setshowloader(false);
                        }
                    })
                    .catch((err) => {
                        seterror_msgs({
                            ...error_msgs,
                            show_error_msg: true,
                            error_msg: 'Something went wrong please try again'
                        });
                        setshowloader(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    })
            }
        }
    }

    function handreplycomments(id) {
        if (loggingstatus === true || loggingstatus === 'true') {
            setcommentsmodal(true);
            cleardatas();
            setFomdatas({
                ...form_datas,
                comment_id: id,
                data_type: "insert"
            });
        }
        else {
            // openloginmodal();
        }
    }

    function handeleditcomments(value, type) {
        if (loggingstatus === true || loggingstatus === 'true') {
            let selected_file_dd = false;
            if (value.comments_file !== '') {
                selected_file_dd = true;
            }

            if (type === 'comment') {
                setFomdatas({
                    ...form_datas,
                    comment_id: value.comment_id,
                    comment_msg: value.comment_msg,
                    doc_selected: value.comments_file,
                    selected_file_dd: selected_file_dd,
                    doc_url: value.comments_file,
                    doc_selected_name: value.comments_file_name,
                    reply_id: 0,
                    data_type: "edit"
                });
            }
            if (type === 'edit') {
                setFomdatas({
                    ...form_datas,
                    comment_id: value.comment_id,
                    comment_msg: value.comment_msg,
                    doc_selected: value.comments_file,
                    doc_url: value.comments_file,
                    doc_selected_name: value.comments_file_name,
                    selected_file_dd: selected_file_dd,
                    reply_id: value.reply_id,
                    data_type: "edit"
                });
            }
            setcommentsmodal(true);
            cleardatas();
        }
        else {
            // openloginmodal();
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

    function makepaymentsubmit(transaction_id, refid, user_id, application_id, balance_total, serves_name, total_amount, payable_now_amount, paid_total) {
        // create order id in razorpay
        let my_ordervalues = {
            "total_pay": payable_now_amount,
            // "total_pay": 1,
            "": payable_now_amount,
        }
        let order_id_pay = 0;
        setshowloader(true);
        axiosInstance.post('/Paymentdetails/crateorder', my_ordervalues)
            .then((res) => {
                if (res.data.status === 'success') {
                    order_id_pay = res.data.order_id;
                    initatepaymentstatus(order_id_pay, transaction_id, refid, user_id, application_id, balance_total, serves_name, total_amount, payable_now_amount, paid_total);
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    function initatepaymentstatus(order_id_pay, transaction_id, refid, user_id, application_id, balance_total, serves_name, total_amount, payable_now_amount, paid_total) {
        let totab_blc = balance_total - payable_now_amount;
        let paid_total_i = paid_total + payable_now_amount;
        let JSONvalue = {};
        JSONvalue.payable_amount = payable_now_amount;
        JSONvalue.balanceamout = totab_blc;
        JSONvalue.total_amount = total_amount;
        JSONvalue.total_amount_paid = paid_total_i;
        JSONvalue.payment_id = '';
        JSONvalue.user_id = user_id;
        JSONvalue.transaction_id = transaction_id;
        JSONvalue.refid = refid;
        JSONvalue.application_id = application_id;
        var options = {
            "key": "rzp_live_sgacU9tOXLx67Y",
            "amount": (payable_now_amount * 100),
            "currency": "INR",
            "name": "Gserves",
            "order_id": order_id_pay,
            "description": "",
            "image": "http://65.1.59.105/assets/img/logo.png",
            "handler": function (response) {
                JSONvalue.payment_id = response.razorpay_payment_id;
                JSONvalue.payment_status = 'completed';
                insertpayment_details(JSONvalue);
            },
            "prefill": {
                "name": CustomerName,
                "email": CustomerEmail,
                "contact": CustomerMobile
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#09988e"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on('payment.failed', function (response) {
            // rzp1.close();
            // e.preventDefault();
        });
    }

    function insertpayment_details(values) {
        setshowloader(true);
        axiosInstance.post('/Paymentdetails/updatependingpayments', values)
            .then((res) => {
                if (res.data.status === 'success') {
                    toast.success("Payment created successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.SUCCESS,
                        className: 'rotateY animated'
                    });
                    getapplicationformdetails();
                }
                if (res.data.status === 'failed') {
                    toast.error("Something went wrong please try again", {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.ERROR,
                        className: 'rotateY animated'
                    });
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    function handeldownloadapplication() {
        setshowloader(true);
        let myarrayss = []
        if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
            let requestid_o = localStorage.getItem('tdid');
            myarrayss = requestid_o.split(',');
        }
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewapplication', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    if (res.data.data.length > 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            const newWindow = window.open(res.data.data[i], '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        }
                    }
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [documentmodal_datas, setdocumentmodal_datas] = useState([]);

    function handelviewdocumentsupload() {
        setshowloader(true);
        let myarrayss = []
        if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
            let requestid_o = localStorage.getItem('tdid');
            myarrayss = requestid_o.split(',');
        }
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewdocuments', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setdocumentmodal_datas(res.data.data['application_data']);
                    documentmodalopen();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [documentmodal, setdocumentmodal] = useState(false);
    const documentmodalclose = () => {
        setdocumentmodal(false);
    }
    const documentmodalopen = () => {
        setdocumentmodal(true);
    }


    const [receiptmodal_data, setreceiptmodal_data] = useState([]);
    function handeldownloadreceipt(refid, user_id) {
        setshowloader(true);
        let JSONvalue = {
            "refid": refid,
            "userid": user_id,
        }
        axiosInstance.post('/Applicationform/getreceiptdetails', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setreceiptmodal_data(res.data.data);
                    receiptmodalopen();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [receiptmodal, setreceiptmodal] = useState(false);
    const receiptmodalclose = () => {
        setreceiptmodal(false);
    }
    const receiptmodalopen = () => {
        setreceiptmodal(true);
    }

    function getdownloadreceipturl(payment_receipt_id, refid, user_id) {
        setshowloader(true);
        let JSONvalue = {
            "refid": refid,
            "userid": user_id,
            "payment_receipt_id": payment_receipt_id,
        }
        axiosInstance.post('/Applicationform/generatereceipt', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    const newWindow = window.open(res.data.data, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });

    }

    function handelbackbutton_dd() {
        navigate("/transaction");
    }


    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }
            <ToastContainer />
            <HeaderComp ref={childCompRef} />
            <main role="main">
                <section id='main_banner'>
                    <Container fluid className='bg-semi-dark' style={{ paddingBottom: 40 }} >
                        <Container>
                            <div className='intro-section mobintro-section'>
                                <Row>
                                    <Col className='pdb-10'>
                                        <BannerMenuComp />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pdb-20'>
                                        <SearchComp />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner pt-5 pb-2">
                                    <h4><span style={{ color: "#6c757d" }}>Transaction Details</span></h4>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    transactiondetails.length > 0 ?
                        <>
                            {
                                transactiondetails.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <section >
                                                <div className="container">
                                                    <div className="card  mt-3"
                                                        style={{ width: "auto", height: "auto", padding: "10px", background: "#d6e8ea" }}>

                                                        <div className="row p-3">
                                                            <div className="col-md-8 p-0">
                                                                <p className="m-0 p-0 mb-1"><b>Application form details</b></p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <p className="m-0 p-0 mb-1 text-end">
                                                                    <button class="btn btn-info btn-sm mr-3" onClick={() => handelbackbutton_dd()}>Back</button>
                                                                </p>
                                                            </div>

                                                            <div className="col-md-12" style={{ padding: "20px 13px 0px 0px" }}>
                                                                {
                                                                    value.application[0].form_details.length > 0 ?
                                                                        <>
                                                                            {
                                                                                value.application[0].form_details.map((form_val, index_form) => {
                                                                                    return (
                                                                                        <React.Fragment key={index_form}>
                                                                                            {
                                                                                                form_val.display_this === 'yes' ?
                                                                                                    <div className=" row mb-3" >
                                                                                                        <div className="col-4">
                                                                                                            <span className='text-start'>{form_val.display_name}  </span>
                                                                                                            <span className='float-end'>: </span>
                                                                                                        </div>
                                                                                                        <div className="col-6">
                                                                                                            {form_val.form_value}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null
                                                                                            }
                                                                                        </React.Fragment>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                        :
                                                                        null}
                                                            </div>
                                                        </div>

                                                        <div className="row p-3">
                                                            <div className="col-md-8 p-0">
                                                                <p className="m-0 p-0 mb-1"><b>Transaction Details and application status</b></p>
                                                            </div>
                                                            <div className="col-md-6" style={{ padding: "20px 13px 0px 0px" }}>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Reference ID  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.refid}
                                                                    </div>
                                                                </div>
                                                                {/* <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Payment ID  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.payment_id}
                                                                    </div>
                                                                </div> */}
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Application submitted  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.created_date}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Department Name </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.dept_name}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Service Name  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.serves_name}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    value.addon_name !== '' && value.addon_name !== null ?
                                                                        <>
                                                                            <div className=" row mb-3">
                                                                                <div className="col-5">
                                                                                    <span className='text-start'>Addon Name  </span>
                                                                                    <span className='float-end'>: </span>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    {value.addon_name}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }
                                                                {
                                                                    value.application_mode == 'courier' ?
                                                                        <>
                                                                            <div className=" row mb-3">
                                                                                <div className="col-5">
                                                                                    <span className='text-start'>Application collection </span>
                                                                                    <span className='float-end'>: </span>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    Will be sent by courier
                                                                                </div>
                                                                            </div>
                                                                            <div className=" row mb-3">
                                                                                <div className="col-5">
                                                                                    <span className='text-start'>Courier Address</span>
                                                                                    <span className='float-end'>: </span>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    {value.courier_details}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : value.application_mode == 'visit' ?
                                                                            <>
                                                                                <div className=" row mb-3">
                                                                                    <div className="col-5">
                                                                                        <span className='text-start'>Application collection </span>
                                                                                        <span className='float-end'>: </span>
                                                                                    </div>
                                                                                    <div className="col-7">
                                                                                        Please visit my place
                                                                                    </div>
                                                                                </div>
                                                                                <div className=" row mb-3">
                                                                                    <div className="col-5">
                                                                                        <span className='text-start'>Address</span>
                                                                                        <span className='float-end'>: </span>
                                                                                    </div>
                                                                                    <div className="col-7">
                                                                                        {value.address_line_one} , {value.preferred_date} , {value.preferred_time}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : value.application_mode == 'uploaded' ?
                                                                                <>
                                                                                    <div className=" row mb-3">
                                                                                        <div className="col-5">
                                                                                            <span className='text-start'>Application collection </span>
                                                                                            <span className='float-end'>: </span>
                                                                                        </div>
                                                                                        <div className="col-7">
                                                                                            Already uploaded online
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : null
                                                                }

                                                            </div>
                                                            <div className="col-md-6" style={{ padding: "20px 13px 0px 0px" }}>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Application Status  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {value.status_desc}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    value.remarks !== '' && value.remarks !== null ?
                                                                        <>
                                                                            <div className=" row mb-3">
                                                                                <div className="col-5">
                                                                                    <span className='text-start'>Remarks</span>
                                                                                    <span className='float-end'>: </span>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    {value.remarks}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                }


                                                                {/* <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Payment Status </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        {
                                                                            value.payment_status === 'initiated' ?
                                                                                <>
                                                                                    <span className="badge btn-secondary pb-1 pt-1 pl-2 pr-2">
                                                                                        Initiated
                                                                                    </span>
                                                                                </>
                                                                                : value.payment_status === 'draft' ?
                                                                                    <>
                                                                                        <span>Draft</span>
                                                                                    </>
                                                                                    : value.payment_status === 'inprogress' ?
                                                                                        <>
                                                                                            <span className="badge btn-primary pb-1 pt-1 pl-2 pr-2">
                                                                                                In-progress
                                                                                            </span>
                                                                                        </>
                                                                                        : value.payment_status === 'Due' ?
                                                                                            <>
                                                                                                <span className="badge btn-danger pb-1 pt-1 pl-2 pr-2">
                                                                                                    Pending
                                                                                                </span>
                                                                                            </>
                                                                                            : value.payment_status === 'completed' ?
                                                                                                <>
                                                                                                    <span className="badge btn-success pb-1 pt-1 pl-2 pr-2" >
                                                                                                        Completed
                                                                                                    </span>
                                                                                                </>
                                                                                                : value.payment_status === '' ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    null
                                                                        }
                                                                    </div>
                                                                </div> */}
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Total Charges  </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        &#x20b9; {value.total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}


                                                                        {/* {parseFloat(value.total_amount).toFixed(2)} */}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Paid Total </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        &#x20b9; {value.paid_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        {/* {parseFloat(value.paid_total).toFixed(2)} */}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Balance Amount </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        &#x20b9; {value.balance_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                                                                        {/* {parseFloat(value.balance_total).toFixed(2)} */}
                                                                    </div>
                                                                </div>
                                                                <div className=" row mb-3">
                                                                    <div className="col-5">
                                                                        <span className='text-start'>Payable Now </span>
                                                                        <span className='float-end'>: </span>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        &#x20b9; {value.payable_now_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        {/* {parseFloat(value.balance_total).toFixed(2)} */}
                                                                    </div>
                                                                    {
                                                                        value.payable_now_amount > 0 ?
                                                                            <>
                                                                                <div className="col-3">
                                                                                    <span className="badge cursor-pointer btn-warning p-2 text-black fs-12" onClick={() => makepaymentsubmit(value.transaction_id_d, value.refid_d, value.user_id_d, value.application_id_d, value.balance_total, value.serves_name, value.total_amount, value.payable_now_amount, value.paid_total)}>
                                                                                        Make Payment
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row p-3 text-center">
                                                            <div className="col-3"></div>
                                                            <div className="col-3">
                                                                <p className='p-0 m-0 cursor-pointer' onClick={() => handeldownloadapplication()}><b>Download Application</b></p>
                                                            </div>
                                                            <div className="col-3">
                                                                <p className='p-0 m-0 cursor-pointer' onClick={() => handelviewdocumentsupload()}><b>View Documents</b></p>
                                                            </div>
                                                            {/* <div className="col-3">
                                                                <p className='p-0 m-0 cursor-pointer'><b>Download Invoice</b></p>
                                                            </div> */}
                                                            <div className="col-3">
                                                                <p className='p-0 m-0 cursor-pointer' onClick={() => handeldownloadreceipt(value.refid_d, value.user_id_d)}><b>Download Receipt</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        </React.Fragment>
                                    )
                                })}

                            {showcomments === true ?
                                <>
                                    <section>
                                        <div className="container showcomments" style={{ paddingTop: "20px", paddingBottom: "50px" }}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card"
                                                        style={{ width: "auto", height: "auto", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047" }}>
                                                        <div className="card-body ">
                                                            {commentdetails.length > 0 ?
                                                                <React.Fragment>
                                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                                            <div className="col-7">
                                                                                Comments
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <div className="float-end">
                                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="text-center cursor-pointer" onClick={() => getapplicationformdetails()}>
                                                                                    <p className='m-0 mt-2'><FontAwesomeIcon icon={faRefresh} /> Refresh</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {commentdetails.map((value, index) => {
                                                                        return (
                                                                            <div style={{ padding: "20px", paddingBottom: "0px" }} key={index} >
                                                                                <div className='main_comments'>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-1">
                                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                                        </div>
                                                                                        <div className="col-sm-11">
                                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                                <b>{value.user_name}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-sm-1"></div>
                                                                                        <div className="col-sm-9">
                                                                                            <p className='p-0 mt-2 mb-1'>{value.comment_msg}</p>
                                                                                            {
                                                                                                value.comments_file !== '' ?
                                                                                                    <a class="text-decoration-none" href={value.comments_file} target="_blank">
                                                                                                        <FontAwesomeIcon icon={faFilePdf} /> Click here to view / download the attachment
                                                                                                    </a>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                            }
                                                                                            <p className='p-0 mt-2 mb-3'>{value.updated_on}</p>

                                                                                        </div>
                                                                                        <div className="col-sm-2" style={{ paddingTop: "2px" }}>
                                                                                            <div>
                                                                                                <button className="btn btn-info btn-sm mr-3" onClick={() => handreplycomments(value.comment_id)}>Reply</button>
                                                                                                {parseInt(userid) === parseInt(value.user_id) ?
                                                                                                    <>
                                                                                                        &nbsp; &nbsp;<button className="btn btn-primary btn-sm ml-2" onClick={() => handeleditcomments(value, "comment")}>Edit</button>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {value.reply_comments.length > 0 ?
                                                                                    <>

                                                                                        {value.reply_comments.map((value2, index2) => {
                                                                                            return (
                                                                                                <div style={{ padding: "20px", paddingBottom: "0px", paddingTop: "15px" }} key={index2}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-1">
                                                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                                                        </div>
                                                                                                        <div className="col-sm-11">
                                                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                                                Replied by - <b>{value2.user_name}</b>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row mt-2">
                                                                                                        <div className="col-sm-1"></div>
                                                                                                        <div className="col-sm-9">
                                                                                                            <p className='p-0 mt-2 mb-1'>{value2.comment_msg}</p>
                                                                                                            {
                                                                                                                value2.comments_file !== '' ?
                                                                                                                    <a class="text-decoration-none" href={value2.comments_file} target="_blank">
                                                                                                                        <FontAwesomeIcon icon={faFilePdf} /> Click here to view / download the attachment
                                                                                                                    </a>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }
                                                                                                            <p className='p-0 mt-2 mb-3'>{value.updated_on}</p>
                                                                                                        </div>
                                                                                                        <div className="col-sm-2" style={{ paddingTop: "2px" }}>
                                                                                                            {parseInt(userid) === parseInt(value2.user_id) ?
                                                                                                                <div>
                                                                                                                    &nbsp; &nbsp;<button className="btn btn-primary btn-sm ml-2" onClick={() => handeleditcomments(value2, "edit")}>Edit</button>
                                                                                                                    {/* <button className="btn btn-secondary btn-sm">Report</button> */}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </> :
                                                                                    <>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </React.Fragment> :
                                                                <>
                                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                                            <div className="col-sm-9">
                                                                                Be the first to write a comments
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <div className="float-right">
                                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </> :
                                <>
                                </>
                            }
                        </>
                        :
                        loadedapi === true && transactiondetails.length <= 0 ?
                            <>
                                <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                    <div id="grad1">
                                        <div className="row">
                                            <div className="col-1"></div>
                                            <div className="col-10 text-center">
                                                <div className='row'>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                <div id="grad1">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-10 text-center">
                                            <h6 style={{ padding: "70px 0px" }}>No data found</h6>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                </div>
                            </div>
                }

                <Modal open={commentsmodal} onClose={commentsmodalclose} center>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Comments</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3">
                                <div className="row mb-3">
                                    <div className='col-sm-12 text-left'>
                                        <label className="text-left col-form-label fw-bold" htmlFor="comments_file" >Image / Document </label>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="custom-file">
                                            <input type="file" className="form-control" id="comments_file" name="comments_file" onChange={(e) => handeldocumentformonchange(e)} disabled={form_datas.selected_file_dd} />
                                        </div>
                                        {form_errormsg.doc_selected_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.doc_selected_errormsg}</span> : <></>}
                                    </div>
                                    {

                                        form_datas.selected_file_dd === true ?
                                            <div className="col-sm-12 mt-3 mb-2">
                                                <div className='row'>
                                                    <div className='selectedfile col-10'>
                                                        <a href={form_datas.doc_url} target="_blank">
                                                            <p className='cut-text'>
                                                                {form_datas.doc_selected_name}
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className='col-2'>
                                                        <button className="btn submitfilebtn btn-danger" onClick={(e) => handelremovedoce(e)}>
                                                            <FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>

                                                </div>
                                            </div>
                                            : <></>
                                    }
                                </div>
                                <div className="row">
                                    <label htmlFor='comment_msg' className="text-left col-sm-12 col-form-label fw-bold">
                                        Message
                                    </label>
                                    <div className="col-sm-12">
                                        <textarea onChange={(e) => handelcommentsonchange(e)} className="form-control" id="comment_msg" name="comment_msg" value={form_datas.comment_msg} rows="5"></textarea>
                                        {form_errormsg.comment_msg_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.comment_msg_errormsg}</span> : <></>}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-8">
                                    </div>
                                    <div className="col-4">
                                        <button onClick={() => handlecommentsubmit()} type="button" className="btn-sm btn btn-secondary w-100">Submit</button>
                                    </div>
                                </div>
                            </div>

                            {
                                error_msgs.show_success_msg === true ?
                                    <>
                                        <div className="container success_msg_bx">
                                            <h4>{error_msgs.success_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }
                            {
                                error_msgs.show_error_msg === true ?
                                    <>
                                        <div className="container error_msg_bx">
                                            <h4>{error_msgs.error_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }

                        </section>
                    </div>
                </Modal>


                <Modal open={documentmodal} onClose={documentmodalclose} center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}> Uploaded documents</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3 mt-3">
                                {documentmodal_datas.length > 0 ?
                                    <>
                                        {documentmodal_datas.map((value_d, index2) => {
                                            return (
                                                <div className='row' key={index2}>
                                                    <div className='col-6'>
                                                        <p> {value_d.display_name} <span className='float-end'>:</span></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href={value_d.doc_url} target="_blank">{value_d.doc_name}</a>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    :
                                    <><p className='text-center p-5'>No data found</p>
                                    </>
                                }
                            </div>
                        </section>
                    </div>
                </Modal>

                <Modal open={receiptmodal} onClose={receiptmodalclose} center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}> Receipt documents</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3 mt-3">
                                {receiptmodal_data.length > 0 ?
                                    <>
                                        {receiptmodal_data.map((value_d, index2) => {
                                            return (
                                                <div className='row' key={index2}>
                                                    <div className='col-4'>
                                                        <p> {value_d.receipt_name_d} <span className='float-end'>:</span></p>
                                                    </div>
                                                    <div className='col-8'>
                                                        <a onClick={() => getdownloadreceipturl(value_d.payment_receipt_id, value_d.refid, value_d.user_id)} className='cursor-pointer'>{value_d.receipt_name}
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    :
                                    <><p className='text-center p-5'>No data found</p>
                                    </>
                                }
                            </div>
                        </section>
                    </div>
                </Modal>

                <FooterComp />
            </main>
        </>
    );
}

export default Transactiondetails;

