import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faCalendarCheck, faList, faCartPlus, faAnglesRight, faUserCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import BannerMenuComp from '../components/bannermenu';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Base64 } from 'js-base64';
import { axiosInstance } from '../Services';
import SearchComp from '../components/search';
import { useLocation } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import BounceLoader from "react-spinners/BounceLoader";
import LoginForm from '../components/loginform';
import { saveAs } from "file-saver";
import * as device from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


function ServicesPage(props) {
    const navigate = useNavigate();
    const childCompRef = useRef();
    const location = useLocation();
    // set loader details
    const [showloader, setshowloader] = useState(false);
    const [loadedapi, setloadedapi] = useState(false);

    const [showComments, setshowComments] = useState(false);
    const [servesdata, setservesdata] = useState([]);
    const [servesmaster_id, setservesmaster_id] = useState(0);

    const [mstatus, setmstatus] = useState(0);
    const [estatus, setestatus] = useState(0);
    const [checkedaddonvalues, setcheckedaddonvalues] = useState([]);
    const [commentdetails, setcommentdetails] = useState([]);


    // set loggin details
    const [loggingstatus, setloggingstatus] = useState(false);
    const [addon_error_msg, setaddon_error_msg] = useState('');
    const [loggedusername, setloggedusername] = useState('');
    const [userid, setuserid] = useState(0);
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            setloggingstatus(localStorage.getItem('logging_status'));
            setloggedusername(Base64.decode(localStorage.getItem('gsun')));
            setuserid(Base64.decode(localStorage.getItem('gsud')));
        } else {
            setloggingstatus(false);
        }
        setloadedapi(false);
        getservesdetails();
        localStorage.removeItem("refid");
        localStorage.removeItem("tdid");
        localStorage.removeItem("requestid");
    }, [location.key]);

    function getservesdetails() {
        var url = window.location.href;
        var parts = url.split('/');
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let mydatas = {
            "userid": userid_o,
            serves: parts[5]
        }
        axiosInstance.post('/Serves', mydatas)
            .then((res) => {
                setservesdata(res.data.data);
                if (res.data.data.length > 0) {
                    setmstatus(res.data.mstatus);
                    setestatus(res.data.estatus);
                    setservesmaster_id(res.data.data[0]['servesmaster_id']);
                    setcommentdetails(res.data.data[0]['commentdetails']);
                }
                setloadedapi(true);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function showDropdown(value, value2) {
        var a = document.getElementsByClassName(value);
        a[0].classList.remove('b_block');
        a[0].classList.add('b_none');
        var b = document.getElementsByClassName(value2);
        b[0].classList.remove('b_none');
        b[0].classList.add('b_block');
    }

    function hideDropdown(value, value2) {
        var a = document.getElementsByClassName(value2);
        a[0].classList.remove('b_block');
        a[0].classList.add('b_none');
        var b = document.getElementsByClassName(value);
        b[0].classList.remove('b_none');
        b[0].classList.add('b_block');
    }

    const handelshowDropdown = (e) => {
        setshowComments(!showComments);
    }

    const [loginmodalopen, setloginmodalopen] = useState(false);
    const [cartmodalopen, setcartmodalopen] = useState(false);

    const openloginmodal = () => setloginmodalopen(true);
    const closeloginmodal = () => {
        setloginmodalopen(false);
        childCompRef.current.checklogindata();
        getservesdetails();
    }

    const opencartmodal = () => setcartmodalopen(true);
    const closecartmodal = () => {
        setcartmodalopen(false);
        getservesdetails();
    }

    const [proceduremodalopen, setproceduremodalopen] = useState(false);

    const openproceduremodal = () => setproceduremodalopen(true);
    const closeproceduremodal = () => setproceduremodalopen(false);

    function handeladdtocart() {
        if (loggingstatus === true || loggingstatus === 'true') {
            opencartmodal();
        }
        else {
            openloginmodal();
        }
    }

    function handeladdtocartsubmit_direct() {
        if (loggingstatus === true || loggingstatus === 'true') {

            if (servesdata[0]['cartflag'] == 1) {
                toast.info('This service is already in the cart. Please proceed with applying by clicking "Cart - Proceed". To request the same service again, submit the existing application and then add to Cart.', {
                    position: toast.POSITION.TOP_RIGHT,
                    type: toast.TYPE.INFO,
                    className: 'rotateY animated'
                });
            }
            else {
                handeladdtocartsubmit();
            }
        }
        else {
            openloginmodal();
        }
    }

    function handelonchangecheckbox(e, index) {

        let dummy_array = servesdata[0]["addon_details"][index];
        if (e.target.checked === true) {
            let myvalue = checkedaddonvalues;
            myvalue.push(e.target.value)
            dummy_array.checked_flag = true;
            setservesdata([...servesdata]);
            setcheckedaddonvalues(myvalue);
        }
        if (e.target.checked === false) {
            let myvalue = checkedaddonvalues;
            dummy_array.checked_flag = false;
            setservesdata([...servesdata]);
            var filteredArray = myvalue.filter(l => l !== e.target.value);
            setcheckedaddonvalues(filteredArray);
        }
    }

    function handeladdtocartsubmit() {
        let addon_validation = servesdata[0]['addon_validation'];
        let _addons = servesdata[0]['addon_details'];
        let cartflag = servesdata[0]['cart_id'];
        let _selected_val = [];
        if (_addons.length > 0) {
            for (let j = 0; j < _addons.length; j++) {
                if (_addons[j]['checked_flag'] === true) {
                    _selected_val.push(_addons[j]['addonmaster_id']);
                }
            }
        }
        if (addon_validation === 'compulsory' && _selected_val.length < 1) {
            setaddon_error_msg('Select any addon value');
        }
        else {
            setshowloader(true);
            let mydatas = {
                "addonvalues": _selected_val,
                "cart_id": cartflag,
                "userid": userid,
                "department_id": servesdata[0]['department_id'],
                "servesmaster_id": servesdata[0]['servesmaster_id'],
            }
            axiosInstance.post('/Addtocart/insert', mydatas)
                .then((res) => {
                    if (res.data.status === 'success') {
                        closecartmodal();
                        childCompRef.current.getcartdetails();
                        getservesdetails();
                        setcheckedaddonvalues([]);
                        toast.success("Added to cart successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            type: toast.TYPE.SUCCESS,
                            className: 'rotateY animated'
                        });
                    }
                    setshowloader(false);
                })
                .catch(() => {
                    setshowloader(false);
                    console.log('Something went wrong please try again');
                });
        }
    }

    const saveFile = (url) => {
        var filename = url.replace(/^.*[\\\/]/, '');
        saveAs(
            url,
            filename
        );
    };

    const [commentsmodal, setcommentsmodal] = useState(false);

    const commentsmodalclose = () => {
        setcommentsmodal(false);
        setFomdatas({
            comment_msg: '',
            comment_id: 0,
        });
    }

    const commentsmodalopen = () => {
        if (loggingstatus === true || loggingstatus === 'true') {

            if (mstatus == 1) {
                setcommentsmodal(true);
                cleardatas();
                setFomdatas({
                    ...form_datas,
                    comment_id: 0
                });
            }
            else {
                navigate("/verification?type=qht4nWWPPQQVVSeqwrcc1yvr");
            }
            // estatus
        }
        else {
            openloginmodal();
        }
    }

    const [form_datas, setFomdatas] = useState({
        comment_msg: '',
        comment_id: 0,
    });

    const [form_errormsg, setformerrormsg] = useState({
        comment_msg_errormsg: "",
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const handelcommentsonchange = (e) => {
        setFomdatas({
            ...form_datas,
            comment_msg: e.target.value
        });
    }

    function formvalidation() {
        let comment_msg_errormsg = '';
        if (!form_datas.comment_msg) {
            comment_msg_errormsg = "* Message cannot be blank";
        }
        if (comment_msg_errormsg) {
            setformerrormsg({
                comment_msg_errormsg: comment_msg_errormsg
            });
            return false;
        }
        else {
            return true;
        }
    }

    function handlecommentsubmit() {
        const validated = formvalidation();
        if (validated) {
            setshowloader(true);
            setformerrormsg({
                comment_msg_errormsg: "",
            });
            const JSONvalue = form_datas;
            JSONvalue.userid = userid;
            JSONvalue.servesmaster_id = servesmaster_id;
            axiosInstance.post('Serves/insertcomments', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setFomdatas({
                            comment_msg: '',
                            comment_id: 0,
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                        getservesdetails();
                        setshowloader(false);
                        commentsmodalclose();
                    }
                    else if (res.data.status === 'failed') {
                        seterror_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                        setshowloader(false);
                    }
                })
                .catch(() => {
                    seterror_msgs({
                        ...error_msgs,
                        show_error_msg: true,
                        error_msg: 'Something went wrong please try again'
                    });
                    setshowloader(false);
                    setTimeout(() => {
                        cleardatas();
                    }, 5000);
                });
        }
    }

    function handeleditcomments(id) {
        if (loggingstatus === true || loggingstatus === 'true') {
            if (mstatus == 1) {
                setcommentsmodal(true);
                cleardatas();
                setFomdatas({
                    ...form_datas,
                    comment_id: id
                });
            }
            else {
                navigate("/verification?type=qht4nWWPPQQVVSeqwrcc1yvr");
            }
        }
        else {
            openloginmodal();
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }
            <ToastContainer />
            <HeaderComp ref={childCompRef} getservesdetails={() => {
                getservesdetails();
            }} />
            <main role="main">
                <section id='main_banner'>
                    <Container fluid className='bg-semi-dark' style={{ paddingBottom: 40 }} >
                        <Container>
                            <div className='intro-section mobintro-section'>
                                <Row>
                                    <Col className='pdb-10'>
                                        <BannerMenuComp />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pdb-20'>
                                        <SearchComp />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>

                {servesdata.length > 0 ?
                    <>
                        <section id='dynamic_banner'>
                            <Container className='mobContainer'>
                                <Row>
                                    <Col lg={2}></Col>
                                    <Col lg={8}>
                                        <div className="con-bottom-inner">
                                            <div className='padding_20'>
                                                <h4>
                                                    <span style={{ color: '#6c757d', fontWeight: 800 }}>{servesdata[0]["service_name"]} </span>
                                                </h4>
                                                <p>{servesdata[0]["service_details"]}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={2}></Col>
                                </Row>
                            </Container>
                        </section>
                        <section>
                            <div className="container mobContainer">
                                <div style={device.isMobileOnly === true ? { padding: '0px 0px' } : { padding: '0px 0px' }}>
                                    <div className="row">
                                        <div className={device.isMobileOnly === true ? " col-6" : "col-3"} style={{ paddingBottom: 20 }}
                                            onMouseEnter={() => showDropdown('card-info-on', 'card-info-off')}
                                            onMouseLeave={() => hideDropdown('card-info-on', 'card-info-off')}
                                        >
                                            <div className="card-info-on b_block">
                                                <div className="card" style={{ width: "auto", height: "230px", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center">
                                                        <div className='custom_center'>
                                                            <div style={{ paddingBottom: 20 }}>
                                                                <span style={{ color: '#6c757d', fontSize: '50px' }}>
                                                                    <FontAwesomeIcon icon={faSquareCheck} />
                                                                </span>
                                                            </div>
                                                            <span className="card-text" style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>OUTPUT</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-info-off b_none" >
                                                <div className="card" style={{ width: "auto", height: "230px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center" style={{ backgroundColor: "#6c757d", color: "#fff" }}>
                                                        <div className='custom_center'>
                                                            <p className=' mb-3 pb-1'>
                                                                <span style={{ fontWeight: 600, fontSize: '13px' }} >Gserves:
                                                                </span><br />
                                                                <small>
                                                                    &nbsp;{servesdata[0]["gserves_output"]}
                                                                </small>
                                                            </p>
                                                            <p className=' mb-0 pb-1'>
                                                                <span style={{ fontWeight: 600, fontSize: '13px' }} >Government: </span>
                                                                <small><br />
                                                                    &nbsp;{servesdata[0]["govt_output"]}
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={device.isMobileOnly === true ? " col-6" : "col-3"} style={{ paddingBottom: 20 }}
                                            onMouseEnter={() => showDropdown('card-pay-on', 'card-pay-off')}
                                            onMouseLeave={() => hideDropdown('card-pay-on', 'card-pay-off')}
                                        >
                                            <div className="card-pay-on b_block">
                                                <div className="card"
                                                    style={{ width: "auto", height: "230px", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center">
                                                        <div className='custom_center'>
                                                            <div style={{ paddingBottom: 20 }}>
                                                                <span
                                                                    style={{ color: '#6c757d', fontSize: '50px' }}>&#8377;</span>
                                                            </div>
                                                            <span className="card-text"
                                                                style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>CHARGES</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-pay-off b_none" >
                                                <div className="card" style={{ width: "auto", height: "230px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center" style={{ backgroundColor: "#6c757d", color: "#fff" }}>
                                                        <div className='custom_center'>
                                                            <p className=' mb-0 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >Government fees &nbsp; &#8377; {servesdata[0]["govt_fees"]}</span></p>
                                                            <p className=' mb-0 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >Out of pocket exps &nbsp;  &#8377; {servesdata[0]["other_exps"]}</span></p>
                                                            <p className=' mb-0 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >Service charges &nbsp;  &#8377; {servesdata[0]["service_charge"]}</span></p>
                                                            <p className=' mb-3 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >Total &nbsp;  &#8377; {servesdata[0]["serves_total"]}</span></p>
                                                            <p className=' mb-0 pb-1'><small>These are indicative charges and actual amount shall be calculated later</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={device.isMobileOnly === true ? " col-6" : "col-3"} style={{ paddingBottom: 20 }}
                                            onMouseEnter={() => showDropdown('card-time-on', 'card-time-off')}
                                            onMouseLeave={() => hideDropdown('card-time-on', 'card-time-off')}
                                        >
                                            <div className="card-time-on b_block">
                                                <div className="card"
                                                    style={{ width: "auto", height: "230px", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center">
                                                        <div className='custom_center'>
                                                            <div style={{ paddingBottom: 20 }}>
                                                                <span style={{ color: '#6c757d', fontSize: '50px' }}>
                                                                    <FontAwesomeIcon icon={faCalendarCheck} />
                                                                </span>
                                                            </div>
                                                            <span className="card-text"
                                                                style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>TIME TAKEN</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-time-off b_none" >
                                                <div className="card" style={{ width: "auto", height: "230px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center" style={{ backgroundColor: "#6c757d", color: "#fff" }}>
                                                        <div className='custom_center'>
                                                            <p className=' mb-2 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >GServes &nbsp; &nbsp; &nbsp; &nbsp;  : {servesdata[0]["time_taken"]} days</span></p>
                                                            <p className=' mb-2 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >Government &nbsp;  : {servesdata[0]["time_taken_govt"]} days</span></p>
                                                            <p><small>Timeline given above is tentative and it might be completed quicker / delayed.</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={device.isMobileOnly === true ? " col-6" : "col-3"} style={{ paddingBottom: 20 }}
                                            onMouseEnter={() => showDropdown('card-addon-on', 'card-addon-off')}
                                            onMouseLeave={() => hideDropdown('card-addon-on', 'card-addon-off')}
                                        >
                                            <div className="card-addon-on b_block">
                                                <div className="card"
                                                    style={{ width: "auto", height: "230px", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047" }}>
                                                    <div className="card-body text-center">
                                                        <div className='custom_center'>
                                                            <div style={{ paddingBottom: 20 }}>
                                                                <span style={{ color: '#6c757d', fontSize: '50px' }}>
                                                                    <FontAwesomeIcon icon={faList} />
                                                                </span>
                                                            </div>
                                                            <span className="card-text"
                                                                style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>ADD ON</span>
                                                            <br></br>
                                                            {servesdata[0]["addon_status"] === 'no' ?
                                                                <span className='noaddons_data'>No Addons found</span>
                                                                :
                                                                null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-addon-off b_none" >
                                                <div className="card" style={{ width: "auto", height: "230px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                    <div className="card-body text-center" style={{ backgroundColor: "#6c757d", color: "#fff" }}>
                                                        <div className='custom_center'>
                                                            {servesdata[0]["addon_details"].length > 0 ?
                                                                <>
                                                                    {servesdata[0]["addon_details"].slice(0, 4).map((value, index) => {
                                                                        return (
                                                                            <p key={index} className=' mb-2 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >{index + 1}. {value.addon_name}</span></p>
                                                                        );
                                                                    })}
                                                                    {servesdata[0]["addon_details"].length >= 4 ?
                                                                        <>
                                                                            <p className=' mb-2 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >ect...</span></p>
                                                                        </> :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <p className=' mb-2 pb-1'><span style={{ fontWeight: 600, fontSize: '13px' }} >No Addons found</span></p>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="container mobContainer" style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-4 col-xs-12 mb-3">
                                        {servesdata[0]["addon_status"] === 'no' ?
                                            <>
                                                <button className="btn btn-danger btn-block w-100" data-toggle="modal" data-target="#exampleModal"
                                                    style={{ color: "#fff", backgroundColor: "#ab5d40" }} onClick={handeladdtocartsubmit_direct}>
                                                    Add to cart<span style={{ padding: "5%" }}><FontAwesomeIcon icon={faCartPlus} /></span>
                                                </button>
                                            </> :
                                            <button className="btn btn-danger btn-block w-100" data-toggle="modal" data-target="#exampleModal"
                                                style={{ color: "#fff", backgroundColor: "#ab5d40" }} onClick={handeladdtocart}>
                                                Add to cart<span style={{ padding: "5%" }}><FontAwesomeIcon icon={faCartPlus} /></span>
                                            </button>
                                        }


                                        {/* {servesdata[0]["cartflag"] === 1 ?
                                            <>
                                                <button className="btn btn-danger btn-block w-100" data-toggle="modal" data-target="#exampleModal"
                                                    style={{ color: "#fff", backgroundColor: "#ab5d40", cursor: 'no-drop' }}>
                                                    Already in cart<span style={{ padding: "5%" }}></span>
                                                </button>
                                            </> :
                                            <>
                                                <button className="btn btn-danger btn-block w-100" data-toggle="modal" data-target="#exampleModal"
                                                    style={{ color: "#fff", backgroundColor: "#ab5d40" }} onClick={handeladdtocart}>
                                                    Add to cart<span style={{ padding: "5%" }}><FontAwesomeIcon icon={faCartPlus} /></span>
                                                </button>
                                            </>} */}
                                    </div>

                                    <div className="col-lg-4  col-sm-4 col-xs-12  mb-3">
                                        <button className="btn btn-secondary btn-block w-100" style={{ color: "#fff" }} data-toggle="modal"
                                            data-target="#ProcedureModal" onClick={openproceduremodal}>
                                            View Procedures<span style={{ padding: "5%" }}><FontAwesomeIcon icon={faAnglesRight} /></span>
                                        </button>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 col-xs-12  mb-3">
                                        <button className="btn btn-secondary btn-block comments w-100" style={{ color: "#fff" }} onClick={handelshowDropdown} >
                                            View Comments<span style={{ padding: "5%" }}><FontAwesomeIcon icon={faAnglesRight} /></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="container mobContainer" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <p>
                                            <strong><a href={servesdata[0]["tracking_url"] !== '' ? servesdata[0]["tracking_url"] : '#'} target="_blank" rel="noopener noreferrer">Click here</a> to access the Official website to the department</strong>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </>
                    : loadedapi === true && servesdata.length <= 0 ?
                        <section>
                            <div className="container mobContainer" style={{ paddingTop: "150px", paddingBottom: "150px" }}>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <p style={{ color: '#6c757d' }}><strong>No data found</strong></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <>
                            <section id='dynamic_banner'>
                                <Container className='mobContainer'>
                                    <Row>
                                        <Col lg={2}></Col>
                                        <Col lg={8}>
                                            <div className="con-bottom-inner">
                                                <div className='padding_20'>
                                                    <h4 className='mb-4'>
                                                        <span style={{ color: '#6c757d', fontWeight: 800 }}>
                                                            <Skeleton baseColor="#d7dbda" count={1} height={30} width={"50%"} />
                                                        </span>
                                                    </h4>
                                                    <Skeleton baseColor="#d7dbda" count={1} height={100} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>
                                </Container>
                            </section>
                            <section>
                                <div className="container mobContainer">
                                    <div style={{ padding: '0px 80px' }}>
                                        <div className="row">
                                            <div className="col-lg-3 col-xs-12" style={{ paddingBottom: 20 }}>
                                                <div className="card-info-on">
                                                    <div className="card" style={{ width: "auto", height: "200px", cursor: "pointer" }}>
                                                        <div className="card-body text-center p-0">
                                                            <Skeleton baseColor="#d7dbda" count={1} height={"100%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-xs-12" style={{ paddingBottom: 20 }}>
                                                <div className="card-info-on">
                                                    <div className="card" style={{ width: "auto", height: "200px", cursor: "pointer" }}>
                                                        <div className="card-body text-center p-0">
                                                            <Skeleton baseColor="#d7dbda" count={1} height={"100%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-xs-12" style={{ paddingBottom: 20 }}>
                                                <div className="card-info-on">
                                                    <div className="card" style={{ width: "auto", height: "200px", cursor: "pointer" }}>
                                                        <div className="card-body text-center p-0">
                                                            <Skeleton baseColor="#d7dbda" count={1} height={"100%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-xs-12" style={{ paddingBottom: 20 }}>
                                                <div className="card-info-on">
                                                    <div className="card" style={{ width: "auto", height: "200px", cursor: "pointer" }}>
                                                        <div className="card-body text-center p-0">
                                                            <Skeleton baseColor="#d7dbda" count={1} height={"100%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container mobContainer" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                                    <div className="row">
                                        <div className="col-lg-4 col-xs-12">
                                            <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                        </div>
                                        <div className="col-lg-4 col-xs-12">
                                            <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                        </div>
                                        <div className="col-lg-4 col-xs-12">
                                            <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>
                }

                {showComments === true ?
                    <section>
                        <div className="container showcomments mobContainer" style={{ paddingTop: "20px", paddingBottom: "50px" }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card"
                                        style={{ width: "auto", height: "auto", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                        <div className="card-body">
                                            {commentdetails.length > 0 ?
                                                <React.Fragment>
                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                            <div className="col-6">
                                                                Comments
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="float-end">
                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {commentdetails.map((value, index) => {
                                                        return (
                                                            <div style={{ padding: "20px", paddingBottom: "0px" }} key={index} >
                                                                <div className='main_comments'>
                                                                    <div className="row">
                                                                        <div className="col-sm-1">
                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                        </div>
                                                                        <div className="col-sm-11">
                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                <b>{value.user_name}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className="col-1"></div>
                                                                        <div className="col-9">{value.comment_msg}</div>
                                                                        <div className="col-2" style={{ paddingTop: "2px" }}>
                                                                            <div className="btn-group">
                                                                                <button className="btn btn-info btn-sm" onClick={() => handeleditcomments(value.comment_id)}>Reply</button>
                                                                                {/* <button className="btn btn-secondary btn-sm">Report</button> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {value.reply_comments.length > 0 ?
                                                                    <>

                                                                        {value.reply_comments.map((value2, index2) => {
                                                                            return (
                                                                                <div style={{ padding: "20px", paddingBottom: "0px", paddingTop: "15px" }} key={index2}>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-1">
                                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                                        </div>
                                                                                        <div className="col-sm-11">
                                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                                Replied by - <b>{value2.user_name}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-sm-1"></div>
                                                                                        <div className="col-sm-11">{value2.comment_msg}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </> :
                                                                    <>
                                                                    </>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </React.Fragment> :
                                                <>
                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                            <div className="col-sm-9">
                                                                Be the first to write a comments
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className="float-right">
                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    : <></>
                }

                <Modal open={loginmodalopen} onClose={closeloginmodal} center>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner">
                                            <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Login</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>
                        <LoginForm datafrom={'services'} closeloginmodal={() => { closeloginmodal(); }} />
                    </div>
                </Modal>

                <Modal open={cartmodalopen} onClose={closecartmodal} center>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner" style={{ paddingBottom: '10px' }}>
                                            <h5><span style={{ fontWeight: 700, textAlign: "right", fontSize: "1.2rem", lineHeight: "1.5" }}>Select from available Add-ons</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card-body"
                                        style={{ padding: "15px" }}>
                                        {/* <p className='selectaadon'>Select Add on</p> */}
                                        {servesdata.length > 0 ?
                                            <>
                                                {servesdata[0]["addon_status"] === 'no' ?
                                                    <p className='text-center'>No addon found</p>
                                                    :
                                                    <>
                                                        <ul style={{ paddingInlineStart: '2px', textAlign: 'left', listStyleType: 'none', cursor: "pointer" }}>
                                                            {servesdata[0]["addon_details"].map((value, index) => {
                                                                return (
                                                                    <li style={{ padding: "3px 0px" }} key={index}>
                                                                        <Form>
                                                                            <Form.Group onChange={(e) => handelonchangecheckbox(e, index)} className="mb-1" controlId={"formBasicCheckbox" + index}>
                                                                                <Form.Check type="checkbox" label={value.addon_name} name={"addoncheckbox" + index} value={value.addonmaster_id} style={{ fontWeight: 600 }} checked={value.checked_flag} />
                                                                                <p className='p-0 m-0' style={{ color: '#6c757d' }}>{value.addon_desc}</p>
                                                                            </Form.Group>
                                                                        </Form>

                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </>}
                                            </> :
                                            <p className='text-center' style={{ color: '#6c757d' }}>
                                                No data found
                                            </p>}
                                    </div>
                                    <p className='text-center' style={{ color: 'red' }}>
                                        {addon_error_msg}
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col-3"></div>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-secondary w-100" onClick={handeladdtocartsubmit}>Add to Cart</button>
                                    </div>
                                    <div className="col-3"></div>
                                </div>
                                <p className='note_p_dd'>After adding your desired services to the cart, you can provide application details by clicking "Cart - Proceed".</p>
                            </div>
                        </section>
                    </div>
                </Modal>

                <Modal open={proceduremodalopen} onClose={closeproceduremodal} center
                    classNames={{
                        modal: 'proceduremodal',
                    }}>
                    <div className='modal_cnt'>
                        {servesdata.length > 0 ?
                            <>
                                <section>
                                    <div className="container" style={{ paddingTop: 20 }}>
                                        <div className="text-center">
                                            <h4><span style={{ color: "#6c757d" }}>Procedures for {servesdata[0]["service_name"]}</span></h4>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <div className="container">
                                        <div style={{ paddingTop: 2 }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div id="table-scroll">
                                                        <table width="100%">
                                                            <tbody>
                                                                {servesdata[0]["procedure_details"].length > 0 ?
                                                                    <>
                                                                        {servesdata[0]["procedure_details"].map((value, index) => {
                                                                            var myindex = index + 1;
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div style={{ backgroundColor: "#ccc", padding: 10, textAlign: "center", fontWeight: 500 }}>
                                                                                            {myindex}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className='m-2'>{value.procedure_details}
                                                                                            {
                                                                                                value.procedure_documents !== '' ?
                                                                                                    <>
                                                                                                        &nbsp; <span onClick={() => saveFile(value.procedure_documents)} className='downloadicon'>
                                                                                                            <FontAwesomeIcon icon={faDownload} />
                                                                                                        </span>
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </p>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}

                                                                    </>
                                                                    :
                                                                    <tr>
                                                                        <td>
                                                                            No procedures found
                                                                        </td>
                                                                    </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                            :
                            <p>No procedures found</p>
                        }
                    </div>
                </Modal>

                <Modal open={commentsmodal} onClose={commentsmodalclose} center>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Comments</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3">
                                <div className="row">
                                    <label htmlFor='comment_msg' className="text-left col-sm-12 col-form-label fw-bold">
                                        Message
                                    </label>
                                    <div className="col-sm-12">
                                        <textarea onChange={(e) => handelcommentsonchange(e)} className="form-control" id="comment_msg" name="comment_msg" value={form_datas.comment_msg} rows="5"></textarea>
                                        {form_errormsg.comment_msg_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.comment_msg_errormsg}</span> : <></>}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-8">
                                    </div>
                                    <div className="col-4">
                                        <button onClick={() => handlecommentsubmit()} type="button" className="btn-sm btn btn-secondary w-100">Submit</button>
                                    </div>
                                </div>
                            </div>

                            {
                                error_msgs.show_success_msg === true ?
                                    <>
                                        <div className="container success_msg_bx">
                                            <h4>{error_msgs.success_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }
                            {
                                error_msgs.show_error_msg === true ?
                                    <>
                                        <div className="container error_msg_bx">
                                            <h4>{error_msgs.error_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }

                        </section>
                    </div>
                </Modal>


                <FooterComp />
            </main>
        </>
    );
}

export default ServicesPage;

