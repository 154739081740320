import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function FooterComp(props) {
    return (
        <>
            <section id="footer" className='bg-secondary' style={{ paddingTop: 50, paddingBottom: 50 }}>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <img src="/assets/img/logo.png" alt={'logo'} />
                        </Col>
                        <Col lg={5}>
                            <div style={{ color: '#fff' }}>
                                <p> <Link to={"/contactus"} style={{ color: '#fff', textDecoration:"none" }}>Contact us </Link> / <Link to={"/legalinfo"} style={{ color: '#fff', textDecoration:"none" }}> Legal Info </Link>/ 
                                <a target="_blank" href="https://www.facebook.com/GServesIndia" style={{ color: '#fff', textDecoration:"none" }}> Social Media</a>
                                </p>
                                <p>&copy; Copyright 2022, All Right Reserved</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default FooterComp;