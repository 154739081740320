import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { axiosInstance } from '../Services';
import { Base64 } from 'js-base64';


function Resetpassword(props) {

    const navigate = useNavigate();
    const [datafrom, setdatafrom] = useState('');
    useEffect(() => {
        if (localStorage.getItem('logging_status') === true || localStorage.getItem('logging_status') === 'true') {
            navigate("/");
        }
        var url = window.location.href;
        var parts = url.split('type=');
        setdatafrom(parts[1]);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const [form_datas, setFomdatas] = useState({
        mobile_otp: '',
        new_password: '',
        confirm_password: '',
        mobile_no: '',
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const [form_errormsg, setformerrormsg] = useState({
        mobile_otp_errormsg: "",
        new_password_errormsg: "",
        confirm_password_errormsg: "",
    });

    function handleInputChange(event) {
        const re = /^[0-9\b]+$/;
        if (event.target.name === "mobile_otp") {
            if (event.target.value === '' || re.test(event.target.value)) {
                setFomdatas({
                    ...form_datas,
                    [event.target.name]: event.target.value
                });
            }
        }
        else {
            setFomdatas({
                ...form_datas,
                [event.target.name]: event.target.value
            });
        }
    }

    function formvalidation() {

        let mobile_otp_errormsg = '';
        let new_password_errormsg = '';
        let confirm_password_errormsg = '';
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#%&])(?=.{8,})");

        const re = /^[0-9\b]+$/;
        if (!form_datas.mobile_otp) {
            mobile_otp_errormsg = "OTP cannot be blank";
        }

        else if (form_datas.mobile_otp.length !== 6) {
            mobile_otp_errormsg = "Enter Valid OTP";
        }

        else if (re.test(form_datas.mobile_otp) === false) {
            mobile_otp_errormsg = "Enter Valid mobile number";
        }

        if (!form_datas.new_password) {
            new_password_errormsg = "Password cannot be blank";
        }
        else if (form_datas.new_password.length < 8) {
            new_password_errormsg = "Password must have at least 8 characters";
        }
        else if (!strongRegex.test(form_datas.new_password)) {
            new_password_errormsg = "use special character, number and capital letter";
        }
        if (!form_datas.confirm_password) {
            confirm_password_errormsg = "Password cannot be blank";
        }
        else if (form_datas.confirm_password !== '' && form_datas.new_password !== form_datas.confirm_password) {
            confirm_password_errormsg = "The password and confirmation password do not match.";
        }

        if (mobile_otp_errormsg || new_password_errormsg || confirm_password_errormsg) {
            setformerrormsg({
                mobile_otp_errormsg: mobile_otp_errormsg,
                new_password_errormsg: new_password_errormsg,
                confirm_password_errormsg: confirm_password_errormsg,
            });
            return false;
        }

        else {
            return true;
        }
    }

    function handelResetpassword() {
        const validated = formvalidation();
        if (validated) {
            setformerrormsg({
                username_errormsg: "",
            });
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            const JSONvalue = {
                "otp": form_datas.mobile_otp,
                "password": form_datas.new_password,
                "mobile_no": Base64.decode(datafrom),
            };
            axiosInstance.post('/Otp/verify', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setFomdatas({
                            username: '',
                        });
                        setTimeout(() => {
                            navigate("/");
                            cleardatas();
                        }, 3000);
                    }
                    else if (res.data.status === 'failed') {
                        seterror_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 3000);
                    }
                })
                .catch(() => {
                    seterror_msgs({
                        ...error_msgs,
                        show_error_msg: true,
                        error_msg: 'Something went wrong please try again'
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 3000);
                });
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

    function handelresendotp(){
        setFomdatas({
            mobile_otp: '',
            new_password: '',
            confirm_password: '',
            mobile_no: '',
        });
        const JSONvalue = {
            "username": Base64.decode(datafrom),
            "userid": 0,
        };
        // navigate("/resetpassword");
        axiosInstance.post('/Otp/send', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    seterror_msgs({
                        show_success_msg: true,
                        success_msg: res.data.msg,
                        show_error_msg: false,
                        error_msg: ''
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 3000);
                }
                else if (res.data.status === 'failed') {
                    seterror_msgs({
                        show_success_msg: false,
                        success_msg: '',
                        show_error_msg: true,
                        error_msg: res.data.msg
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 3000);
                }
            })
            .catch(() => {
                seterror_msgs({
                    ...error_msgs,
                    show_error_msg: true,
                    error_msg: 'Something went wrong please try again'
                });
                setTimeout(() => {
                    cleardatas();
                }, 3000);
            });
    }

    return (
        <>
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner">
                                    <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Reset Password</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className='text-center mb-4'>
                                    <p style={{ margin: 0, lineHeight: 2 }}>Enter the OTP which you received on your mobile number <br /> to reset your password</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-2"></div> */}
                    </div>
                </section>

                <section className='mb-5 mt-2'>
                    <div className="container">
                        <div style={{ padding: 2 }}>
                            <div className="row mb-3">
                                <div className="col-md-6 offset-md-3">
                                    <div className='row mb-3'>
                                        <div className='col-sm-4 text-left'>
                                            <label className="text-left col-form-label fw-bold" htmlFor="mobile_otp" >OTP</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <input type="text" className="form-control" data-inputmask="'mask': '99999 99999'" placeholder="Enter OTP" name="mobile_otp" value={form_datas.mobile_otp} onChange={handleInputChange} maxLength={6} />
                                            {form_errormsg.mobile_otp_errormsg !== '' ?
                                                <span className='error_msg'>{form_errormsg.mobile_otp_errormsg}</span>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-sm-4 text-left'>
                                            <label className="text-left col-form-label fw-bold" htmlFor="new_password" >New Password</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <input type="password" className="form-control" placeholder="Enter password" name="new_password" value={form_datas.new_password} onChange={handleInputChange} />
                                            {form_errormsg.new_password_errormsg !== '' ?
                                                <span className='error_msg'>{form_errormsg.new_password_errormsg}</span>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-sm-4 text-left'>
                                            <label className="text-left col-form-label fw-bold" htmlFor="confirm_password" >Confirm Password</label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <input type="password" className="form-control" placeholder="Enter password" name="confirm_password" value={form_datas.confirm_password} onChange={handleInputChange} />
                                            {form_errormsg.confirm_password_errormsg !== '' ?
                                                <span className='error_msg'>{form_errormsg.confirm_password_errormsg}</span>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="btn btn-secondary btn-block w-100" onClick={handelResetpassword} >
                                                Submit
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: 10 }}>
                                        <div className="col-6 social text-start">
                                            <Link to={'/login'}> <FontAwesomeIcon icon={faChevronLeft} /> Back to Login</Link>
                                        </div>
                                        <div className="col-6 social text-end">
                                            <p onClick={() => handelresendotp()} className='m-0' style={{ color: "#09988e", fontWeight: 700, cursor: 'pointer' }}>Resend OTP</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    {
                        error_msgs.show_success_msg === true ?
                            <>
                                <div className="container success_msg_bx">
                                    <h4>{error_msgs.success_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    {
                        error_msgs.show_error_msg === true ?
                            <>
                                <div className="container error_msg_bx">
                                    <h4>{error_msgs.error_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    <br />

                </section>
            </main>
            <FooterComp />
        </>
    );
}

export default Resetpassword;