import React, { useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Container, Navbar, NavDropdown, Nav, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleUser, faCartShopping, faBars, faLocationDot, faGear, faSignOutAlt, faAngleRight, faArrowLeft, faTrashCan, faIndianRupeeSign, faBed, faBell } from '@fortawesome/free-solid-svg-icons';
import { axiosInstance } from '../Services';
import { Base64 } from 'js-base64';
import { slide as Menu } from 'react-burger-menu';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
// import { ToastContainer, toast } from 'react-toastify';
import * as device from 'react-device-detect';
import ReactTooltip from "react-tooltip";
import FloatingWhatsApp from 'react-floating-whatsapp';
// var CryptoJS = require("crypto-js");
const HeaderComp = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const myRef = useRef(null);
    const handleClickOutside = e => {
        if (myRef.current != null) {
            if (!myRef.current.contains(e.target)) {
                setshowcart(false);
            }
        }
    };
    const handleClickInside = () => {
        setshowcart(true);
    }
    const handleClickOutside_dd = () => {
        setshowcart(false);
    }
    const notify = () => {
        // toast.info('Your previous order should be above AED ', {
        //     position: toast.POSITION.TOP_RIGHT,
        //     type: toast.TYPE.INFO,
        //     className: 'rotateY animated'
        // });
    }
    const [showloader, setshowloader] = useState(false);
    useImperativeHandle(ref, () => ({
        getcartdetails, checklogindata
    }))
    const [mobile_status, setmobile_status] = useState(0);
    const [email_status, setemail_status] = useState(0);
    function getcartdetails() {
        let Jsondata = {
            "userid": Base64.decode(localStorage.getItem('gsud')),
        }
        axiosInstance.post('/Addtocart', Jsondata)
            .then((res) => {
                setCartdetails(res.data.data);
                setmobile_status(res.data.mobile_status);
                setemail_status(res.data.email_status);
            }).catch((error) => {
                console.log('error');
            });
    }
    const [menudetails, setMenudetails] = useState([]);
    const [cartdetails, setCartdetails] = useState([]);
    const [notificationdetails, setNotificationdetails] = useState([]);
    const [showlogg, setShowlogg] = useState(false);
    const [showcartlogg, setShowcartlogg] = useState(false);
    const [shownotificationlogg, setShownotificationlogg] = useState(false);
    const [showcart, setshowcart] = useState(false);
    const [loggingstatus, setloggingstatus] = useState(false);
    const [loggedusername, setloggedusername] = useState('');
    useLayoutEffect(() => {
        axiosInstance.get('/Menudetails')
            .then((res) => {
                setMenudetails(res.data.data);
            }).catch((error) => {
                console.log('error');
            });
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    useEffect(() => {
        checklogindata();
        setshowloader(false);
        getnotificationsdata();
        setInterval(() => {
            getnotificationsdata();
        }, 5000);
        // // Encrypt
        // var ciphertext = CryptoJS.AES.encrypt('Vinith vini', 'Gservesaecr').toString();
        // console.log(ciphertext);
        // // Decrypt
        // var bytes = CryptoJS.AES.decrypt(ciphertext, 'Gservesaecr');
        // var originalText = bytes.toString(CryptoJS.enc.Utf8);
        // console.log(originalText);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    function checklogindata() {
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            setloggingstatus(localStorage.getItem('logging_status'));
            setloggedusername(Base64.decode(localStorage.getItem('gsun')));
            getcartdetails();
        } else {
            setloggingstatus(false);
        }
    }
    const showDropdownlogg = (e) => {
        setShowlogg(!showlogg);
    }
    const hideDropdownlogg = e => {
        setShowlogg(false);
    }
    const showcartDropdownlogg = (e) => {
        setShowcartlogg(!showcartlogg);
    }
    const hidecartDropdownlogg = e => {
        setShowcartlogg(false);
    }
    const shownotificationDropdownlogg = (e) => {
        setShownotificationlogg(!shownotificationlogg);
    }
    const hidenotificationDropdownlogg = e => {
        setShownotificationlogg(false);
    }
    function handlelogout() {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }
    const [burgermenustate, setburgermenustate] = useState(false);
    const [showsubmenu, setshowsubmenu] = useState(false);
    const [submenutitle, setsubmenutitle] = useState(false);
    const [submenudetails, setsubmenudetails] = useState([]);
    function handleOnOpen() {
        setburgermenustate(true);
        setshowsubmenu(false);
        setsubmenutitle('');
        setsubmenudetails([]);
    }
    function handleOnClose() {
        setburgermenustate(false);
        setshowsubmenu(false);
        setsubmenutitle('');
        setsubmenudetails([]);
    }
    function handlesidemenu(title, values) {
        setshowsubmenu(true);
        setsubmenutitle(title);
        setsubmenudetails(values);
    }
    function clearsubmenu() {
        setshowsubmenu(false);
        setsubmenutitle('');
        setsubmenudetails([]);
    }
    // const handelcartmenu = (e) => {
    //     setshowcart(!showcart);
    // }
    function handeldeletecart(val) {
        let Jsondata = {
            "userid": Base64.decode(localStorage.getItem('gsud')),
            "cart_id": val
        }
        axiosInstance.post('Addtocart/deletecart', Jsondata)
            .then((res) => {
                if (res.data.status === "success") {
                    getcartdetails();
                    if (props.refreshapplication !== undefined) {
                        props.refreshapplication();
                    }
                    if (props.getservesdetails !== undefined) {
                        props.getservesdetails();
                    }
                }
            }).catch((error) => {
                console.log('error');
            });
    }
    function handelverification() {
        // setshowloader(true);
        if (mobile_status === 1 && email_status === 1) {
            navigate("/applicationform");
        }
        if (mobile_status === 0 && email_status === 0) {
            navigate("/verification?type=muecvt003zht55fewsvv");
        }
        if (mobile_status === 1 && email_status === 0) {
            navigate("/emailverification?type=muecvt003zht55fewsvv");
        }
    }
    function getnotificationsdata() {
        if (localStorage.getItem('logging_status') == true || localStorage.getItem('logging_status') == 'true') {
            var Jsondata = {
                "userid": Base64.decode(localStorage.getItem('gsud')),
            }
        }
        else {
            var Jsondata = {
                "userid": 0,
            }
        }
        axiosInstance.post('/Addtocart/getnotification', Jsondata)
            .then((res) => {
                setNotificationdetails(res.data.data);
            }).catch((error) => {
                console.log('error');
            });
    }
    function handeledit(refid, not_id) {
        let Jsondata = {
            "userid": Base64.decode(localStorage.getItem('gsud')),
            "refid": refid,
        }
        axiosInstance.post('/Addtocart/update_notification_r', Jsondata)
            .then((res) => {
                localStorage.setItem('tdid', refid);
                navigate("/transactiondetails");
            }).catch((error) => {
                console.log('error');
            });
    }
    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }

            {/* <FloatingWhatsApp
                phoneNumber='+91 89710 63235'
                accountName='GServs'
                avatar='/assets/img/gserves_w_logo.png'
            /> */}
            <ReactTooltip id='Bengaluru' place="bottom" type="dark" effect="solid">
                <span>Currently GServes operates only in Bengaluru</span>
            </ReactTooltip>
            <Menu isOpen={burgermenustate} onClose={handleOnClose} customBurgerIcon={
                <p className='text-white' >
                    <FontAwesomeIcon icon={faBars} /> Services
                </p>}>
                {showsubmenu === true ?
                    <h4 className='text-center' style={{ paddingBottom: "5px", fontSize: "1.2rem" }}>
                        <FontAwesomeIcon onClick={clearsubmenu} style={{ fontSize: "16px", cursor: "pointer" }} icon={faArrowLeft} />
                        &nbsp; {submenutitle}</h4>
                    :
                    <h4 className='text-center' style={{ paddingBottom: "5px" }}>All Services</h4>
                }
                {showsubmenu === true ?
                    submenudetails.map((value, index) => {
                        return (
                            <Link onClick={() => handleOnClose()} key={index} className="menu-item-sizebar" to={'/serves/' + submenutitle.replace(/[ ,]+/g, "-") + '/' + value.url}>
                                {value.menu_name}
                            </Link>
                        );
                    })
                    :
                    menudetails.map((value, index) => {
                        return (
                            <div style={{ cursor: "pointer" }} key={index} className="menu-item-sizebar" onClick={() => handlesidemenu(value.menu_name, value.submenu)}>
                                {value.menu_name}
                                <span style={{ float: "right", fontSize: "12px" }}> <FontAwesomeIcon icon={faAngleRight} />  </span>
                            </div>
                        );
                    })
                }
            </Menu>
            <Navbar expand="lg" className='fixed-top bg-dark-cust'>
                <Container>
                    {device.isMobile === true ?
                        <>
                            <Col sm={1} md={1} xs={1} >
                                <Nav className="me-auto">
                                    <Nav.Link className='text-white' onClick={handleOnOpen}>
                                        <FontAwesomeIcon color='#09988e' icon={faBars} className={'fs-5'} /></Nav.Link>
                                </Nav>
                            </Col>
                            <Col sm={4} md={4} xs={4} className={'text-center'} >
                                <Link to={'/'} className={'mlogo'}>
                                    <img src="/assets/img/logo.png" alt={'logo'} />
                                </Link>
                            </Col>
                            <Col sm={2} md={2} xs={2} ></Col>
                            <Col sm={5} md={5} xs={5} >
                                {loggingstatus === false ?
                                    <>
                                        <Nav className="ml-auto mnav_df">
                                            <Nav.Link className='text-white' href="/register"> <FontAwesomeIcon color='#09988e' icon={faEdit} /> &nbsp;Register</Nav.Link>
                                            <Nav.Link className='text-white' href="/login"> <FontAwesomeIcon color='#09988e' icon={faCircleUser} />  &nbsp;Login</Nav.Link>
                                            <NavDropdown
                                                className='text-white'
                                                title={
                                                    <span className='text-white' >
                                                        <FontAwesomeIcon color='#09988e' icon={faCartShopping} /> &nbsp; Cart
                                                    </span>
                                                }
                                                id="collasible-nav-dropdown"
                                                show={showcartlogg}
                                                onMouseEnter={showcartDropdownlogg}
                                                onMouseLeave={hidecartDropdownlogg}
                                            >
                                                <p className='text-center m-0'>Cart is empty</p>
                                            </NavDropdown>
                                            <NavDropdown
                                                className='text-white'
                                                title={
                                                    <span className='text-white' >
                                                        <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                        <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                    </span>
                                                }
                                                id="collasible-nav-dropdown"
                                                show={shownotificationlogg}
                                                onMouseEnter={shownotificationDropdownlogg}
                                                onMouseLeave={hidenotificationDropdownlogg}
                                            >
                                                <p className='text-center m-0'>No records found</p>
                                            </NavDropdown>
                                        </Nav>
                                    </> :
                                    <Nav className="ml-auto mnav_df">
                                        <NavDropdown
                                            className='text-white'
                                            title={
                                                <span className='text-white' >
                                                    <FontAwesomeIcon color='#09988e' icon={faCircleUser} /> &nbsp; {loggedusername}
                                                </span>
                                            }
                                            id="collasible-nav-dropdown"
                                            show={showlogg}
                                            onMouseEnter={showDropdownlogg}
                                            onMouseLeave={hideDropdownlogg}
                                        >
                                            <NavDropdown.Item href="#"> <FontAwesomeIcon color='#09988e' icon={faGear} /> &nbsp; Setting</NavDropdown.Item>
                                            <NavDropdown.Item onClick={handlelogout}> <FontAwesomeIcon color='#09988e' icon={faSignOutAlt} /> &nbsp; Logout</NavDropdown.Item>
                                            <NavDropdown.Item href="/transaction"> <FontAwesomeIcon color='#09988e' icon={faIndianRupeeSign} /> &nbsp; Transaction</NavDropdown.Item>
                                        </NavDropdown>
                                        {cartdetails.length > 0 ?
                                            <>
                                                <NavDropdown
                                                    className='text-white addtocart'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faCartShopping} />
                                                            <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{cartdetails.length}</span>
                                                            &nbsp; &nbsp; &nbsp; Cart
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={showcart}
                                                    // onClick={() => handelcartmenu()} 
                                                    ref={myRef}
                                                    onMouseEnter={handleClickInside}
                                                    onMouseLeave={handleClickOutside_dd}
                                                >
                                                    <div>
                                                        <div className='addtocartheader'>
                                                            <h6>Services</h6>
                                                        </div>
                                                        <div className='addcartbody'>
                                                            <div className="container">
                                                                <table width="100%">
                                                                    <tbody>
                                                                        {cartdetails.map((value, index) => {
                                                                            if (value.addonvalues.length > 0) {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td><p>{value.services_name}</p>
                                                                                            <ul>
                                                                                                {value.addonvalues.map((value2, index2) => {
                                                                                                    return (
                                                                                                        <li key={index2}>
                                                                                                            <i>{value2}</i>
                                                                                                        </li>);
                                                                                                })}
                                                                                            </ul>
                                                                                        </td>
                                                                                        <td><button className="btn btn-sm btn-danger" onClick={() => handeldeletecart(value.cart_id)}>
                                                                                            <FontAwesomeIcon icon={faTrashCan} /></button></td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td><p>{value.services_name}</p></td>
                                                                                        <td><button className="btn btn-sm btn-danger" onClick={() => handeldeletecart(value.cart_id)}>
                                                                                            <FontAwesomeIcon icon={faTrashCan} /></button></td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                                <div>
                                                                    <span onClick={() => handelverification()} className="btn btn-block btn-secondary btn-sm w-100 mt-3">Proceed</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavDropdown>
                                            </> :
                                            <>
                                                <NavDropdown
                                                    className='text-white'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faCartShopping} /> &nbsp; Cart
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={showcartlogg}
                                                    onMouseEnter={showcartDropdownlogg}
                                                    onMouseLeave={hidecartDropdownlogg}
                                                >
                                                    <p className='text-center m-0'>Cart is empty</p>
                                                </NavDropdown>
                                            </>}
                                        {notificationdetails.length > 0 ?
                                            <>
                                                <NavDropdown
                                                    className='text-white'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                            <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={shownotificationlogg}
                                                    onMouseEnter={shownotificationDropdownlogg}
                                                    onMouseLeave={hidenotificationDropdownlogg}
                                                >
                                                    <p className='text-center m-0'>No records found</p>
                                                </NavDropdown>
                                            </>
                                            :
                                            <>
                                                <NavDropdown
                                                    className='text-white'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                            <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={shownotificationlogg}
                                                    onMouseEnter={shownotificationDropdownlogg}
                                                    onMouseLeave={hidenotificationDropdownlogg}
                                                >
                                                    <p className='text-center m-0'>No records found</p>
                                                </NavDropdown>
                                            </>
                                        }
                                    </Nav>
                                }
                            </Col>
                        </>
                        : device.isBrowser === true ?
                            <>
                                <Col lg={4}>
                                    <Nav className="me-auto">
                                        <Nav.Link className='text-white' onClick={handleOnOpen}> <FontAwesomeIcon color='#09988e' icon={faBars} /> &nbsp;Services</Nav.Link>
                                        <Nav.Link className='text-white' data-tip data-for='Bengaluru'> <FontAwesomeIcon color='#09988e' icon={faLocationDot} /> &nbsp;Bengaluru</Nav.Link>
                                    </Nav>
                                </Col>
                                <Col lg={4} className="text-center">
                                    <Link to={'/'}>
                                        <img src="/assets/img/logo.png" width="160px" alt={'logo'} />
                                    </Link>
                                </Col>
                                <Col lg={4} >
                                    {loggingstatus === false ?
                                        <>
                                            <Nav className="ml-auto cust_flot_right">
                                                <Nav.Link className='text-white' href="/register"> <FontAwesomeIcon color='#09988e' icon={faEdit} /> &nbsp;Register</Nav.Link>
                                                <Nav.Link className='text-white' href="/login"> <FontAwesomeIcon color='#09988e' icon={faCircleUser} />  &nbsp;Login</Nav.Link>
                                                <NavDropdown
                                                    className='text-white'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faCartShopping} /> &nbsp; Cart
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={showcartlogg}
                                                    onMouseEnter={showcartDropdownlogg}
                                                    onMouseLeave={hidecartDropdownlogg}
                                                >
                                                    <p className='text-center m-0'>Cart is empty</p>
                                                </NavDropdown>
                                                <NavDropdown
                                                    className='text-white'
                                                    title={
                                                        <span className='text-white' >
                                                            <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                            <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                        </span>
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    show={shownotificationlogg}
                                                    onMouseEnter={shownotificationDropdownlogg}
                                                    onMouseLeave={hidenotificationDropdownlogg}
                                                >
                                                    <p className='text-center m-0'>No records found</p>
                                                </NavDropdown>
                                            </Nav>
                                        </> :
                                        <Nav className="ml-auto cust_flot_right">
                                            <NavDropdown
                                                className='text-white'
                                                title={
                                                    <span className='text-white' >
                                                        <FontAwesomeIcon color='#09988e' icon={faCircleUser} /> &nbsp; {loggedusername}
                                                    </span>
                                                }
                                                id="collasible-nav-dropdown"
                                                show={showlogg}
                                                onMouseEnter={showDropdownlogg}
                                                onMouseLeave={hideDropdownlogg}
                                            >
                                                <NavDropdown.Item href="#"> <FontAwesomeIcon color='#09988e' icon={faGear} /> &nbsp; Setting</NavDropdown.Item>
                                                <NavDropdown.Item onClick={handlelogout}> <FontAwesomeIcon color='#09988e' icon={faSignOutAlt} /> &nbsp; Logout</NavDropdown.Item>
                                                <NavDropdown.Item href="/transaction"> <FontAwesomeIcon color='#09988e' icon={faIndianRupeeSign} /> &nbsp; Transaction</NavDropdown.Item>
                                            </NavDropdown>
                                            {cartdetails.length > 0 ?
                                                <>
                                                    <NavDropdown
                                                        className='text-white addtocart'
                                                        title={
                                                            <span className='text-white' >
                                                                <FontAwesomeIcon color='#09988e' icon={faCartShopping} />
                                                                <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{cartdetails.length}</span>
                                                                &nbsp; &nbsp; &nbsp; Cart
                                                            </span>
                                                        }
                                                        id="collasible-nav-dropdown"
                                                        show={showcart}
                                                        // onClick={() => handelcartmenu()}
                                                        ref={myRef}
                                                        onMouseEnter={handleClickInside}
                                                        onMouseLeave={handleClickOutside_dd}
                                                    >
                                                        <div>
                                                            <div className='addtocartheader'>
                                                                <h6>Services</h6>
                                                            </div>
                                                            <div className='addcartbody'>
                                                                <div className="container">
                                                                    <table width="100%">
                                                                        <tbody>
                                                                            {cartdetails.map((value, index) => {
                                                                                if (value.addonvalues.length > 0) {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td><p>{value.services_name}</p>
                                                                                                <ul>
                                                                                                    {value.addonvalues.map((value2, index2) => {
                                                                                                        return (
                                                                                                            <li key={index2}>
                                                                                                                <p>{value2}</p>
                                                                                                            </li>);
                                                                                                    })}
                                                                                                </ul>
                                                                                            </td>
                                                                                            <td><button className="btn btn-sm btn-danger" onClick={() => handeldeletecart(value.cart_id)}>
                                                                                                <FontAwesomeIcon icon={faTrashCan} /></button></td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td><p>{value.services_name}</p></td>
                                                                                            <td><button className="btn btn-sm btn-danger" onClick={() => handeldeletecart(value.cart_id)}>
                                                                                                <FontAwesomeIcon icon={faTrashCan} /></button></td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                    <div>
                                                                        <span onClick={() => handelverification()} className="btn btn-block btn-secondary btn-sm w-100 mt-3">Proceed</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavDropdown>
                                                </> :
                                                <>
                                                    <NavDropdown
                                                        className='text-white'
                                                        title={
                                                            <span className='text-white' >
                                                                <FontAwesomeIcon color='#09988e' icon={faCartShopping} /> &nbsp; Cart
                                                            </span>
                                                        }
                                                        id="collasible-nav-dropdown"
                                                        show={showcartlogg}
                                                        onMouseEnter={showcartDropdownlogg}
                                                        onMouseLeave={hidecartDropdownlogg}
                                                    >
                                                        <p className='text-center m-0'>Cart is empty</p>
                                                    </NavDropdown>
                                                </>}
                                            {notificationdetails.length > 0 ?
                                                <>
                                                    <NavDropdown
                                                        className='text-white notification_trop'
                                                        title={
                                                            <span className='text-white' >
                                                                <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                                <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                            </span>
                                                        }
                                                        id="collasible-nav-dropdown"
                                                        show={shownotificationlogg}
                                                        onMouseEnter={shownotificationDropdownlogg}
                                                        onMouseLeave={hidenotificationDropdownlogg}
                                                    >
                                                        <div>
                                                            <div className="container">
                                                                <table width="100%">
                                                                    <tbody>
                                                                        {notificationdetails.map((value, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className='p-2 cursor-pointer' onClick={() => handeledit(value.refid, value.notification_id)}>
                                                                                            <h6 className='p-0 m-0 pb-2'><FontAwesomeIcon color='#000' icon={faCircleUser} />  &nbsp;{value.user_name}</h6>
                                                                                            <p className='p-0 m-0 pb-1 class_textnotification'>{value.comment_msg}</p>
                                                                                            <p className='p-0 m-0 clr_cess'>{value.updated_on}</p>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </NavDropdown>
                                                </>
                                                :
                                                <>
                                                    <NavDropdown
                                                        className='text-white'
                                                        title={
                                                            <span className='text-white' >
                                                                <FontAwesomeIcon color='#09988e' icon={faBell} />
                                                                <span className="badge badge-light" style={{ borderRadius: '50px', marginTop: "-4px", marginLeft: "-4px", position: 'absolute', backgroundColor: "#fff", color: "#000" }} >{notificationdetails.length}</span>
                                                            </span>
                                                        }
                                                        id="collasible-nav-dropdown"
                                                        show={shownotificationlogg}
                                                        onMouseEnter={shownotificationDropdownlogg}
                                                        onMouseLeave={hidenotificationDropdownlogg}
                                                    >
                                                        <p className='text-center m-0'>No records found</p>
                                                    </NavDropdown>
                                                </>
                                            }
                                        </Nav>
                                    }
                                </Col>
                            </>
                            :
                            <>
                            </>
                    }
                </Container>
            </Navbar>
        </>
    );
});
export default HeaderComp;
