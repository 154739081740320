import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope, faL } from '@fortawesome/free-solid-svg-icons';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { axiosInstance } from '../Services';
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { Base64 } from 'js-base64';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function Emailverification(props) {
    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        getuseremailid();
        setshowloader(false);
    }, []);

    function handelresendotp() {
        setFomdatas({
            email_otp: "",
        });
        handelonclicksendotp();
    }

    const [form_datas, setFomdatas] = useState({
        email_otp: '',
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const [form_errormsg, setformerrormsg] = useState({
        email_otp_errormsg: "",
    });

    function handleInputChange(event) {
        if (event.target.name === "email_otp") {
            setFomdatas({
                ...form_datas,
                email_otp: event.target.value
            });
        }
    }

    function formvalidation() {

        let email_otp_errormsg = '';
        const re = /^[0-9\b]+$/;

        if (!form_datas.email_otp) {
            email_otp_errormsg = "Email OTP cannot be blank";
        }

        else if (form_datas.email_otp.length !== 6) {
            email_otp_errormsg = "Enter Valid Email OTP";
        }

        else if (re.test(form_datas.email_otp) === false) {
            email_otp_errormsg = "Enter Valid Email OTP";
        }

        if (email_otp_errormsg) {
            setformerrormsg({
                email_otp_errormsg: email_otp_errormsg,
            });
            return false;
        }

        else {
            return true;
        }
    }

    function handelverification() {
        const validated = formvalidation();
        if (validated) {
            setshowloader(true);
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "userid": userid_o,
                "email_otp": form_datas.email_otp,
                "email_id":otp_form_datas.email_id
            }
            setformerrormsg({
                email_otp_errormsg: "",
            });
            axiosInstance.post('/Otp/verifyemailotp', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setFomdatas({
                            email_otp: '',
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                        navigate("/applicationform");
                    }
                    else if (res.data.status === 'failed') {
                        seterror_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    }

                    setshowloader(false);
                })
                .catch(() => {
                    seterror_msgs({
                        ...error_msgs,
                        show_error_msg: true,
                        error_msg: 'Something went wrong please try again'
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 5000);
                    setshowloader(false);
                });
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

   // ********************************* Modal

    const [showenteremailid, setshowenteremailid] = useState(false);
    const [useremailid, setemailid] = useState('');
    const [useremailidtwo, setuseremailidtwo] = useState('');
    const [emailidverification, setemailidverification] = useState(true);
    const [emailidstatus, setemailidstatus] = useState(false);

    const openemailidverification = () => {
        setemailidverification(true);
    }

    const closeemailidverification = () => {
        setemailidverification(false);
        if (emailidstatus === false) {
            navigate("/");
        }
    }

    const [otp_form_datas, setotp_form_datas] = useState({
        email_id: '',
    });

    const [otp_form_errormsg, setotp_form_errormsg] = useState({
        email_id_errormsg: "",
    });


    function getuseremailid() {
        setshowloader(true);
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "userid": userid_o,
        }
        axiosInstance.post('/Userdetails/getemailid', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setemailid(res.data.data);
                    setotp_form_datas({
                        ...otp_form_datas,
                        email_id: res.data.data
                    });
                    const first3 = res.data.data.substring(0, 4);
                    setuseremailidtwo(first3);
                }
                setshowloader(false);
            })
            .catch(() => {
                setshowloader(false);
            });
    }

    function handleOTPInputChange(event) {
        if (event.target.name === "email_id") {
            setotp_form_datas({
                ...otp_form_datas,
                email_id: event.target.value
            });
        }
    }

    function handelonclicksendotp() {
        const validated = otpformvalidation();
        if (validated) {
            const first3 = otp_form_datas.email_id.substring(0, 3);
            setuseremailidtwo(first3);
            setshowloader(true);
            setotp_form_errormsg({
                email_id_errormsg: "",
            });
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "userid": userid_o,
                "email_id": otp_form_datas.email_id,
                "type":"email"
            }
            axiosInstance.post('/Otp/sendotp', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setemailidverification(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 3000);
                    }
                    else if (res.data.status === 'failed') {

                    }
                    setshowloader(false);
                })
                .catch(() => {
                    setshowloader(false);
                });
        }
    }

    function otpformvalidation() {
        let email_id_errormsg = '';
        var mailformat = /(^\w.*@\w+\.\w)/;
        
        if (!otp_form_datas.email_id) 
        {
            email_id_errormsg = "* Email id cannot be blank";
        }
        else if (mailformat.test(otp_form_datas.email_id) === false) {
            email_id_errormsg = "Enter Valid Email";
        }
        if (email_id_errormsg) {
            setotp_form_errormsg({
                email_id_errormsg: email_id_errormsg,
            });
            return false;
        }
        else {
            return true;
        }
    }

    function handelchangenumber() {
        setshowenteremailid(true);
    }    
    
    function handelchangenumber_d() {
        setemailidverification(true);
        setshowenteremailid(true);
    }


    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner">
                                    <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Email Verification</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className='text-center mb-4'>
                                    <p style={{ margin: 0, lineHeight: 2 }}>Enter the OTP which you received on your registered Email id <br /> {useremailidtwo}******** To verify and proceed next</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-2"></div> */}
                    </div>
                </section>

                <section className='mb-5 mt-2'>
                    <div className="container">
                        <div style={{ padding: 2 }}>
                            <div className="row mb-3">
                                <div className="col-md-4 offset-md-4">
                                    <div className="form-group mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="" style={{ padding: '.75rem' }}><FontAwesomeIcon icon={faEnvelope} /></span>
                                            </div>
                                            <input type="text" className="form-control" data-inputmask="'mask': '99999 99999'" placeholder="Email OTP" style={{ width: '60%' }} name="email_otp" value={form_datas.email_otp} onChange={handleInputChange} maxLength={6} />
                                        </div>
                                        {form_errormsg.email_otp_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.email_otp_errormsg}</span> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="btn btn-secondary btn-block w-100" onClick={handelverification} >
                                                Verify
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: 10 }}>
                                    <div className="col-6 social text-start">
                                            <p onClick={() => handelchangenumber_d()} className='m-0' style={{ color: "#09988e", fontWeight: 700, cursor: 'pointer' }}>Change my email</p>
                                        </div>                                        
                                        <div className="col-6 social text-center">
                                            <p onClick={() => handelresendotp()} className='m-0' style={{ color: "#09988e", fontWeight: 700, cursor: 'pointer' }}>Resend OTP</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    {
                        error_msgs.show_success_msg === true ?
                            <>
                                <div className="container success_msg_bx">
                                    <h4>{error_msgs.success_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    {
                        error_msgs.show_error_msg === true ?
                            <>
                                <div className="container error_msg_bx">
                                    <h4>{error_msgs.error_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    <br />
                </section>

                <Modal classNames={'mobileverifymodal'} open={emailidverification} onClose={closeemailidverification} center closeOnOverlayClick={false}>
                    <div className="row mt-3 mb-3">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="con-bottom-inner" style={{ paddingBottom: '10px' }}>
                                <h3><span style={{ fontWeight: 700, color: '#6c757d', textAlign: "right" }}>Verify your email</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className='mobileverify text-center'>
                        <div className='header'>
                            <img src='/assets/img/message.png' />
                        </div>
                    </div>

                    {
                        showenteremailid === true ?
                            <>
                                <div className="col-12">
                                    <div className="card-body" style={{ padding: "15px" }}>
                                        <div className="form-group mb-3">
                                            <p>Enter your new Mobile number</p>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="" style={{ padding: '.75rem' }}><FontAwesomeIcon icon={faEnvelope} /></span>
                                                </div>
                                                <input type="text" className="form-control" style={{ width: '65%' }} name="email_id" value={otp_form_datas.email_id} onChange={handleOTPInputChange} />
                                            </div>
                                            {otp_form_errormsg.email_id_errormsg !== '' ?
                                                <span className='error_msg'>{otp_form_errormsg.email_id_errormsg}</span> : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-7"></div>
                                        <div className="col-5">
                                            <button type="button" onClick={() => handelonclicksendotp()} className="btn btn-secondary w-100">Send</button>
                                        </div>
                                    </div>
                                </div>

                            </>
                            :
                            <>
                                <div className="col-12">
                                    <div className="card-body"
                                        style={{ padding: "15px", color: '#6c757d' }}>
                                        <p className='selectaadon'>Get a verification code</p>
                                        <p>GServes will send a verification code to {useremailidtwo}********</p>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-7">
                                            <h6 onClick={(e) => handelchangenumber(e)} style={{ fontWeight: 700, color: '#09988e', cursor: 'pointer' }}>Change my email</h6>
                                        </div>
                                        <div className="col-5">
                                            <button type="button" onClick={() => handelonclicksendotp()} className="btn btn-secondary w-100">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Modal>


            </main>
            <FooterComp />
        </>
    );
}

export default Emailverification;