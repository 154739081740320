import React, { useEffect } from 'react';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { useNavigate } from "react-router-dom";
import LoginForm from '../components/loginform';
import * as device from 'react-device-detect';


function LoginPage(props) {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('logging_status') === true || localStorage.getItem('logging_status') === 'true') {
            navigate("/");
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            {device.isBrowser === true ?
                                <div className="pdb-20">
                                    <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                                </div> :
                                <>
                                </>}
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className={device.isMobileOnly === true ? " col-1" : "col-2"}></div>
                            <div className={device.isMobileOnly === true ? " col-10" : "col-8"}>
                                <div className="con-bottom-inner">
                                    <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Login</span></h3>
                                </div>
                            </div>
                            <div className={device.isMobileOnly === true ? " col-1" : "col-2"}></div>
                        </div>
                    </div>
                </section>
                <LoginForm datafrom={'login'} />
            </main>
            <FooterComp />
        </>
    );
}

export default LoginPage;