import React, { useState, useEffect, useRef } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { axiosInstance } from '../Services';
import { Link } from "react-router-dom";


function SearchComp(props) {

    const myRef = useRef();
    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            hideDropdown();
        }
    };
    const handleClickInside = () => {
        showDropdown();
    }

    const [searchsuggestion, setsearchsuggestion] = useState([]);
    const [searchsuggestionvalue, setsearchsuggestionvalue] = useState('');
    useEffect(() => {
        getsearchsuggestion();
    }, [searchsuggestionvalue])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        getsearchsuggestion();
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])

    function showDropdown() {
        var a = document.getElementsByClassName('search_suggestions_list');
        if (a.length > 0) {
            a[0].classList.remove('b_none');
            a[0].classList.add('b_block');
        }
        getsearchsuggestion();
    }

    const hideDropdown = e => {
        var a = document.getElementsByClassName('search_suggestions_list');
        if (a.length > 0) {
            a[0].classList.remove('b_block');
            a[0].classList.add('b_none');
        }
        setsearchsuggestion([]);
        // setsearchsuggestionvalue('');
    }

    function handelonchange(event) {
        setsearchsuggestionvalue(event.target.value);
        // getsearchsuggestion();
    }
    function getsearchsuggestion() {

        axiosInstance.get('/Search', { params: { searchkey: searchsuggestionvalue } })
            .then((res) => {
                setsearchsuggestion(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div className='search_suggestions' ref={myRef} onClick={handleClickInside}  >
            <InputGroup>
                <FormControl
                    placeholder="Type the service you are looking for"
                    aria-label="Type the service you are looking for"
                    aria-describedby="basic-addon2"
                    name='search_suggestion'
                    value={searchsuggestionvalue}
                    onChange={handelonchange}
                />
                <Button id="button-addon2" style={{ width: '100px', backgroundColor: '#09988e', boxShadow: 'none', border: 'none' }}>
                    <FontAwesomeIcon icon={faSearch} /> &nbsp;
                </Button>
            </InputGroup>

            {searchsuggestion.length > 0 ?
                <div className='search_suggestions_list'>
                    {searchsuggestion.map((value, index) => {
                        return (
                            <p key={index}>
                                <Link onClick={() => hideDropdown()} to={'/serves/' + value.dept_name.replace(/[ ,]+/g, "-") + '/' + value.menu_name.replace(/[ ,]+/g, "-")}>
                                    {value.menu_name}
                                </Link>
                            </p>
                        );
                    })}
                </div> :
                null}
        </div>
    );

}

export default SearchComp;