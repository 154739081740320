import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { axiosInstance } from '../Services';
import { Base64 } from 'js-base64';


function Forgetpassword(props) {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('logging_status') === true || localStorage.getItem('logging_status') === 'true') {
            navigate("/");
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const [form_datas, setFomdatas] = useState({
        username: '',
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const [form_errormsg, setformerrormsg] = useState({
        username_errormsg: "",
    });

    function handleInputChange(event) {
        if (event.target.name === "username") {
            setFomdatas({
                ...form_datas,
                username: event.target.value
            });
        }
    }

    function formvalidation() {

        let username_errormsg = '';
        const re = /^[0-9\b]+$/;
        if (!form_datas.username) {
            username_errormsg = "Mobile number cannot be blank";
        }

        else if (form_datas.username.length !== 10) {
            username_errormsg = "Enter Valid mobile number";
        }

        else if (re.test(form_datas.username) === false) {
            username_errormsg = "Enter Valid mobile number";
        }

        if (username_errormsg) {
            setformerrormsg({
                username_errormsg: username_errormsg,
            });
            return false;
        }

        else {
            return true;
        }
    }

    function handelforgetpassword() {
        const validated = formvalidation();
        if (validated) {
            setformerrormsg({
                username_errormsg: "",
            });
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            const JSONvalue = {
                "username": form_datas.username,
                "userid": userid_o,
            };
            // navigate("/resetpassword");
            axiosInstance.post('/Otp/send', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setFomdatas({
                            username: '',
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                        var type = Base64.encode(form_datas.username, true);
                        navigate("/resetpassword?type=" + type);
                    }
                    else if (res.data.status === 'failed') {
                        seterror_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    }
                })
                .catch(() => {
                    seterror_msgs({
                        ...error_msgs,
                        show_error_msg: true,
                        error_msg: 'Something went wrong please try again'
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 5000);
                });
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }


    return (
        <>
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner">
                                    <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Forgot Password</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className='text-center mb-4'>
                                    <p style={{ margin: 0, lineHeight: 2 }}>Enter your registered mobile number we'll send you a <br /> OTP to reset your password</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mb-5 mt-2'>
                    <div className="container">
                        <div style={{ padding: 2 }}>
                            <div className="row mb-3">
                                <div className="col-md-4 offset-md-4">
                                    <div className="form-group mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="" style={{ padding: '.75rem' }}><FontAwesomeIcon icon={faMobile} /></span>
                                            </div>
                                            <input type="text" className="form-control" placeholder="+91" disabled />
                                            <input type="text" className="form-control" data-inputmask="'mask': '99999 99999'" placeholder="Mobile number" style={{ width: '60%' }} name="username" value={form_datas.username} onChange={handleInputChange} maxLength={10} />
                                        </div>
                                        {form_errormsg.username_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.username_errormsg}</span> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="btn btn-secondary btn-block w-100" onClick={handelforgetpassword} >
                                                Send OTP
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: 10 }}>
                                        <div className="col-12 social text-center">

                                            <Link to={'/login'}> <FontAwesomeIcon icon={faChevronLeft} /> Back to Login</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    {
                        error_msgs.show_success_msg === true ?
                            <>
                                <div className="container success_msg_bx">
                                    <h4>{error_msgs.success_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    {
                        error_msgs.show_error_msg === true ?
                            <>
                                <div className="container error_msg_bx">
                                    <h4>{error_msgs.error_msg}</h4>
                                </div>
                            </> :
                            null
                    }

                    <br />
                </section>
            </main>
            <FooterComp />
        </>
    );
}

export default Forgetpassword;