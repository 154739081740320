import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import HomePage from './homepage';
import LoginPage from './login';
import RegisterPage from './register';
import ServicesPage from './services';
import Contactus from './contactus';
import Legalinfo from './legalinfo';
import Forgetpassword from './forgetpassword';
import Verification from './verification';
import Applicationform from './applicationform';
import Editapplicationform from './editapplicationform';
import Resetpassword from './resetpassword';
import Transaction from './transaction';
import Payment from './payment';
import Transactiondetails from './transactiondetails';
import Emailverification from './emailverification';

const Pages = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/register" element={<RegisterPage />} />
                <Route exact path="/contactus" element={<Contactus />} />
                <Route exact path="/legalinfo" element={<Legalinfo />} />
                <Route exact path="/forgetpassword" element={<Forgetpassword />} />
                <Route exact path="/verification" element={<Verification />} />
                <Route exact path="/emailverification" element={<Emailverification />} />
                <Route exact path="/applicationform" element={<Applicationform />} />
                <Route exact path="/editapplicationform" element={<Editapplicationform />} />
                <Route exact path="/resetpassword" element={<Resetpassword />} />
                <Route exact path="/transaction" element={<Transaction />} />
                <Route exact path="/payment" element={<Payment />} />
                <Route exact path="/transactiondetails" element={<Transactiondetails />} />
                <Route exact path="/serves/:departmentname/:servesname" element={<ServicesPage />} />
            </Routes>
        </Router>
    );
}; export default Pages;