import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import BannerMenuComp from '../components/bannermenu';
import SearchComp from '../components/search';
import { useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import { axiosInstance } from '../Services';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTrash, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-responsive-modal/styles.css';
import BounceLoader from "react-spinners/BounceLoader";
import { Modal } from 'react-responsive-modal';
import * as device from 'react-device-detect';

// const Razorpay = require('razorpay')

function Payment(props) {
    const childCompRef = useRef();
    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    const [showcomments, setshowcomments] = useState(false);
    const [loadedapi, setloadedapi] = useState(false);
    const [transactiondetails, settransactiondetails] = useState([]);
    const [commentdetails, setcommentdetails] = useState([]);
    const [transaction_cmd, settransaction_cmd] = useState('');
    const [TotalPayable, setTotalPayable] = useState(0);
    const [Nowpayable_d, setNowpayable_d] = useState(0);
    const [Nowpayable, setNowpayable] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerMobile, setCustomerMobile] = useState('');
    const [CustomerEmail, setCustomerEmail] = useState('');
    const [services_name_array, setservices_name_array] = useState([]);
    const [referenceIdArray, setreferenceIdArray] = useState([]);
    const [transaction_id_array, settransaction_id_array] = useState([]);
    const [payable_amout_array, setpayable_amout_array] = useState([]);
    const [deffered_amout_array, setdeffered_amout_array] = useState([]);
    const [paynow_amout_array, setpaynow_amout_array] = useState([]);

    // set loggin details
    const [loggingstatus, setloggingstatus] = useState(false);
    const [loggedusername, setloggedusername] = useState('');
    const [userid, setuserid] = useState(0);
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        else {
            if (localStorage.getItem('requestid') === null && localStorage.getItem('requestid') === undefined) {
                navigate("/");
            }
            else {
                if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                    setloggingstatus(localStorage.getItem('logging_status'));
                    setloggedusername(Base64.decode(localStorage.getItem('gsun')));
                    setuserid(Base64.decode(localStorage.getItem('gsud')));
                } else {
                    setloggingstatus(false);
                }
                getapplicationformdetails();
                setshowloader(false);
            }
        }
        localStorage.removeItem("refid");
        localStorage.removeItem("tdid");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    function getapplicationformdetails() {
        setloadedapi(true);
        let userid_o = 0;
        let requestid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
            requestid_o = localStorage.getItem('requestid');
        }

        if (requestid_o === 0) {
            navigate('/');
        }
        else {
            let myarrayss = requestid_o.split(',');
            let mydatas = {
                "userid": userid_o,
                "requestid": myarrayss,
            }
            axiosInstance.post('/Paymentdetails', mydatas)
                .then((res) => {
                    settransactiondetails(res.data.data);
                    if (res.data.data[0]['commentdetails_array'].length <= 1) {
                        setshowcomments(true);
                    }
                    else {
                        setshowcomments(false);
                    }
                    if (res.data.data.length > 0) {
                        setcommentdetails(res.data.data[0]['commentdetails_array'][0]);
                        settransaction_cmd(res.data.data[0]['transaction_cmd']);
                        setTotalPayable(res.data.data[0]['total_charges']);
                        setNowpayable(res.data.data[0]['now_payable']);
                        setNowpayable_d(res.data.data[0]['now_payable']);
                        setCustomerName(res.data.data[0]['customer_name']);
                        setCustomerMobile(res.data.data[0]['customer_mobile']);
                        setCustomerEmail(res.data.data[0]['customer_email']);
                        setservices_name_array(res.data.data[0]['services_name_ddo']);
                        setreferenceIdArray(res.data.data[0]['RefId_array']);
                        setpayable_amout_array(res.data.data[0]['payable_amout_array']);
                        setdeffered_amout_array(res.data.data[0]['deffered_amout_array']);
                        setpaynow_amout_array(res.data.data[0]['paynow_amout_array']);
                        settransaction_id_array(res.data.data[0]['transaction_id_array']);
                    }
                    setloadedapi(false);
                })
                .catch((err) => {
                    setloadedapi(false);
                    console.log(err);
                });
        }
    }

    function handeleditform() {
        let requestid_o = 0;
        if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
            requestid_o = localStorage.getItem('requestid');
        }
        var url = window.location.href;
        var parts = url.split('/');
        localStorage.setItem('refid', requestid_o);
        navigate("/editapplicationform/");
        if (parts[3] === 'editapplicationform') {
            window.location.reload();
        }
    }

    const [commentsmodal, setcommentsmodal] = useState(false);
    const commentsmodalclose = () => {
        setcommentsmodal(false);
        setFomdatas({
            comment_msg: '',
            comment_id: 0,
            reply_id: 0,
            doc_selected: '',
            doc_selected_name: '',
            doc_url: '',
            selected_file_dd: false,
            data_type: ''
        });
    }
    const commentsmodalopen = () => {
        if (loggingstatus === true || loggingstatus === 'true') {
            setcommentsmodal(true);
            cleardatas();
            setFomdatas({
                ...form_datas,
                comment_id: 0,
                data_type: "insert"
            });
        }
        else {
            // openloginmodal();
        }
    }

    const [form_datas, setFomdatas] = useState({
        comment_msg: '',
        comment_id: 0,
        reply_id: 0,
        doc_selected: '',
        doc_selected_name: '',
        doc_url: '',
        selected_file_dd: false,
        data_type: ''
    });

    const [form_errormsg, setformerrormsg] = useState({
        comment_msg_errormsg: "",
        doc_selected_errormsg: "",
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const handelcommentsonchange = (e) => {
        setFomdatas({
            ...form_datas,
            comment_msg: e.target.value,
        });
    }

    function handeldocumentformonchange(event) {
        setFomdatas({
            ...form_datas,
            doc_selected: event.target.files[0],
            doc_selected_name: event.target.files[0].name,
            doc_url: URL.createObjectURL(event.target.files[0]),
            selected_file_dd: true
        });
    }

    function handelremovedoce(e) {
        setFomdatas({
            ...form_datas,
            doc_selected: '',
            doc_selected_name: '',
            doc_url: '',
            selected_file_dd: false
        });
    }

    function formvalidation() {
        let comment_msg_errormsg = '';
        let doc_selected_errormsg = '';

        if (!form_datas.comment_msg) {
            comment_msg_errormsg = "* Message cannot be blank";
        }

        if (form_datas.doc_selected.size !== undefined && form_datas.doc_selected.size >= 2000000) {
            doc_selected_errormsg = "* File too large. File must be less than 2 megabytes.";
        }

        if (comment_msg_errormsg || doc_selected_errormsg) {
            setformerrormsg({
                doc_selected_errormsg: doc_selected_errormsg,
                comment_msg_errormsg: comment_msg_errormsg
            });
            return false;
        }
        else {
            return true;
        }
    }

    function handlecommentsubmit() {
        const validated = formvalidation();
        if (validated) {
            setshowloader(true);
            setformerrormsg({
                comment_msg_errormsg: "",
            });
            let requestid_o = 0;
            if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                requestid_o = localStorage.getItem('requestid');
            }
            const formData = new FormData();
            formData.append("comment_id", form_datas.comment_id);
            formData.append("comment_msg", form_datas.comment_msg);
            formData.append("reply_id", form_datas.reply_id);
            formData.append("comments_file", form_datas.doc_selected);
            formData.append("userid", userid);
            formData.append("transaction_id", requestid_o);
            formData.append("doc_url", form_datas.doc_url);
            if (form_datas.data_type === 'insert') {
                axiosInstance.post('/Paymentdetails/insertcomments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status === 'success') {
                            seterror_msgs({
                                show_success_msg: true,
                                success_msg: res.data.msg,
                                show_error_msg: false,
                                error_msg: ''
                            });
                            setFomdatas({
                                comment_msg: '',
                                comment_id: 0,
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            getapplicationformdetails();
                            setshowloader(false);
                            commentsmodalclose();
                        }
                        else if (res.data.status === 'failed') {
                            seterror_msgs({
                                show_success_msg: false,
                                success_msg: '',
                                show_error_msg: true,
                                error_msg: res.data.msg
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            setshowloader(false);
                        }
                    })
                    .catch((err) => {
                        seterror_msgs({
                            ...error_msgs,
                            show_error_msg: true,
                            error_msg: 'Something went wrong please try again'
                        });
                        setshowloader(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    })
            }
            else {
                axiosInstance.post('/Paymentdetails/updatecomments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status === 'success') {
                            seterror_msgs({
                                show_success_msg: true,
                                success_msg: res.data.msg,
                                show_error_msg: false,
                                error_msg: ''
                            });
                            setFomdatas({
                                comment_msg: '',
                                comment_id: 0,
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            getapplicationformdetails();
                            setshowloader(false);
                            commentsmodalclose();
                        }
                        else if (res.data.status === 'failed') {
                            seterror_msgs({
                                show_success_msg: false,
                                success_msg: '',
                                show_error_msg: true,
                                error_msg: res.data.msg
                            });
                            setTimeout(() => {
                                cleardatas();
                            }, 5000);
                            setshowloader(false);
                        }
                    })
                    .catch((err) => {
                        seterror_msgs({
                            ...error_msgs,
                            show_error_msg: true,
                            error_msg: 'Something went wrong please try again'
                        });
                        setshowloader(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 5000);
                    })
            }
        }
    }

    function handreplycomments(id) {
        if (loggingstatus === true || loggingstatus === 'true') {
            setcommentsmodal(true);
            cleardatas();
            setFomdatas({
                ...form_datas,
                comment_id: id,
                data_type: "insert"
            });
        }
        else {
            // openloginmodal();
        }
    }

    function handeleditcomments(value, type) {
        if (loggingstatus === true || loggingstatus === 'true') {
            let selected_file_dd = false;
            if (value.comments_file !== '') {
                selected_file_dd = true;
            }

            if (type === 'comment') {
                setFomdatas({
                    ...form_datas,
                    comment_id: value.comment_id,
                    comment_msg: value.comment_msg,
                    doc_selected: value.comments_file,
                    selected_file_dd: selected_file_dd,
                    doc_url: value.comments_file,
                    doc_selected_name: value.comments_file_name,
                    reply_id: 0,
                    data_type: "edit"
                });
            }
            if (type === 'edit') {
                setFomdatas({
                    ...form_datas,
                    comment_id: value.comment_id,
                    comment_msg: value.comment_msg,
                    doc_selected: value.comments_file,
                    doc_url: value.comments_file,
                    doc_selected_name: value.comments_file_name,
                    selected_file_dd: selected_file_dd,
                    reply_id: value.reply_id,
                    data_type: "edit"
                });
            }
            setcommentsmodal(true);
            cleardatas();
        }
        else {
            // openloginmodal();
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

    const [PaymentDatas, setPaymentDatas] = useState({
        payable_amount: 0,
        application_mode: '',
        application_status: '',
        payment_mode: '',
        payment_status: '',
        payment_id: '',
        address_line_one: '',
        address_line_two: '',
        landmark: '',
        pincode: '',
        preferred_date: '',
        preferred_time: '',
        refid: [],
        userid: userid
    });

    const [PaymentError, setPaymentError] = useState({
        application_mode: '',
        payment_mode: '',
        payment_total: '',
        address_line_one: '',
        address_line_two: '',
        landmark: '',
        pincode: '',
        preferred_date: '',
        preferred_time: '',
    });

    const handelpaymentonchange = (e) => {

        if (e.target.name == 'application_mode') {
            setPaymentDatas({
                ...PaymentDatas,
                [e.target.name]: e.target.value,
                address_line_one: '',
                address_line_two: '',
                landmark: '',
                pincode: '',
                preferred_date: '',
                preferred_time: '',
            });
        }
        else {
            setPaymentDatas({
                ...PaymentDatas,
                [e.target.name]: e.target.value
            });
        }
        setPaymentError({
            ...PaymentError,
            [e.target.name]: ''
        });
    }

    function paymentvalidation() {
        let application_mode = '';
        let payment_total = '';
        let address_line_one = '';
        let address_line_two = '';
        let landmark = '';
        let pincode = '';
        let preferred_date = '';
        let preferred_time = '';

        if (!PaymentDatas.application_mode) {
            application_mode = "* Select the Application collection Mode";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.address_line_one == '') {
            address_line_one = "* This field is required";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.address_line_two == '') {
            address_line_two = "* This field is required";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.landmark == '') {
            landmark = "* This field is required";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.pincode == '') {
            pincode = "* This field is required";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.preferred_date == '') {
            preferred_date = "* This field is required";
        }
        if (PaymentDatas.application_mode == 'Visit_my_place' && PaymentDatas.preferred_time == '') {
            preferred_time = "* This field is required";
        }
        if (Nowpayable < Nowpayable_d) {
            payment_total = '* Payable Now cannot be lesser than Rs ' + Nowpayable_d + '';
        }
        if (Nowpayable > TotalPayable) {
            payment_total = '* Payable Now cannot be greater than total charges';
        }
        if (application_mode || payment_total || address_line_one || address_line_two || landmark || pincode || preferred_date || preferred_time) {
            setPaymentError({
                application_mode: application_mode,
                payment_total: payment_total,
                address_line_one: address_line_one,
                address_line_two: address_line_two,
                landmark: landmark,
                pincode: pincode,
                preferred_date: preferred_date,
                preferred_time: preferred_time,
            });
            return false;
        }
        else {
            setPaymentError({
                application_mode: application_mode,
                payment_total: payment_total,
                address_line_one: address_line_one,
                address_line_two: address_line_two,
                landmark: landmark,
                pincode: pincode,
                preferred_date: preferred_date,
                preferred_time: preferred_time,
            });
            return true;
        }
    }


    const [tearmsandconditionmodal, settearmsandconditionmodal] = useState(false);
    const tearmsandconditionmodalclose = () => {
        setterms_condition(false);
        settearmsandconditionmodal(false);
    }
    const tearmsandconditionmodalopen = () => {
        settearmsandconditionmodal(true);
    }


    function makepaymentsubmit() {
        const validate_payment = paymentvalidation();
        if (validate_payment) {
            tearmsandconditionmodalopen();
        }
    }

    function makepaymentwithtermsandcondition(params) {
        tearmsandconditionmodalclose();
        const validate_payment = paymentvalidation();
        if (validate_payment) {
            // create order id in razorpay
            let my_ordervalues = {
                "total_pay": Nowpayable,
                // "total_pay": 1,
                "": Nowpayable,
            }
            let order_id_pay = 0;
            setshowloader(true);
            axiosInstance.post('/Paymentdetails/crateorder', my_ordervalues)
                .then((res) => {
                    if (res.data.status === 'success') {
                        order_id_pay = res.data.order_id;
                        // console.log(res.data.order_id);
                        initatepaymentstatus(order_id_pay);
                    }
                    if (res.data.status === 'failed') {
                        console.log(res.data);
                    }
                    setshowloader(false);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        }
    }

    function initatepaymentstatus(order_id_pay) {

        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = PaymentDatas;
        var balanceamout = TotalPayable - Nowpayable;
        JSONvalue.payable_amount = Nowpayable;
        JSONvalue.balanceamout = balanceamout;
        JSONvalue.payable_amout_array = payable_amout_array;
        JSONvalue.deffered_amout_array = deffered_amout_array;
        JSONvalue.paynow_amout_array = paynow_amout_array;
        JSONvalue.payment_id = '';
        JSONvalue.userid = userid_o;
        let myarrayss = []
        if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
            let requestid_o = localStorage.getItem('requestid');
            myarrayss = requestid_o.split(',');
        }

        JSONvalue.refid = myarrayss;

        // Replace the values below with your own credentials
        const merchantId = "pXRCnh37101807094808";
        const merchantKey = "eS2@wPolnKI#mxIN";
        const amount = (Nowpayable * 100);
        const txnId = `txn_${Date.now()}`;
        const callbackUrl = "https://merchant-website.com/callback";

        // customer details
        const customerDetails = {
            email: CustomerEmail,
            phone: CustomerMobile,
            name: CustomerName
        };

        // order details
        const orderDetails = {
            orderId: order_id_pay,
            customerId: customerDetails.email,
            amount: amount
        };

        // generate checksum using PaytmChecksum library
        //generateSignature(JSON.stringify(orderDetails), merchantKey)
        //.then(checksum => {
        // payment options
        //const paymentOptions = {
        //"ORDER_ID": orderDetails.orderId,
        //"MID": merchantId,
        // "TXN_AMOUNT": `${amount / 100}`,
        // "CUST_ID": customerDetails.email,
        // "MOBILE_NO": customerDetails.phone,
        // "EMAIL": customerDetails.email,
        //"CHANNEL_ID": "WEB",
        //"INDUSTRY_TYPE_ID": "Retail",
        //"WEBSITE": "WEBSTAGING",
        //"CALLBACK_URL": callbackUrl,
        //"CHECKSUMHASH": checksum
        //};

        // invoke payment flow
        //const paytm = window.Paytm;
        //paytm && paytm.invokePaymentFlow(paymentOptions);
        // })
        // .catch(error => {
        //     console.log(error);
        // });


        var options = {
            "key": "rzp_live_sgacU9tOXLx67Y",
            "amount": (Nowpayable * 100),
            "currency": "INR",
            "name": "Gserves",
            "order_id": order_id_pay,
            "description": "",
            "image": "http://65.1.59.105/assets/img/logo.png",
            "handler": function (response) {
                JSONvalue.payment_id = response.razorpay_payment_id;
                JSONvalue.payment_status = 'completed';
                JSONvalue.application_status = 'inprogress';
                insertpayment_details(JSONvalue);
            },
            "prefill": {
                "name": CustomerName,
                "email": CustomerEmail,
                "contact": CustomerMobile
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#09988e"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on('payment.failed', function (response) {
            //rzp1.close();
            //e.preventDefault();
        });
    }

    function insertpayment_details(values) {
        setshowloader(true);
        axiosInstance.post('/Paymentdetails/insert', values)
            .then((res) => {
                if (res.data.status === 'success') {
                    let myarrayss = [];
                    if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                        let requestid_o = localStorage.getItem('requestid');
                        myarrayss = requestid_o.split(',');
                    }

                    if (myarrayss.length > 1) {
                        localStorage.removeItem("refid");
                        localStorage.removeItem("requestid");
                        localStorage.removeItem("tdid");
                        navigate('/transaction');
                    }
                    else {
                        let dym = myarrayss.join();
                        localStorage.removeItem("refid");
                        localStorage.removeItem("requestid");
                        localStorage.setItem("tdid", dym);
                        navigate('/transactiondetails');
                    }
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [downloadappmodal, setdownloadapp] = useState(false);
    const downloadappclose = () => {
        setdownloadapp(false);
    }
    const downloadappopen = () => {
        setdownloadapp(true);
    }

    function handeldownloadapplication() {

        if (referenceIdArray.length == 1) {
            setshowloader(true);
            let myarrayss = []
            if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                let requestid_o = localStorage.getItem('requestid');
                myarrayss = requestid_o.split(',');
            }
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "refid": myarrayss,
                "userid": userid_o,
            }
            axiosInstance.post('/Applicationform/viewapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        if (res.data.data.length > 0) {
                            for (let i = 0; i < res.data.data.length; i++) {
                                const newWindow = window.open(res.data.data[i], '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            }
                        }
                    }
                    if (res.data.status === 'failed') {
                        console.log(res.data);
                    }
                    setshowloader(false);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        } else {
            downloadappopen();
        }
    }

    function handeldownloadapplicationbyid(index) {
        setshowloader(true);
        let myarrayss = [];
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        myarrayss.push(referenceIdArray[index]);
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewapplication', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    if (res.data.data.length > 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            const newWindow = window.open(res.data.data[i], '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        }
                    }
                    downloadappclose();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [email_error_msgs, setemail_error_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const [emailappmodal, setemailappmodal] = useState(false);
    const emailappmodalclose = () => {
        setemailappmodal(false);
    }
    const emailappmodalopen = () => {
        setemailappmodal(true);
    }

    function handelemailapplication() {
        if (referenceIdArray.length == 1) {
            setshowloader(true);
            let myarrayss = []
            if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                let requestid_o = localStorage.getItem('requestid');
                myarrayss = requestid_o.split(',');
            }
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "refid": myarrayss,
                "userid": userid_o,
            }
            axiosInstance.post('/Applicationform/emailapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        setemail_error_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                    }
                    if (res.data.status === 'failed') {
                        setemail_error_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                    }
                    setshowloader(false);
                    setTimeout(() => {
                        clearemaildatas();
                    }, 5000);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        }
        else {
            emailappmodalopen();
        }
    }

    function handelemailapplicationbyid(index) {
        setshowloader(true);
        let myarrayss = []
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        myarrayss.push(referenceIdArray[index]);
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/emailapplication', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setemail_error_msgs({
                        show_success_msg: true,
                        success_msg: res.data.msg,
                        show_error_msg: false,
                        error_msg: ''
                    });
                    emailappmodalclose();
                }
                if (res.data.status === 'failed') {
                    setemail_error_msgs({
                        show_success_msg: false,
                        success_msg: '',
                        show_error_msg: true,
                        error_msg: res.data.msg
                    });
                }
                setshowloader(false);
                setTimeout(() => {
                    clearemaildatas();
                }, 5000);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    function clearemaildatas() {
        setemail_error_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }


    const [documentmodal_datas, setdocumentmodal_datas] = useState([]);

    function handelviewdocumentsupload() {
        setshowloader(true);
        let myarrayss = []
        if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
            let requestid_o = localStorage.getItem('requestid');
            myarrayss = requestid_o.split(',');
        }
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewdocuments', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setdocumentmodal_datas(res.data.data['application_data']);
                    documentmodalopen();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [documentmodal, setdocumentmodal] = useState(false);
    const documentmodalclose = () => {
        setdocumentmodal(false);
    }
    const documentmodalopen = () => {
        setdocumentmodal(true);
    }

    function handelnowpayable(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setNowpayable(e.target.value);
        }
    }
    const [terms_condition, setterms_condition] = useState(false);
    function handeltermsandcondition(e) {
        // console.log(e.target);
        setterms_condition(!terms_condition);
    }


    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }
            <HeaderComp ref={childCompRef} />
            <main role="main">
                <section id='main_banner'>
                    <Container fluid className='bg-semi-dark' style={{ paddingBottom: 40 }} >
                        <Container>
                            <div className='intro-section mobintro-section'>
                                <Row>
                                    <Col className='pdb-10'>
                                        <BannerMenuComp />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pdb-20'>
                                        <SearchComp />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner custu_padd">
                                    <h4><span style={{ color: "#6c757d" }}>My Transaction</span></h4>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    transactiondetails.length > 0 ?
                        <>
                            {
                                transactiondetails.map((value, index) => {
                                    return (
                                        <section key={index}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-10 offset-md-1" style={device.isMobileOnly === true ? { padding: "10px 15px 20px 15px" } : { padding: "10px 40px 20px 40px" }}>
                                                        <div style={{ backgroundColor: '#09988e', padding: "20px" }} >
                                                            <div style={{ backgroundColor: '#fff', padding: "20px" }}>
                                                                <div className="form-card">
                                                                    <h2 className="fs-title text-center">Success !</h2> <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className={device.isMobileOnly === true ? "col-6" : "col-3"} >

                                                                            <img
                                                                                src="/assets/img/success.png"
                                                                                className="fit-image" /> </div>
                                                                    </div> <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className={device.isMobileOnly === true ? "text-center w-100" : "text-center w-50"} >
                                                                            <h5 style={{ paddingBottom: "20px" }}>Thanks for requesting <b>{services_name_array}</b>. Our team will contact you as soon as possible.
                                                                            </h5>
                                                                            <button onClick={() => navigate("/")} className="btn btn-dark btn-lg" style={{ backgroundColor: "#6c757d", borderRadius: "5px" }}>Ok</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div id="msform" style={{ textAlign: "left" }}>
                                                                    <div className="form-group row mb-3">
                                                                        <label htmlFor='colFormLabel' className="text-left col-5 col-form-label">Application collection Mode</label>
                                                                        <div className="col-7">
                                                                            <select className="form-control custom-select-sm" id='colFormLabel' name='application_mode' value={PaymentDatas.application_mode} onChange={(e) => handelpaymentonchange(e)}>
                                                                                <option value=''>--- Select ---</option>
                                                                                <option value="visit">Please visit my place</option>
                                                                                <option value="courier">Will be sent by courier</option>
                                                                                <option value="uploaded">Already uploaded online</option>
                                                                            </select>
                                                                            {PaymentError.application_mode !== '' ?
                                                                                <span className='error_msg'>{PaymentError.application_mode}</span> :
                                                                                <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        PaymentDatas.application_mode == 'visit' ?
                                                                            <>
                                                                                <div className="col-md-12 mb-3 text-start" style={{ paddingTop: '0px' }}>
                                                                                    <h5><span style={{ color: "#6c757d" }}>Address Details</span></h5>
                                                                                    <p>Please provide address, if different from Address in the application.</p>
                                                                                </div>
                                                                                <div className="form-group row mb-3">
                                                                                    <label htmlFor='colFormLabel' className="text-left col-5 col-form-label">Address details</label>
                                                                                    <div className="col-7">
                                                                                        <textarea rows="1" id="colFormLabel" className="form-control" name="address_line_one" value={PaymentDatas.address_line_one} onChange={(e) => handelpaymentonchange(e)} placeholder='' />
                                                                                        {PaymentError.address_line_one !== '' ?
                                                                                            <span className='error_msg'>{PaymentError.address_line_one}</span> :
                                                                                            <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group row mb-3">
                                                                                    <label htmlFor='colFormLabel' className="text-left col-5 col-form-label">Preferred date</label>
                                                                                    <div className="col-7">
                                                                                        <input type="text" id="colFormLabel" className="form-control" name="preferred_date" value={PaymentDatas.preferred_date} onChange={(e) => handelpaymentonchange(e)} placeholder='DD-MM-YYYY' />
                                                                                        {PaymentError.preferred_date !== '' ?
                                                                                            <span className='error_msg'>{PaymentError.preferred_date}</span> :
                                                                                            <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group row mb-3">
                                                                                    <label htmlFor='colFormLabel' className="text-left col-5 col-form-label">Preferred time</label>
                                                                                    <div className="col-7">
                                                                                        <select className="form-control custom-select-sm" id='colFormLabel' name='preferred_time' value={PaymentDatas.preferred_time} onChange={(e) => handelpaymentonchange(e)}>
                                                                                            <option value=''>--- Select ---</option>
                                                                                            <option value="Any_time">Any time</option>
                                                                                            <option value="Weekday_morning">Weekday morning</option>
                                                                                            <option value="Weekday_afternoon">Weekday afternoon</option>
                                                                                            <option value="Saturday_morning">Saturday morning</option>
                                                                                            <option value="Saturday_afternoon">Saturday afternoon</option>
                                                                                        </select>
                                                                                        {PaymentError.preferred_time !== '' ?
                                                                                            <span className='error_msg'>{PaymentError.preferred_time}</span> :
                                                                                            <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </> : PaymentDatas.application_mode == 'courier' ?
                                                                                <>
                                                                                    <div className="col-md-12 mb-3 text-start" style={{ paddingTop: '0px' }}>
                                                                                        <h5><span style={{ color: "#6c757d" }}>Courier Details</span></h5>
                                                                                    </div>
                                                                                    {
                                                                                        value.courier_details.map((value2, index2) => {
                                                                                            return (
                                                                                                <div key={index2}>
                                                                                                    <p >{value2}</p>

                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                </> :
                                                                                null
                                                                    }
                                                                </div> */}

                                                                {/* <div className="col-md-12 mb-3" style={{ paddingTop: '20px' }}>
                                                                    <h5><span style={{ color: "#6c757d" }}>Payment Details</span></h5>
                                                                </div>

                                                                {value.application.length > 0 ?
                                                                    <>
                                                                        {
                                                                            value.application.map((value2, index2) => {
                                                                                return (
                                                                                    <React.Fragment key={index2}>
                                                                                        <div className="col-md-12 p-0">
                                                                                            <h6 style={{ paddingBottom: "10px", paddingTop: "10px" }}>{value2.serves_name}</h6>
                                                                                        </div>

                                                                                        <div className="row mb-2">
                                                                                            <div className="col-6">
                                                                                                <span>Reference ID</span>
                                                                                                <span className='float-end'> : </span>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="float-end">
                                                                                                    <span><b>{value.customer_mobile} - 00{value2.refid}</b></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row mb-2">
                                                                                            <div className="col-6">
                                                                                                <span>Service charges</span>
                                                                                                <span className='float-end'> : </span>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="float-end">
                                                                                                    <span><b>&#8377; {value2.service_charge_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row mb-2">
                                                                                            <div className="col-6">
                                                                                                <span>Out of pocket exps</span>
                                                                                                <span className='float-end'> : </span>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="float-end">
                                                                                                    <span><b>&#8377; {value2.other_exps_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row mb-2">
                                                                                            <div className="col-6">
                                                                                                <span>Government fees</span>
                                                                                                <span className='float-end'> : </span>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="float-end">
                                                                                                    <span><b>&#8377; {value2.govt_fees_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </b></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <hr />
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div className="row mb-3">
                                                                            <div className="col-6">
                                                                                <span style={{ fontSize: '16px' }}><b>Total charges</b></span>
                                                                                <span style={{ fontSize: '16px' }} className='float-end'>:</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="float-end">
                                                                                    <span style={{ fontSize: '18px' }}><b>&#8377; {value.total_charges.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </b></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-6">
                                                                                <span style={{ fontSize: '16px' }}><b>Payable now </b></span>
                                                                                <span style={{ fontSize: '16px' }} className='float-end'>:</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="float-end">
                                                                                    <span style={{ fontSize: '18px' }}>&#8377; {Nowpayable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-6">
                                                                                <span style={{ fontSize: '16px' }}><b>Promo code</b></span>
                                                                                <span style={{ fontSize: '16px' }} className='float-end'>:</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="float-end">
                                                                                    <input type="text" className="form-control" style={{ textAlign: 'right' }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">
                                                                            <div className="col-6">
                                                                                <span style={{ fontSize: '16px' }}><b>Make payment</b></span>
                                                                                <span style={{ fontSize: '16px' }} className='float-end'>:</span>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="float-end">
                                                                                    <span onClick={() => makepaymentsubmit()} className="btn btn-warning btn-sm" value="payment">Make Payment</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {PaymentError.payment_total !== '' ?
                                                                            <div className="row mb-3">
                                                                                <div className="col-12 text-end text-right">
                                                                                    <span className='error_msg'>{PaymentError.payment_total}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                        }

                                                                    </>
                                                                    :
                                                                    null
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )
                                })}

                            <section>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 text-center" style={{ padding: '30px 50px 100px 50px' }}>
                                            <div className="row">
                                                <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                    <button className="w-100 btn btn-secondary btn-small" onClick={() => handeleditform()}>View / Edit form</button>
                                                </div>
                                                <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                    <button className="w-100 btn btn-secondary btn-small" onClick={() => handelviewdocumentsupload()}>View
                                                        documents</button>

                                                </div>
                                                <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                    <button className="w-100 btn btn-secondary btn-small" onClick={() => handeldownloadapplication()}>Download
                                                        Application</button>

                                                </div>
                                                <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                    <button className="w-100 btn btn-secondary btn-small" onClick={() => handelemailapplication()}>Email Application</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        email_error_msgs.show_success_msg === true ?
                                            <>
                                                <div className="container success_msg_bx">
                                                    <h4>{email_error_msgs.success_msg}</h4>
                                                </div>
                                            </> :
                                            null
                                    }
                                    {
                                        email_error_msgs.show_error_msg === true ?
                                            <>
                                                <div className="container error_msg_bx">
                                                    <h4>{email_error_msgs.error_msg}</h4>
                                                </div>
                                            </> :
                                            null
                                    }

                                    <br />
                                </div>
                            </section>

                            {showcomments === true ?
                                <>
                                    <section>
                                        <div className="container showcomments" style={{ paddingTop: "20px", paddingBottom: "50px" }}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card"
                                                        style={{ width: "auto", height: "auto", padding: "10px", boxShadow: "0px 0px 7px 1px #00000047", cursor: "pointer" }}>
                                                        <div className="card-body">
                                                            {commentdetails.length > 0 ?
                                                                <React.Fragment>
                                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                                            <div className="col-9">
                                                                                Comments
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <div className="float-right">
                                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {commentdetails.map((value, index) => {
                                                                        return (
                                                                            <div style={{ padding: "20px", paddingBottom: "0px" }} key={index} >
                                                                                <div className='main_comments'>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-1">
                                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                                        </div>
                                                                                        <div className="col-sm-11">
                                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                                <b>{value.user_name}</b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mt-2">
                                                                                        <div className="col-sm-1"></div>
                                                                                        <div className="col-sm-9">
                                                                                            <p className='p-0 mt-2 mb-1'>{value.comment_msg}</p>
                                                                                            {
                                                                                                value.comments_file !== '' ?
                                                                                                    <a class="text-decoration-none" href={value.comments_file} target="_blank">
                                                                                                        <FontAwesomeIcon icon={faFilePdf} /> Click here to view / download the attachment
                                                                                                    </a>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                            }
                                                                                            <p className='p-0 mt-2 mb-3'>{value.updated_on}</p>
                                                                                        </div>
                                                                                        <div className="col-sm-2" style={{ paddingTop: "2px" }}>
                                                                                            <div>
                                                                                                <button className="btn btn-info btn-sm mr-3" onClick={() => handreplycomments(value.comment_id)}>Reply</button>
                                                                                                {parseInt(userid) === parseInt(value.user_id) ?
                                                                                                    <>
                                                                                                        &nbsp; &nbsp;<button className="btn btn-primary btn-sm ml-2" onClick={() => handeleditcomments(value, "comment")}>Edit</button>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {value.reply_comments.length > 0 ?
                                                                                    <>

                                                                                        {value.reply_comments.map((value2, index2) => {
                                                                                            return (
                                                                                                <div style={{ padding: "20px", paddingBottom: "0px", paddingTop: "15px" }} key={index2}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-1">
                                                                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                                                                        </div>
                                                                                                        <div className="col-sm-11">
                                                                                                            <div style={{ borderBottom: " 1px #ccc solid" }}>
                                                                                                                Replied by - <b>{value2.user_name}</b>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row mt-2">
                                                                                                        <div className="col-sm-1"></div>
                                                                                                        <div className="col-sm-9">
                                                                                                            <p className='p-0 mt-2 mb-1'>{value2.comment_msg}</p>
                                                                                                            {
                                                                                                                value2.comments_file !== '' ?
                                                                                                                    <a class="text-decoration-none" href={value2.comments_file} target="_blank">
                                                                                                                        <FontAwesomeIcon icon={faFilePdf} /> Click here to view / download the attachment
                                                                                                                    </a>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }
                                                                                                            <p className='p-0 mt-2 mb-3'>{value.updated_on}</p>
                                                                                                        </div>
                                                                                                        <div className="col-sm-2" style={{ paddingTop: "2px" }}>
                                                                                                            {parseInt(userid) === parseInt(value2.user_id) ?
                                                                                                                <div>
                                                                                                                    &nbsp; &nbsp;<button className="btn btn-primary btn-sm ml-2" onClick={() => handeleditcomments(value2, "edit")}>Edit</button>
                                                                                                                    {/* <button className="btn btn-secondary btn-sm">Report</button> */}
                                                                                                                </div>
                                                                                                                :
                                                                                                                <>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </> :
                                                                                    <>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </React.Fragment> :
                                                                <>
                                                                    <div style={{ borderBottom: "1px #ccc solid" }}>
                                                                        <div className="row" style={{ paddingBottom: "5px" }}>
                                                                            <div className="col-sm-9">
                                                                                Be the first to write a comments
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <div className="float-right">
                                                                                    <button onClick={() => commentsmodalopen()} className="btn btn-secondary btn-sm">Enter new comment</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </> :
                                <>
                                </>
                            }
                        </>
                        :
                        loadedapi === true && transactiondetails.length <= 0 ?
                            <>
                                <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                    <div id="grad1">
                                        <div className="row">
                                            <div className="col-1"></div>
                                            <div className="col-10 text-center">
                                                <div className='row'>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                    <div className='col-sm-6 mb-3'>
                                                        <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                <div id="grad1">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-10 text-center">
                                            <h6 style={{ padding: "70px 0px" }}>No data found in card</h6>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                </div>
                            </div>
                }

                <Modal open={commentsmodal} onClose={commentsmodalclose} center>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Comments</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3">
                                <div className="row mb-3">
                                    <div className='col-sm-12 text-left'>
                                        <label className="text-left col-form-label fw-bold" htmlFor="comments_file" >Image / Document </label>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="custom-file">
                                            <input type="file" className="form-control" id="comments_file" name="comments_file" onChange={(e) => handeldocumentformonchange(e)} disabled={form_datas.selected_file_dd} />
                                        </div>
                                        {form_errormsg.doc_selected_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.doc_selected_errormsg}</span> : <></>}
                                    </div>
                                    {

                                        form_datas.selected_file_dd === true ?
                                            <div className="col-sm-12 mt-3 mb-2">
                                                <div className='row'>
                                                    <div className='selectedfile col-10'>
                                                        <a href={form_datas.doc_url} target="_blank">
                                                            <p className='cut-text'>
                                                                {form_datas.doc_selected_name}
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className='col-2'>
                                                        <button className="btn submitfilebtn btn-danger" onClick={(e) => handelremovedoce(e)}>
                                                            <FontAwesomeIcon icon={faTrash} /></button>
                                                    </div>

                                                </div>
                                            </div>
                                            : <></>
                                    }
                                </div>
                                <div className="row">
                                    <label htmlFor='comment_msg' className="text-left col-sm-12 col-form-label fw-bold">
                                        Message
                                    </label>
                                    <div className="col-sm-12">
                                        <textarea onChange={(e) => handelcommentsonchange(e)} className="form-control" id="comment_msg" name="comment_msg" value={form_datas.comment_msg} rows="5"></textarea>
                                        {form_errormsg.comment_msg_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.comment_msg_errormsg}</span> : <></>}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-8">
                                    </div>
                                    <div className="col-4">
                                        <button onClick={() => handlecommentsubmit()} type="button" className="btn-sm btn btn-secondary w-100">Submit</button>
                                    </div>
                                </div>
                            </div>

                            {
                                error_msgs.show_success_msg === true ?
                                    <>
                                        <div className="container success_msg_bx">
                                            <h4>{error_msgs.success_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }
                            {
                                error_msgs.show_error_msg === true ?
                                    <>
                                        <div className="container error_msg_bx">
                                            <h4>{error_msgs.error_msg}</h4>
                                        </div>
                                    </> :
                                    null
                            }

                        </section>
                    </div>
                </Modal>


                <Modal open={documentmodal} onClose={documentmodalclose} center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Uploaded Documents</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3 mt-3">
                                {documentmodal_datas.length > 0 ?
                                    <>
                                        {documentmodal_datas.map((value_d, index2) => {
                                            return (
                                                <div className='row' key={index2}>
                                                    <div className='col-6'>
                                                        <p> {value_d.display_name} <span className='float-end'>:</span></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <a href={value_d.doc_url} target="_blank">{value_d.doc_name}</a>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    :
                                    <><p className='text-center p-5'>No data found</p>
                                    </>
                                }
                            </div>
                        </section>
                    </div>
                </Modal>

                <Modal open={downloadappmodal} onClose={downloadappclose} center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Download application form</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3 mt-3">
                                {services_name_array.length > 0 ?
                                    <>
                                        {services_name_array.map((value_d, index2) => {
                                            return (
                                                <div className='row' key={index2}>
                                                    <div className='col-4'>
                                                        <p> Application form {index2 + 1} <span className='float-end'>:</span></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='cursor-pointer' onClick={() => handeldownloadapplicationbyid(index2)}>{value_d} {CustomerMobile} - 00{referenceIdArray[index2]}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    :
                                    <><p className='text-center p-5'>No data found</p>
                                    </>
                                }
                            </div>
                        </section>
                    </div>
                </Modal>


                <Modal open={emailappmodal} onClose={emailappmodalclose} center
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Email application form</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>

                        <section>
                            <div className="container mb-3 mt-3">
                                {services_name_array.length > 0 ?
                                    <>
                                        {services_name_array.map((value_d, index2) => {
                                            return (
                                                <div className='row' key={index2}>
                                                    <div className='col-4'>
                                                        <p> Application form {index2 + 1} <span className='float-end'>:</span></p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='cursor-pointer' onClick={() => handelemailapplicationbyid(index2)}>{value_d} {CustomerMobile} - 00{referenceIdArray[index2]}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </>
                                    :
                                    <><p className='text-center p-5'>No data found</p>
                                    </>
                                }
                            </div>
                        </section>
                    </div>
                </Modal>

                <Modal open={tearmsandconditionmodal} onClose={tearmsandconditionmodalclose} center
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                    classNames={{
                        overlay: 'customOverlay',
                        modal: 'myuploadedmodals',
                    }}>
                    <div className='modal_cnt'>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div className="con-bottom-inner pb-2">
                                            <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Terms and conditions</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"></div>
                            </div>
                        </section>
                        <section>
                            <div className="container terms_cnd_heit mb-3 mt-3 text-justify">
                                <p>
                                    <b>GServes</b> provides assistance in availing various Government services, by coordinating
                                    with the Government offices and performing the necessary tasks on customers’ behalf.
                                </p>
                                <p>
                                    By engaging GServes and making payment for the services, you express acceptance to the below Terms and conditions:
                                </p>
                                <p>
                                    <b>1. Scope of deliverable</b> - The final output in your transaction shall be provided by the concerned Government department
                                    and GServes acts as your representative in applying for the same. The deliverable from GServes varies from service
                                    to service, please refer our website or check with us for the exact nature of deliverable applicable in your transaction.
                                </p>
                                <p>
                                    <b>2. Timeline</b> – The timeline indicated for service delivery and output is based on our experience and Government rules,
                                    but there could be delays or rejections beyond our control. In the capacity of being a representative in your transaction,
                                    GServes may not be able to offer any commitment on Service delivery timeline. However, it is our endeavour to
                                    perform all the necessary tasks within the gambit of law to ensure timely output. In many cases, Government rules
                                    guarantee time bound delivery of services and even compensation for rejection/delays. If in case your transaction is
                                    rejected/delayed, GServes shall take up necessary steps such as reapplying, escalation, RTI application etc., and shall
                                    assist you in availing any other benefits/compensation offered by Government. Beyond this, GServes shall not take
                                    responsibility for delays as the timeline and onus for actual delivery of service rests with the Government department.
                                </p>
                                <p>
                                    <b>3. Data Accuracy & Correctness</b> - Although GServes conducts the necessary checks before submitting your application,
                                    the onus of data accuracy in the application and the veracity of the supporting documents, signature etc. lies with you.
                                    If the service is denied on account of incorrect information, or if there is any further task necessitated due to such
                                    gaps, GServes shall not be held responsible and your co-operation is needed for pursuing the application further.
                                </p>
                                <p>
                                    <b>4. Correctness of Output</b> – If the output provided by the Government department has any error or discrepancy, GServes
                                    shall not be liable for the same unless such error was caused due to any deficiency on the part of GServes. Any cost
                                    involved in getting it rectified shall be borne by the customer and GServes may charge for such additional effort.
                                </p>
                                <p>
                                    <b>5. Originals</b> - GServes shall handle all your documents and materials with utmost care and shall submit / return the same
                                    as may be required to perform the transaction. In spite of our best efforts, if there is any loss or damage to the items
                                    entrusted to us due to GServes’ failure, our liability is limited to the cost of obtaining the duplicate document,
                                    wherever it is practically feasible. Here again, your co-operation shall be required, as appropriate. Apart from
                                    documents, you are strongly advised not to hand over your vehicle or any other physical material to GServes as we
                                    will not be in a position to assume responsibility. Any such items given us to shall always remain at your own risk.
                                </p>
                                <p>
                                    <b>6. Loss or damage by Third parties</b> – If there is any loss or damage to your documents / materials by third parties post
                                    GServes submitting the same to such third party in the course of performing the contracted transaction, GServes shall
                                    not be responsible for such loss/damage. However, GServes shall take necessary precautions to avoid such mishaps.
                                </p>
                                <p>
                                    <b>7. Status updates</b> – GServes will perform its tasks with fullest sincerity and shall strive to provide constant updates as
                                    and when your file moves to the next level. However, given the nature of Government transactions, we may not get
                                    complete picture on a daily / periodical basis, and hence we will not be able to provide such updates frequently. Most
                                    Government departments offer online status tracking and the necessary links for the same is provided on our website.
                                </p>
                                <p>
                                    <b>8. Process adherence</b> – While GServes shall guide you on the process to be followed in completing your transaction and
                                    the documents required thereon based on extant rules and our experience on ground, it may so happen that additional
                                    documents might be required to complete the transaction or an extra step that has to be performed. Your consent and
                                    co-operation to comply with any such additional requirements shall be warranted, as and when the need arises.
                                </p>
                                <p>
                                    <b>9. Data Privacy</b> - In performance of your transactions, we are required to collect your data in physical and/or digital
                                    form. GServes promises data confidentiality and usage of your data only for the contracted purposes and we will
                                    never sell the data to third-parties or repurpose. If there is any data breach of pilferage in spite of our precautions,
                                    GServes shall not be held liable for damages caused on account of such mishaps.
                                </p>
                                <p>
                                    <b>10. Monetary liability</b> – Our liability due to errors/mistakes solely attributable to us shall be limited to the Service charges
                                    paid excluding expenses already incurred, provided we are not able to correct such errors as per the stated scope.
                                </p>

                            </div>
                        </section>
                        <section>
                            <div className="container mb-3 mt-3 text-justify">
                                <div className='disp_inb'>
                                    <div className="radio_s">
                                        <label>
                                            <input type="checkbox"
                                                value=""
                                                name="terms_condition"
                                                onChange={(e) => handeltermsandcondition(e)}
                                                checked={terms_condition}
                                            />
                                            &nbsp; I agree to the terms & conditions
                                        </label>
                                    </div>
                                </div>
                                <div className="float-end mb-3">
                                    <button disabled={!terms_condition} onClick={() => makepaymentwithtermsandcondition()} className="btn btn-primary btn-md">continue</button>
                                </div>
                            </div>
                        </section>
                    </div>

                </Modal>
                <FooterComp />
            </main>
        </>
    );
}

export default Payment;

