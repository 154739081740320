import React from 'react';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';


function Legalinfo(props) {

    return (
        <>
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container" style={{ paddingTop: "150px", paddingBottom: "150px" }}>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 style={{ color: '#6c757d' }}><strong>Legal Info </strong></h3>
                                <p style={{ textAlign: "left"}}>By engaging GServes and making payment for the services, you express acceptance to the below Terms and conditions:</p>

                                <p style={{ textAlign: "left"}}>1.	Scope of deliverable - The final output in your transaction shall be provided by the concerned Government department and GServes acts as your representative in applying for the same. The deliverable from GServes varies from service to service, please refer our website or check with us for the exact nature of deliverable applicable in your transaction.</p>

                                <p style={{ textAlign: "left"}}>2.	Timeline – The timeline indicated for service delivery and output is based on our experience and Government rules, but there could be delays or rejections beyond our control. In the capacity of being a representative in your transaction, GServes may not be able to offer any commitment on Service delivery timeline. However, it is our endeavour to perform all the necessary tasks within the gambit of law to ensure timely output. In many cases, Government rules guarantee time bound delivery of services and even compensation for rejection/delays. If in case your transaction is rejected/delayed, GServes shall take up necessary steps such as reapplying, escalation, RTI application etc., and shall assist you in availing any other benefits/compensation offered by Government. Beyond this, GServes shall not take responsibility for delays as the timeline and onus for actual delivery of service rests with the Government department.
                                </p>
                                <p style={{ textAlign: "left"}}>
                                3.	Data Accuracy & Correctness - Although GServes conducts the necessary checks before submitting your application, the onus of data accuracy in the application and the veracity of the supporting documents, signature etc. lies with you. If the service is denied on account of incorrect information, or if there is any further task necessitated due to such gaps, GServes shall not be held responsible and your co-operation is needed for pursuing the application further. 
                                </p>
                                <p style={{ textAlign: "left"}}>
                                4.	Correctness of Output – If the output provided by the Government department has any error or discrepancy, GServes shall not be liable for the same unless such error was caused due to any deficiency on the part of GServes. Any cost involved in getting it rectified shall be borne by the customer and GServes may charge for such additional effort.
                                </p>
                                <p style={{ textAlign: "left"}}>
                                5.	Originals - GServes shall handle all your documents and materials with utmost care and shall submit / return the same as may be required to perform the transaction. In spite of our best efforts, if there is any loss or damage to the items entrusted to us due to GServes’ failure, our liability is limited to the cost of obtaining the duplicate document, wherever it is practically feasible. Here again, your co-operation shall be required, as appropriate. Apart from documents, you are strongly advised not to hand over your vehicle or any other physical material to GServes as we will not be in a position to assume responsibility. Any such items given us to shall always remain at your own risk.
                                </p>
                                <p style={{ textAlign: "left"}}>
                                6.	Loss or damage by Third parties – If there is any loss or damage to your documents / materials by third parties post GServes submitting the same to such third party in the course of performing the contracted transaction, GServes shall not be responsible for such loss/damage. However, GServes shall take necessary precautions to avoid such mishaps.
                                </p>
                                <p style={{ textAlign: "left"}}>
                                7.	Status updates – GServes will perform its tasks with fullest sincerity and shall strive to provide constant updates as and when your file moves to the next level. However, given the nature of Government transactions, we may not get complete picture on a daily / periodical basis, and hence we will not be able to provide such updates frequently. Most Government departments offer online status tracking and the necessary links for the same is provided on our website. 
                                </p>
                                <p style={{ textAlign: "left"}}>
                                8.	Process adherence – While GServes shall guide you on the process to be followed in completing your transaction and the documents required thereon based on extant rules and our experience on ground, it may so happen that additional documents might be required to complete the transaction or an extra step that has to be performed. Your consent and co-operation to comply with any such additional requirements shall be warranted, as and when the need arises.
                                </p>
                                <p style={{ textAlign: "left"}}>
                                9.	Data Privacy - In performance of your transactions, we are required to collect your data in physical and/or digital form. GServes promises data confidentiality and usage of your data only for the contracted purposes and we will never sell the data to third-parties or repurpose. If there is any data breach of pilferage in spite of our precautions, GServes shall not be held liable for damages caused on account of such mishaps. 
                                </p>
                                <p style={{ textAlign: "left"}}>
                                10.	Monetary liability – Our liability due to errors/mistakes solely attributable to us shall be limited to the Service charges paid excluding expenses already incurred, provided we are not able to correct such errors as per the stated scope.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <FooterComp />
        </>
    );
}

export default Legalinfo;