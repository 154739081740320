import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Base64 } from 'js-base64';
import { axiosInstance } from '../Services';
import { Steps, useSteps } from "react-step-builder";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faInfoCircle, faLongArrowAltLeft, faLongArrowAltRight, faMultiply, faQuestionCircle, faSave, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import BounceLoader from "react-spinners/BounceLoader";
import Skeleton from 'react-loading-skeleton';
import * as device from 'react-device-detect';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";

const Multisteps = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        getapplicationformdetails
    }), [])// eslint-disable-line react-hooks/exhaustive-deps

    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    const [loadedapi, setloadedapi] = useState(false);
    const { next, prev, current } = useSteps();
    const [userNo, setuserNo] = useState('');
    const [referenceId, setreferenceId] = useState('');
    const [referenceIdArray, setreferenceIdArray] = useState([]);
    const [generalformdatas, setgeneralform] = useState([]);
    const [documentformdatas, setdocumentformdatas] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [services_id, setservices_id] = useState([]);
    const [services_name_array, setservices_name_array] = useState([]);
    const [services_name, setservices_name] = useState('');
    const [addonvalues, setaddonvalues] = useState([]);
    const [loggingstatus, setloggingstatus] = useState(false);
    const [loggedusername, setloggedusername] = useState('');
    const [userid, setuserid] = useState(0);
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            setloggingstatus(localStorage.getItem('logging_status'));
            setloggedusername(Base64.decode(localStorage.getItem('gsun')));
            setuserid(Base64.decode(localStorage.getItem('gsud')));
        } else {
            setloggingstatus(false);
        }
        getapplicationformdetails();
        setshowloader(false);
        localStorage.removeItem("tdid");
        localStorage.removeItem("requestid");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    function getapplicationformdetails() {
        setloadedapi(true);
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        if (props.application_type === 'EDIT' && props.refid !== '') {

            let myarrayss = props.refid.split(',');
            let mydatas = {
                "userid": userid_o,
                "refid": myarrayss,
            }
            axiosInstance.post('/Applicationform/getbyid', mydatas)
                .then((res) => {
                    setuserNo('' + res.data.data.UserNo + '');
                    setreferenceId('' + res.data.data.RefId + '');
                    setservices_id(res.data.data.services_id);
                    setreferenceIdArray(res.data.data.RefId_array);
                    setservices_name_array(res.data.data.services_name_array);
                    setservices_name(res.data.data.services_name);
                    setaddonvalues(res.data.data.addonvalues);
                    setgeneralform(res.data.data.general_form);
                    // setdocumentformdatas(res.data.data.document_form);
                    let document_form_d = res.data.data.document_form;
                    if (selectedFile.length > 0) {
                        for (let i = 0; i < selectedFile.length; i++) {
                            for (let j = 0; j < res.data.data.document_form.length; j++) {
                                if (res.data.data.document_form[j]["fieldsmaster_id"] === selectedFile[i]["field_type"] && res.data.data.document_form[j]["doc_selected"] === false && selectedFile[i]["doc_url"] !== '') {
                                    res.data.data.document_form[j]["doc_selected"] = true;
                                    res.data.data.document_form[j]["doc_url"] = selectedFile[i]["doc_url"];
                                    res.data.data.document_form[j]["doc_name"] = selectedFile[i]["doc_name"];
                                }
                            }
                        }
                    }
                    else {
                        let selectedFile_d = [];
                        if (document_form_d.length > 0) {
                            for (let i = 0; i < document_form_d.length; i++) {
                                let dummy_data = {
                                    'field_type': document_form_d[i]['fieldsmaster_id'],
                                    'field_name': document_form_d[i]['field_name'],
                                    'selected_file': '',
                                    'doc_url': '',
                                    'doc_name': '',
                                }
                                selectedFile_d.push(dummy_data);
                            }
                        }
                        setSelectedFile(selectedFile_d);
                    }
                    setdocumentformdatas(res.data.data.document_form);
                    setloadedapi(false);
                })
                .catch((err) => {
                    setloadedapi(false);
                    console.log(err);
                });
        }
        else {
            // let myarrayss = props.refid.split(',');
            let mydatas = {
                "userid": userid_o,
                "refid": props.refid,
            }
            axiosInstance.post('/Applicationform/get', mydatas)
                .then((res) => {
                    setuserNo('' + res.data.data.UserNo + '');
                    setreferenceId('' + res.data.data.RefId + '');
                    setservices_id(res.data.data.services_id);
                    setreferenceIdArray(res.data.data.RefId_array);
                    setservices_name_array(res.data.data.services_name_array);
                    setservices_name(res.data.data.services_name);
                    setaddonvalues(res.data.data.addonvalues);
                    setgeneralform(res.data.data.general_form);
                    // setdocumentformdatas(res.data.data.document_form);
                    let document_form_d = res.data.data.document_form;
                    if (selectedFile.length > 0) {
                        for (let i = 0; i < selectedFile.length; i++) {
                            for (let j = 0; j < res.data.data.document_form.length; j++) {
                                if (res.data.data.document_form[j]["fieldsmaster_id"] === selectedFile[i]["field_type"] && res.data.data.document_form[j]["doc_selected"] === false && selectedFile[i]["doc_url"] !== '') {
                                    res.data.data.document_form[j]["doc_selected"] = true;
                                    res.data.data.document_form[j]["doc_url"] = selectedFile[i]["doc_url"];
                                    res.data.data.document_form[j]["doc_name"] = selectedFile[i]["doc_name"];
                                }
                            }
                        }
                    }
                    else {
                        let selectedFile_d = [];
                        if (document_form_d.length > 0) {
                            for (let i = 0; i < document_form_d.length; i++) {
                                let dummy_data = {
                                    'field_type': document_form_d[i]['fieldsmaster_id'],
                                    'field_name': document_form_d[i]['field_name'],
                                    'selected_file': '',
                                    'doc_url': '',
                                    'doc_name': '',
                                }
                                selectedFile_d.push(dummy_data);
                            }
                        }
                        setSelectedFile(selectedFile_d);
                    }
                    setdocumentformdatas(res.data.data.document_form);
                    setloadedapi(false);
                })
                .catch((err) => {
                    setloadedapi(false);
                    console.log(err);
                });
        }
    }

    // stringValPatternValidation = stringVal => {
    //     return /\s/g.test(stringVal);
    //   };

    function handelgeneralformonchange(e, value, datatype, index, text_format, index2) {
        const re = /^[0-9\b]+$/;
        const re_text = /^[A-Za-z ]+$/;
        var regex = new RegExp("^[0-9a-zA-Z\b ]+$");
        let dummyarray = generalformdatas[index];

        if (text_format === 'any') {
            dummyarray.form_value = e.target.value;
            setgeneralform([...generalformdatas]);
            checkdepedentvalidation(index);
        }
        if (text_format === 'email') {
            dummyarray.form_value = e.target.value;
            setgeneralform([...generalformdatas]);
            checkdepedentvalidation(index);
        }
        if (text_format === 'only_number') {
            if (e.target.value === '' || re.test(e.target.value)) {
                dummyarray.form_value = e.target.value;
                setgeneralform([...generalformdatas]);
                checkdepedentvalidation(index, dummyarray.form_value);
            }
        }
        if (text_format === 'only_text') {
            if (e.target.value === '' || re_text.test(e.target.value)) {
                dummyarray.form_value = e.target.value;
                setgeneralform([...generalformdatas]);
                checkdepedentvalidation(index, dummyarray.form_value);
            }
        }
        if (text_format === 'only_text_and_number') {
            if (e.target.value === '' || regex.exec(e.target.value) !== null) {
                dummyarray.form_value = e.target.value;
                setgeneralform([...generalformdatas]);
                checkdepedentvalidation(index, dummyarray.form_value);
            }
        }
        if (datatype == 'checkbox') {
            dummyarray.field_options[index2].option_selected = e.target.checked;
            setgeneralform([...generalformdatas]);
            checkdepedentvalidation(index);
            let count = 0;
            for (let c = 0; c < dummyarray.field_options.length; c++) {
                let element = dummyarray.field_options[c].option_selected;
                if (element === true) {
                    count += 1;
                }
            }
            if (count > 0) {
                dummyarray.form_value = e.target.value;
                setgeneralform([...generalformdatas]);
                checkdepedentvalidation(index);
            }
            else {
                dummyarray.form_value = '';
                setgeneralform([...generalformdatas]);
                checkdepedentvalidation(index);
            }
        }
        if (datatype == 'radio') {
            dummyarray.field_options[index2].option_selected = e.target.checked;
            setgeneralform([...generalformdatas]);
            checkdepedentvalidation(index);
        }
        if (datatype == 'select') {
            dummyarray.form_value = e.target.value;
            dummyarray.field_options[index2].option_selected = true
            setgeneralform([...generalformdatas]);
            checkdepedentvalidation(index);
        }
    }

    function checkdepedentvalidation(index, value) {
        let dummyarray = generalformdatas[index];
        for (let i = 0; i < generalformdatas.length; i++) {
            if (dummyarray.servesform_id === generalformdatas[i]["dependent_field"] && dummyarray.form_from_type == "Services") {
                if (generalformdatas[i]["operator"] !== '' && generalformdatas[i]["operator_value"] !== '') {
                    if (generalformdatas[i]["operator"] === 'equal_to') {
                        if (parseInt(dummyarray.form_value) === parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'not_equal_to') {
                        if (parseInt(dummyarray.form_value) !== parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'grater_than') {
                        if (parseInt(dummyarray.form_value) > parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'less_than') {
                        if (parseInt(dummyarray.form_value) < parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'grater_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) >= parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'less_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) <= parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'between') {
                        if (parseInt(dummyarray.form_value) >= parseInt(generalformdatas[i]["operator_value"]) &&
                            parseInt(dummyarray.form_value) <= parseInt(generalformdatas[i]["operator_value_to"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'not_between') {
                        if (!(parseInt(generalformdatas[i]["operator_value"]) > parseInt(dummyarray.form_value) &&
                            parseInt(generalformdatas[i]["operator_value_to"]) < parseInt(dummyarray.form_value))) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'whole_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        } else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";

                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'is_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        } else {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                    }
                }
            }

            if (dummyarray.addonform_id === generalformdatas[i]["dependent_field"] && dummyarray.form_from_type == "Addon") {
                if (generalformdatas[i]["operator"] !== '' && generalformdatas[i]["operator_value"] !== '') {
                    if (generalformdatas[i]["operator"] === 'equal_to') {
                        if (parseInt(dummyarray.form_value) === parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'not_equal_to') {
                        if (parseInt(dummyarray.form_value) !== parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'grater_than') {
                        if (parseInt(dummyarray.form_value) > parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'less_than') {
                        if (parseInt(dummyarray.form_value) < parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'grater_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) >= parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'less_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) <= parseInt(generalformdatas[i]["operator_value"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'between') {
                        if (parseInt(dummyarray.form_value) >= parseInt(generalformdatas[i]["operator_value"]) &&
                            parseInt(dummyarray.form_value) <= parseInt(generalformdatas[i]["operator_value_to"])) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'not_between') {
                        if (!(parseInt(generalformdatas[i]["operator_value"]) > parseInt(dummyarray.form_value) &&
                            parseInt(generalformdatas[i]["operator_value_to"]) < parseInt(dummyarray.form_value))) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                        else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'whole_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        } else {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";

                        }
                    }
                    else if (generalformdatas[i]["operator"] === 'is_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            generalformdatas[i]["dependent_field_disable"] = true;
                            generalformdatas[i]["form_value"] = "";
                        } else {
                            generalformdatas[i]["dependent_field_disable"] = false;
                        }
                    }
                }
            }

            setgeneralform([...generalformdatas]);
        }
    }

    function generalformvalidation() {
        let flag = 0;
        var mailformat = /(^\w.*@\w+\.\w)/;
        for (let i = 0; i < generalformdatas.length; i++) {
            let dummyarray = generalformdatas[i];
            if (dummyarray.mandatory === 'yes' && dummyarray.form_value === '') {
                dummyarray.error_msg_h = '* This field is required';
                dummyarray.show_error_h = true;
                flag = 1;
            }
            else if (dummyarray.form_value !== '' && dummyarray.field_text_format === 'email'
                && mailformat.test(dummyarray.form_value) === false) {
                dummyarray.error_msg_h = '* Please enter the valid email';
                dummyarray.show_error_h = true;
                flag = 1;
            }
            else if (dummyarray.field_type === 'text' && dummyarray.field_min_length !== 0 && dummyarray.field_max_length !== 0 && dummyarray.form_value !== '' || dummyarray.field_type === 'textarea' && dummyarray.field_min_length !== 0 && dummyarray.field_max_length !== 0 && dummyarray.form_value !== '') {
                if (dummyarray.form_value.length < dummyarray.field_min_length) {
                    dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should have minimum ' + dummyarray.field_min_length + ' characters';
                    dummyarray.show_error_h = true;
                    flag = 1;
                }
                else if (dummyarray.form_value.length > dummyarray.field_max_length) {
                    dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should have maximum ' + dummyarray.field_max_length + ' characters';
                    dummyarray.show_error_h = true;
                    flag = 1;
                }
                else if (dummyarray.field_text_format === 'only_number' && dummyarray.form_value != '') {
                    if (dummyarray.field_number_interval === 'equal_to') {
                        if (parseInt(dummyarray.form_value) === parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be lesser than or grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_equal_to') {
                        if (parseInt(dummyarray.form_value) !== parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than') {
                        if (parseInt(dummyarray.form_value) > parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than') {
                        if (parseInt(dummyarray.form_value) < parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be grater than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'between') {
                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from) &&
                            parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_to)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_between') {
                        if (!(parseInt(dummyarray.field_number_interval_from) > parseInt(dummyarray.form_value) &&
                            parseInt(dummyarray.field_number_interval_to) < parseInt(dummyarray.form_value))) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'whole_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'is_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                        else {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                    }
                }
                else {
                    dummyarray.error_msg_h = '';
                    dummyarray.show_error_h = false;
                }
            }
            else if (dummyarray.field_type === 'text' && dummyarray.field_min_length !== 0 && dummyarray.form_value !== '' ||
                dummyarray.field_type === 'textarea' && dummyarray.field_min_length !== 0 && dummyarray.form_value !== '') {

                if (dummyarray.form_value.length < dummyarray.field_min_length) {
                    dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should have minimum ' + dummyarray.field_min_length + ' characters';
                    dummyarray.show_error_h = true;
                    flag = 1;
                }
                else if (dummyarray.field_text_format === 'only_number' && dummyarray.form_value != '') {
                    if (dummyarray.field_number_interval === 'equal_to') {
                        if (parseInt(dummyarray.form_value) === parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be lesser than or grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_equal_to') {
                        if (parseInt(dummyarray.form_value) !== parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than') {
                        if (parseInt(dummyarray.form_value) > parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than') {
                        if (parseInt(dummyarray.form_value) < parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be grater than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'between') {

                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from) &&
                            parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_to)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_between') {
                        if (!(parseInt(dummyarray.field_number_interval_from) > parseInt(dummyarray.form_value) &&
                            parseInt(dummyarray.field_number_interval_to) < parseInt(dummyarray.form_value))) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'whole_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'is_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                        else {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                    }
                    else {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                }
                else {
                    dummyarray.error_msg_h = '';
                    dummyarray.show_error_h = false;
                }
            }
            else if (dummyarray.field_type === 'text' && dummyarray.field_max_length !== 0 && dummyarray.form_value !== '' ||
                dummyarray.field_type === 'textarea' && dummyarray.field_max_length !== 0 && dummyarray.form_value !== '') {

                if (dummyarray.form_value.length > dummyarray.field_max_length) {
                    dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should have maximum ' + dummyarray.field_max_length + ' characters';
                    dummyarray.show_error_h = true;
                    flag = 1;
                }
                else if (dummyarray.field_text_format === 'only_number' && dummyarray.form_value != '') {
                    if (dummyarray.field_number_interval === 'equal_to') {
                        if (parseInt(dummyarray.form_value) === parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be lesser than or grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_equal_to') {
                        if (parseInt(dummyarray.form_value) !== parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than') {
                        if (parseInt(dummyarray.form_value) > parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser grater than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than') {
                        if (parseInt(dummyarray.form_value) < parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'grater_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be grater than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'less_than_or_equal_to') {
                        if (parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_from)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than or equal to ' + dummyarray.field_number_interval_from;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'between') {
                        if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from) &&
                            parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_to)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'not_between') {
                        if (!(parseInt(dummyarray.field_number_interval_from) > parseInt(dummyarray.form_value) &&
                            parseInt(dummyarray.field_number_interval_to) < parseInt(dummyarray.form_value))) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'whole_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                        else {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                    }
                    else if (dummyarray.field_number_interval === 'is_number') {
                        if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                            dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be an whole number';
                            dummyarray.show_error_h = true;
                            flag = 1;
                        }
                        else {
                            dummyarray.error_msg_h = '';
                            dummyarray.show_error_h = false;
                        }
                    }
                }
                else {
                    dummyarray.error_msg_h = '';
                    dummyarray.show_error_h = false;
                }
            }
            else if (dummyarray.field_text_format === 'only_number' && dummyarray.form_value != '') {
                if (dummyarray.field_number_interval === 'equal_to') {
                    if (parseInt(dummyarray.form_value) === parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be lesser than or grater than ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'not_equal_to') {
                    if (parseInt(dummyarray.form_value) !== parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be equal to ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'grater_than') {
                    if (parseInt(dummyarray.form_value) > parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser grater than ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'less_than') {
                    if (parseInt(dummyarray.form_value) < parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'grater_than_or_equal_to') {
                    if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be grater than or equal to ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'less_than_or_equal_to') {
                    if (parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_from)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be lesser than or equal to ' + dummyarray.field_number_interval_from;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'between') {
                    if (parseInt(dummyarray.form_value) >= parseInt(dummyarray.field_number_interval_from) &&
                        parseInt(dummyarray.form_value) <= parseInt(dummyarray.field_number_interval_to)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' should be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'not_between') {
                    if (!(parseInt(dummyarray.field_number_interval_from) > parseInt(dummyarray.form_value) &&
                        parseInt(dummyarray.field_number_interval_to) < parseInt(dummyarray.form_value))) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be be between ' + dummyarray.field_number_interval_from + ' and ' + dummyarray.field_number_interval_to;
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'whole_number') {
                    if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                    else {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' must be an whole number';
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                }
                else if (dummyarray.field_number_interval === 'is_number') {
                    if (dummyarray.form_value == Math.floor(dummyarray.form_value)) {
                        dummyarray.error_msg_h = '* ' + dummyarray.display_name + ' cannot be an whole number';
                        dummyarray.show_error_h = true;
                        flag = 1;
                    }
                    else {
                        dummyarray.error_msg_h = '';
                        dummyarray.show_error_h = false;
                    }
                }
            }
            else {
                dummyarray.error_msg_h = '';
                dummyarray.show_error_h = false;
            }
            setgeneralform([...generalformdatas]);
        }
        if (flag === 0) {
            return true;
        } else {
            return false;
        }
    }

    function handelcancelstep(mode) {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to discard the changes',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (mode === 'EDIT') {
                            navigate("/");
                        } else {
                            setshowloader(true);
                            let userid_o = 0;
                            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                                userid_o = Base64.decode(localStorage.getItem('gsud'));
                            }
                            const JSONvalue = {
                                "userid": userid_o,
                                "refid": referenceIdArray,
                            };
                            axiosInstance.post('/Applicationform/cancel_all_formdata', JSONvalue)
                                .then((res) => {
                                    if (res.data.status === 'success') {
                                        setshowloader(false);
                                        navigate("/");
                                    }
                                })
                                .catch((err) => {
                                    setshowloader(false);
                                    console.log(err);
                                });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    function handelnextstep(mode) {
        const validated = generalformvalidation();
        if (validated) {
            setshowloader(true);
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }

            // ******************** Insert general information datas ********************
            const JSONvalue = {
                'generalformdata': generalformdatas,
                "userid": userid_o,
                "RefId": referenceId,
                "services_id": services_id,
                "RefId_array": referenceIdArray,
                "addonvalues": addonvalues,
            };
            axiosInstance.post('/Applicationform/insertapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        getapplicationformdetails();
                        setshowloader(false);
                        if (mode !== 'save') {
                            next();
                        }
                        else {
                            toast.success("Application details saved successfully", {
                                position: toast.POSITION.TOP_RIGHT,
                                type: toast.TYPE.SUCCESS,
                                className: 'rotateY animated'
                            });
                        }
                    }
                    else {
                        toast.success(res.data.msg, {
                            position: toast.POSITION.TOP_RIGHT,
                            type: toast.TYPE.SUCCESS,
                            className: 'rotateY animated'
                        })
                    }
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
            // ******************** Insert general information datas End********************
        }
    }

    function handeldocumentformonchange(event, value, datatype, index, file_type) {
        if (file_type === 'image' && event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/jpg') {
            let dummyarray = documentformdatas[index];
            dummyarray.error_msg_h = "* File type must be jpg/png";
            dummyarray.show_error_h = true;
            // setdocumentformdatas([...documentformdatas]);
            dummyarray.doc_url = "";
            dummyarray.doc_name = "";
            dummyarray.doc_selected = false;
            dummyarray.doc_uploaded = false;
            setdocumentformdatas([...documentformdatas]);

        }
        else if (file_type === 'pdf' && event.target.files[0].type !== 'application/pdf') {
            let dummyarray = documentformdatas[index];
            dummyarray.error_msg_h = "* File type must be pdf";
            dummyarray.show_error_h = true;
            dummyarray.doc_url = "";
            dummyarray.doc_name = "";
            dummyarray.doc_selected = false;
            dummyarray.doc_uploaded = false;
            setdocumentformdatas([...documentformdatas]);
        }
        else if (file_type === 'image_pdf' && event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'image/png' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/jpg') {
            let dummyarray = documentformdatas[index];
            dummyarray.error_msg_h = "* File type must be pdf or Image";
            dummyarray.show_error_h = true;
            dummyarray.doc_url = "";
            dummyarray.doc_name = "";
            dummyarray.doc_selected = false;
            dummyarray.doc_uploaded = false;
            setdocumentformdatas([...documentformdatas]);
        }
        else if (event.target.files[0].size >= 2000000) {
            let dummyarray = documentformdatas[index];
            dummyarray.error_msg_h = "* File too large. File must be less than 2 megabytes.";
            dummyarray.show_error_h = true;
            dummyarray.doc_url = "";
            dummyarray.doc_name = "";
            dummyarray.doc_selected = false;
            dummyarray.doc_uploaded = false;
            setdocumentformdatas([...documentformdatas]);
        }
        else {
            let dummyarray = documentformdatas[index];
            dummyarray.doc_url = URL.createObjectURL(event.target.files[0]);
            dummyarray.doc_name = event.target.files[0].name;
            dummyarray.doc_selected = true;
            dummyarray.error_msg_h = "";
            dummyarray.show_error_h = false;
            setdocumentformdatas([...documentformdatas]);

            let selectedFile_d = selectedFile[index];
            selectedFile_d.selected_file = event.target.files[0];
            selectedFile_d.doc_url = URL.createObjectURL(event.target.files[0]);
            selectedFile_d.doc_name = event.target.files[0].name;
            setSelectedFile([...selectedFile]);
        }
    }

    function handelremovedoce(event, value, datatype, index) {
        let dummyarray = documentformdatas[index];
        dummyarray.doc_url = "";
        dummyarray.doc_name = "";
        dummyarray.doc_selected = false;
        dummyarray.doc_uploaded = false;
        setdocumentformdatas([...documentformdatas]);

        let selectedFile_d = selectedFile[index];
        selectedFile_d.selected_file = "";
        selectedFile_d.doc_url = "";
        selectedFile_d.doc_name = "";
        setSelectedFile([...selectedFile]);
        document.getElementById(value).value = "";

        // get user id from local storgae
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        setshowloader(true);

        let JSONvalue = {
            'userid': userid_o,
            'RefId': referenceId,
            "RefId_array": referenceIdArray,
            'services_id': services_id,
            'field_name': dummyarray.field_name,
            'field_type': dummyarray.field_type,
            'form_value': dummyarray.form_value,
            'data_type': dummyarray.field_type,
            'doc_url': "",
            'doc_name': "",
        }
        axiosInstance.post('/Applicationform/updatedocuments', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    getapplicationformdetails();
                    setshowloader(false);
                    toast.success("documents removed successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.SUCCESS,
                        className: 'rotateY animated'
                    });
                    // if (mode !== 'save') {
                    //     next();
                    // }
                }
                else {
                    toast.success(res.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.SUCCESS,
                        className: 'rotateY animated'
                    })
                }
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    function handeluploaddoce(event, value, datatype, index) {
        let dummyarray = documentformdatas[index];
        let selectedFile_d = selectedFile[index];

        // get user id from local storgae
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        setshowloader(true);

        // ******************** Insert Document datas *************************
        const formData = new FormData();
        formData.append("userid", userid_o);
        formData.append("RefId", referenceId);
        for (let i = 0; i < services_id.length; i++) {
            formData.append('services_id[]', services_id[i]);
            formData.append('RefId_array[]', referenceIdArray[i]);
        }
        formData.append("field_name", dummyarray.field_name);
        formData.append("field_type", dummyarray.field_type);
        formData.append("form_value", dummyarray.form_value);
        formData.append("data_type", dummyarray.field_type);
        formData.append("doc_url", "");
        formData.append("doc_name", "");
        formData.append("files", selectedFile_d.selected_file);
        axiosInstance.post('/Applicationform/insertdocuments', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    setshowloader(false);
                    getapplicationformdetails();
                    toast.success("documents uploaded successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.SUCCESS,
                        className: 'rotateY animated'
                    });
                }
                else {
                    toast.success(res.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        type: toast.TYPE.SUCCESS,
                        className: 'rotateY animated'
                    })
                }
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            })

        // ******************** Insert Document datas end*************************
    }

    function documentformvalidation() {
        let flag = 0;
        for (let i = 0; i < documentformdatas.length; i++) {
            let dummyarray = documentformdatas[i];
            if (dummyarray.mandatory === 'yes' && dummyarray.doc_name === '') {
                dummyarray.error_msg_h = '* ' + dummyarray.field_name + ' cannot be blank';
                dummyarray.show_error_h = true;
                flag = 1;
            }
            else if (dummyarray.doc_name !== '' && dummyarray.doc_uploaded === false) {
                dummyarray.error_msg_h = '* Please click upload button to upload the file';
                dummyarray.show_error_h = true;
                flag = 1;
            }
            else {
                dummyarray.error_msg_h = '';
                dummyarray.show_error_h = false;
            }
            setdocumentformdatas([...documentformdatas]);
        }
        if (flag === 0) {
            return true;
        } else {
            return false;
        }
    }

    function handelgeneratestep() {
        const validate = documentformvalidation();
        if (validate) {
            setshowloader(true);
            // get user id from local storgae
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                'userid': userid_o,
                'RefId': referenceId,
                "RefId_array": referenceIdArray,
            }
            axiosInstance.post('/Applicationform/generateapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        next();
                        props.refreshcartdata();
                    }
                    setshowloader(false);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        }
    }

    function handeleditform() {
        var url = window.location.href;
        var parts = url.split('/');
        let array_join = referenceIdArray.join();
        localStorage.setItem('refid', array_join);
        navigate("/editapplicationform/");
        if (parts[3] === 'editapplicationform') {
            window.location.reload();
        }
    }

    function handelrequestgserves() {
        setshowloader(true);
        // get user id from local storgae
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }

        let JSONvalue = {
            'userid': userid_o,
            'RefId': referenceId,
            "RefId_array": referenceIdArray,
        }
        let array_join = referenceIdArray.join();
        axiosInstance.post('/Applicationform/updatestatus', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    localStorage.setItem('requestid', array_join);
                    navigate("/payment");
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }


    function handeldownloadapplication() {
        if (referenceIdArray.length == 1) {
            setshowloader(true);
            let myarrayss = []
            if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                let requestid_o = localStorage.getItem('requestid');
                myarrayss = requestid_o.split(',');
            }
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "refid": referenceIdArray,
                "userid": userid_o,
            }
            axiosInstance.post('/Applicationform/viewapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        if (res.data.data.length > 0) {
                            for (let i = 0; i < res.data.data.length; i++) {
                                const newWindow = window.open(res.data.data[i], '_blank', 'noopener,noreferrer')
                                if (newWindow) newWindow.opener = null
                            }
                        }
                    }
                    if (res.data.status === 'failed') {
                        console.log(res.data);
                    }
                    setshowloader(false);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        }
        else {
            downloadappopen();
        }
    }

    function handeldownloadapplicationbyid(index) {
        setshowloader(true);
        let myarrayss = [];
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        myarrayss.push(referenceIdArray[index]);
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewapplication', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    if (res.data.data.length > 0) {
                        for (let i = 0; i < res.data.data.length; i++) {
                            const newWindow = window.open(res.data.data[i], '_blank', 'noopener,noreferrer')
                            if (newWindow) newWindow.opener = null
                        }
                    }
                    downloadappclose();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [downloadappmodal, setdownloadapp] = useState(false);
    const downloadappclose = () => {
        setdownloadapp(false);
    }
    const downloadappopen = () => {
        setdownloadapp(true);
    }

    const [documentmodal_datas, setdocumentmodal_datas] = useState([]);

    function handelviewdocumentsupload() {
        setshowloader(true);
        let myarrayss = []
        if (localStorage.getItem('tdid') !== null && localStorage.getItem('tdid') !== undefined) {
            let requestid_o = localStorage.getItem('tdid');
            myarrayss = requestid_o.split(',');
        }
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "refid": referenceIdArray,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/viewdocuments', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setdocumentmodal_datas(res.data.data['application_data']);
                    documentmodalopen();
                }
                if (res.data.status === 'failed') {
                    console.log(res.data);
                }
                setshowloader(false);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }

    const [documentmodal, setdocumentmodal] = useState(false);
    const documentmodalclose = () => {
        setdocumentmodal(false);
    }
    const documentmodalopen = () => {
        setdocumentmodal(true);
    }

    const [email_error_msgs, setemail_error_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });


    function handelemailapplication() {
        if (referenceIdArray.length == 1) {
            setshowloader(true);
            let myarrayss = []
            if (localStorage.getItem('requestid') !== null && localStorage.getItem('requestid') !== undefined) {
                let requestid_o = localStorage.getItem('requestid');
                myarrayss = requestid_o.split(',');
            }
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "refid": referenceIdArray,
                "userid": userid_o,
            }
            axiosInstance.post('/Applicationform/emailapplication', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        setemail_error_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                    }
                    if (res.data.status === 'failed') {
                        setemail_error_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                    }
                    setshowloader(false);
                    setTimeout(() => {
                        clearemaildatas();
                    }, 5000);
                })
                .catch((err) => {
                    setshowloader(false);
                    console.log(err);
                });
        }
        else {
            emailappmodalopen();
        }
    }

    function handelemailapplicationbyid(index) {
        setshowloader(true);
        let myarrayss = [];
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        myarrayss.push(referenceIdArray[index]);
        let JSONvalue = {
            "refid": myarrayss,
            "userid": userid_o,
        }
        axiosInstance.post('/Applicationform/emailapplication', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setemail_error_msgs({
                        show_success_msg: true,
                        success_msg: res.data.msg,
                        show_error_msg: false,
                        error_msg: ''
                    });
                    emailappmodalclose();
                }
                if (res.data.status === 'failed') {
                    setemail_error_msgs({
                        show_success_msg: false,
                        success_msg: '',
                        show_error_msg: true,
                        error_msg: res.data.msg
                    });
                }
                setshowloader(false);
                setTimeout(() => {
                    clearemaildatas();
                }, 5000);
            })
            .catch((err) => {
                setshowloader(false);
                console.log(err);
            });
    }



    const [emailappmodal, setemailappmodal] = useState(false);
    const emailappmodalclose = () => {
        setemailappmodal(false);
    }
    const emailappmodalopen = () => {
        setemailappmodal(true);
    }


    function clearemaildatas() {
        setemail_error_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }


    return (
        <>
            <ToastContainer />
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }

            {generalformdatas.length > 0 ?
                <>
                    <div className="container">
                        <div className="row">
                            <div className={device.isMobileOnly === true ? " col-1" : "col-2"}></div>
                            <div className={device.isMobileOnly === true ? " col-10" : "col-8"}>
                                <div className="con-bottom-inner custu_padd">
                                    <h4><span style={{ color: "#6c757d", marginLeft: "10px", marginRight: "10px" }}>{services_name}</span></h4>
                                </div>
                            </div>
                            <div className={device.isMobileOnly === true ? " col-1" : "col-2"}></div>
                        </div>
                    </div>
                    <Steps>
                        <div className='step1'>
                            <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                <div id="grad1">
                                    <div className="row">
                                        <div className="col-12">
                                            <form id="msform">
                                                <ul id="progressbar" className='p-0'>
                                                    <li className="active" id="step1"><strong>General information</strong></li>
                                                    <li id="step2"><strong>Documents Upload</strong></li>
                                                    <li id="confirm"><strong>Request Gserves</strong></li>
                                                </ul>
                                                <div className="form-card ">
                                                    <div className="row mt-5">
                                                        {
                                                            generalformdatas.length > 0 ?
                                                                <>
                                                                    {
                                                                        generalformdatas.map((value, index) => {
                                                                            return (
                                                                                <div key={index} className="col-sm-12  mb-4">
                                                                                    <ReactTooltip id={"sadFace" + index} place="right" type="dark" effect="solid" multiline="true" ></ReactTooltip>
                                                                                    <div className="form-group row">
                                                                                        {value.field_type === 'select' ?
                                                                                            <>
                                                                                                <label htmlFor={value.field_name} className="text-left col-sm-4 col-form-label fw-bold">
                                                                                                    {value.display_name}
                                                                                                    {
                                                                                                        value.display_description !== '' ?
                                                                                                            <>
                                                                                                                <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                </span>
                                                                                                            </>
                                                                                                            : null
                                                                                                    }
                                                                                                    {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                </label>
                                                                                                <div className="col-sm-8 text-left">
                                                                                                    <select
                                                                                                        value={value.form_value}
                                                                                                        className="form-control"
                                                                                                        id={value.field_name}
                                                                                                        name={value.field_name}
                                                                                                        onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, 0)}
                                                                                                        disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                    >
                                                                                                        <option value={''}>--- Select --- </option>
                                                                                                        {value.field_options.map((value2, index2) => {
                                                                                                            return (<option key={index2} value={value2.option_value}>{value2.option_name}</option>);
                                                                                                        })}
                                                                                                    </select>
                                                                                                    {
                                                                                                        value.show_error_h === true ?
                                                                                                            <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                            : <></>
                                                                                                    }
                                                                                                </div>

                                                                                            </>
                                                                                            : value.field_type === 'radio' ?
                                                                                                <>
                                                                                                    <label htmlFor={value.field_name} className="text-left col-sm-4 col-form-label fw-bold">
                                                                                                        {value.display_name}
                                                                                                        {
                                                                                                            value.display_description !== '' ?
                                                                                                                <>
                                                                                                                    <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                    </span>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }
                                                                                                        {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                    </label>
                                                                                                    <div className="col-sm-8 text-left">
                                                                                                        {value.field_options.map((value2, index2) => {
                                                                                                            return (
                                                                                                                <div className="radio_s" key={index2}>
                                                                                                                    <label>
                                                                                                                        <input type="radio"
                                                                                                                            value={value2.option_value}
                                                                                                                            name={value.field_name}
                                                                                                                            onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, index2)}
                                                                                                                            disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                                            checked={value.form_value === value2.option_value ? true : false}
                                                                                                                        /> {value2.option_name}
                                                                                                                        <span className="checkmark"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            );
                                                                                                        })}
                                                                                                        {
                                                                                                            value.show_error_h === true ?
                                                                                                                <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                                : <></>
                                                                                                        }

                                                                                                    </div>

                                                                                                </>
                                                                                                : value.field_type === 'checkbox' ?
                                                                                                    <>
                                                                                                        <label htmlFor={value.field_name} className="text-left col-sm-4 col-form-label fw-bold">
                                                                                                            {value.display_name}
                                                                                                            {
                                                                                                                value.display_description !== '' ?
                                                                                                                    <>
                                                                                                                        <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                    : null
                                                                                                            }
                                                                                                            {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                        </label>
                                                                                                        <div className="col-sm-8 text-left">
                                                                                                            <div className='disp_inb'>
                                                                                                                {value.field_options.map((value2, index2) => {
                                                                                                                    return (
                                                                                                                        <div className="radio_s" key={index2}>
                                                                                                                            <label>
                                                                                                                                <input type="checkbox"
                                                                                                                                    value={value2.option_value}
                                                                                                                                    name={value.field_name}
                                                                                                                                    onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, index2)}
                                                                                                                                    disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                                                    checked={value2.option_selected}
                                                                                                                                />
                                                                                                                                &nbsp;&nbsp;{value2.option_name}
                                                                                                                            </label>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </div>
                                                                                                            {
                                                                                                                value.show_error_h === true ?
                                                                                                                    <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                                    : <></>
                                                                                                            }
                                                                                                        </div>

                                                                                                    </>
                                                                                                    : value.field_type === 'date' ?
                                                                                                        <>
                                                                                                            <label htmlFor={value.field_name} className="text-left col-sm-4 col-form-label fw-bold">
                                                                                                                {value.display_name}
                                                                                                                {
                                                                                                                    value.display_description !== '' ?
                                                                                                                        <>
                                                                                                                            <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                            </span>
                                                                                                                        </>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                            </label>
                                                                                                            <div className="col-sm-8 text-left">
                                                                                                                <input type="date" className="form-control"
                                                                                                                    placeholder="Date"
                                                                                                                    value={value.form_value}
                                                                                                                    id={value.field_name}
                                                                                                                    name={value.field_name}
                                                                                                                    onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, 0)}
                                                                                                                    disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                                />
                                                                                                                {
                                                                                                                    value.show_error_h === true ?
                                                                                                                        <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                                        : <></>
                                                                                                                }
                                                                                                            </div>

                                                                                                        </>
                                                                                                        : value.field_type === 'textarea' ?
                                                                                                            <>
                                                                                                                <label htmlFor={value.field_name}
                                                                                                                    className="text-left col-sm-4 col-form-label fw-bold">{value.display_name}
                                                                                                                    {
                                                                                                                        value.display_description !== '' ?
                                                                                                                            <>
                                                                                                                                <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                                </span>
                                                                                                                            </>
                                                                                                                            : null
                                                                                                                    }
                                                                                                                    {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                                </label>
                                                                                                                <div className="col-sm-8 text-left">
                                                                                                                    <textarea rows="1" className="form-control"
                                                                                                                        value={value.form_value}
                                                                                                                        id={value.field_name}
                                                                                                                        name={value.field_name}
                                                                                                                        onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, 0)}
                                                                                                                        disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                                    />
                                                                                                                    {
                                                                                                                        value.show_error_h === true ?
                                                                                                                            <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                                            : <></>
                                                                                                                    }
                                                                                                                </div>

                                                                                                            </>
                                                                                                            : value.field_type === 'text' ?
                                                                                                                <>
                                                                                                                    <label htmlFor={value.field_name}
                                                                                                                        className="text-left col-sm-4 col-form-label fw-bold">{value.display_name}
                                                                                                                        {
                                                                                                                            value.display_description !== '' ?
                                                                                                                                <>
                                                                                                                                    <span data-tip={value.display_description} data-for={"sadFace" + index}>&nbsp;&nbsp;<FontAwesomeIcon icon={faQuestionCircle} />
                                                                                                                                    </span>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                                        }
                                                                                                                        {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}
                                                                                                                    </label>
                                                                                                                    <div className="col-sm-8 text-left">
                                                                                                                        <input value={value.form_value}
                                                                                                                            type="text" className="form-control"
                                                                                                                            id={value.field_name}
                                                                                                                            name={value.field_name}
                                                                                                                            onChange={(e) => handelgeneralformonchange(e, value.field_name, value.field_type, index, value.field_text_format, 0)}
                                                                                                                            disabled={value.dependent_field !== 0 ? value.dependent_field_disable : false}
                                                                                                                        />
                                                                                                                        {
                                                                                                                            value.show_error_h === true ?
                                                                                                                                <span className='error_msg'>{value.error_msg_h}</span>
                                                                                                                                : <></>
                                                                                                                        }
                                                                                                                    </div>

                                                                                                                </>
                                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </> :
                                                                <>
                                                                    <p className='text-center' >No data found</p>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='step2'>
                            <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                <div id="grad1">
                                    <div className="row">
                                        <div className="col-12" id="msform">
                                            <ul id="progressbar" className='p-0'>
                                                <li className="active" id="step1"><strong>General information</strong></li>
                                                <li className="active" id="step2"><strong>Documents Upload</strong></li>
                                                <li id="confirm"><strong>Request Gserves</strong></li>
                                            </ul>
                                            <div className="form-card ">
                                                <div className="row mt-5">
                                                    {
                                                        documentformdatas.length > 0 ?
                                                            <>
                                                                {
                                                                    documentformdatas.map((value, index) => {
                                                                        return (
                                                                            <div key={index} className="col-sm-12 mb-2rm">
                                                                                {value.field_type === 'file' ?
                                                                                    <>
                                                                                        <div className='row'>
                                                                                            <div className='col-sm-4 text-left'>
                                                                                                <label className="text-left col-form-label fw-bold" htmlFor={value.field_name} >{value.display_name} {value.mandatory === 'yes' ? <span className="color_red">&nbsp; *</span> : null}</label>
                                                                                            </div>
                                                                                            <div className='col-sm-8 text-left'>
                                                                                                <div className="custom-file mb-2">
                                                                                                    <input style={{ width: "90%" }} type="file" disabled={value.doc_uploaded} className="custom-file-input" id={value.field_name} name={value.field_name} onChange={(e) => handeldocumentformonchange(e, value.field_name, value.field_type, index, value.file_type)} />
                                                                                                </div>
                                                                                                {
                                                                                                    value.file_type == 'image' ?
                                                                                                        <>
                                                                                                            <small>Note * Only jpg/jpeg and png files are allowed</small>
                                                                                                        </>
                                                                                                        : value.file_type == 'pdf' ?
                                                                                                            <>
                                                                                                                <small>Note * Only PDF files are allowed!</small>
                                                                                                            </>
                                                                                                            : value.file_type == 'image_pdf' ?
                                                                                                                <>
                                                                                                                    <small>Note * Only jpg/jpeg/png and PDF files are allowed!</small>
                                                                                                                </>
                                                                                                                : null
                                                                                                }
                                                                                                {
                                                                                                    value.doc_selected === true ?
                                                                                                        <div className="col-sm-12 mt-3 mb-2">
                                                                                                            <div className='row'>
                                                                                                                <div className='selectedfile col-10'>
                                                                                                                    <a href={value.doc_url} target="_blank">
                                                                                                                        <p className='cut-text'>
                                                                                                                            {value.doc_name}
                                                                                                                        </p>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                {value.doc_uploaded === true ?
                                                                                                                    <div className='col-2'>
                                                                                                                        <button className="btn submitfilebtn btn-danger" onClick={(e) => handelremovedoce(e, value.field_name, value.field_type, index)}>
                                                                                                                            <FontAwesomeIcon icon={faTrash} /></button>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className='col-2'>
                                                                                                                        <button className="btn submitfilebtn btn-success" onClick={(e) => handeluploaddoce(e, value.field_name, value.field_type, index)}>
                                                                                                                            <FontAwesomeIcon icon={faUpload} /></button>
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : <></>
                                                                                                }
                                                                                                {
                                                                                                    value.show_error_h === true ?
                                                                                                        <span className='mt-2 error_msg float-left'>{value.error_msg_h}</span>
                                                                                                        : <></>
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                    : null}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </> :
                                                            <>
                                                                <p className='text-center' >No data found</p>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='step3'>
                            <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                                <div id="grad1">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-10" id="msform">
                                            <ul id="progressbar" className='p-0'>
                                                <li className="active" id="step1"><strong>General information</strong></li>
                                                <li className="active" id="step2"><strong>Documents Upload</strong></li>
                                                <li className="active" id="confirm"><strong>Request Gserves</strong></li>
                                            </ul>
                                            <div className="form-card">
                                                <h2 className="fs-title text-center">Success !</h2> <br />
                                                <div className="row justify-content-center">
                                                    <div className={device.isMobileOnly === true ? "col-6" : "col-3"} >

                                                        <img
                                                            src="/assets/img/success.png"
                                                            className="fit-image" /> </div>
                                                </div> <br />
                                                <div className="row justify-content-center">
                                                    <div className={device.isMobileOnly === true ? "text-center w-100" : "text-center w-50"} >
                                                        <h5 style={{ paddingBottom: "20px" }}>You Have Successfully Generated</h5>
                                                        <h5 className='mb-3'>Reference Id</h5>
                                                        {
                                                            referenceIdArray.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={index}>
                                                                            <p className='mb-2'>{userNo} - 00{value}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        <br />


                                                        <div className='mt-2' style={{ padding: "30px", backgroundColor: "#6c757d", borderRadius: "5px" }}>
                                                            <p style={{ color: "white" }}>One step away! Click here to take it ahead!
                                                            </p>
                                                            <button onClick={() => handelrequestgserves()} className="btn btn-light btn-sm">Request GServes</button>
                                                            {/* <a href="request.html" type="button" className="btn btn-light btn-sm"
                                                                value="payment"></a> */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pb-20 pt-20">
                                                    <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                        <button className="btn btn-secondary btn-small w-100" onClick={() => handeleditform()}>View / Edit form</button>

                                                    </div>
                                                    <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                        <button className="btn btn-secondary btn-small w-100" onClick={() => handelviewdocumentsupload()}>View
                                                            documents</button>

                                                    </div>
                                                    <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                        <button className="btn btn-secondary btn-small w-100" onClick={() => handeldownloadapplication()}>Download
                                                            Application</button>

                                                    </div>
                                                    <div className={device.isMobileOnly === true ? "col-6 mb-3" : "col-3"}>
                                                        <button className="btn btn-secondary btn-small w-100" onClick={() => handelemailapplication()}>Email Application</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1"></div>
                                    </div>
                                </div>
                                {
                                    email_error_msgs.show_success_msg === true ?
                                        <>
                                            <div className="container success_msg_bx">
                                                <h4>{email_error_msgs.success_msg}</h4>
                                            </div>
                                        </> :
                                        null
                                }
                                {
                                    email_error_msgs.show_error_msg === true ?
                                        <>
                                            <div className="container error_msg_bx">
                                                <h4>{email_error_msgs.error_msg}</h4>
                                            </div>
                                        </> :
                                        null
                                }

                                <br />
                            </div>
                        </div>

                    </Steps>
                    <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                        <div id="grad1">
                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            {
                                                current == 2 ?
                                                    <>
                                                        <button name="previous" className="btn btn-info btn-sm btn_next_prev" value="previous" onClick={prev}><FontAwesomeIcon icon={faLongArrowAltLeft} /> Previous</button>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className='col-6 text-end'>
                                            {
                                                current == 2 ?
                                                    <>
                                                        {
                                                            props.application_type === 'EDIT' ?
                                                                <>
                                                                    <button name="previous" className="btn btn-secondary btn-sm btn_save_doc" value="previous" onClick={() => handelcancelstep('EDIT')}>Cancel &nbsp;<FontAwesomeIcon icon={faMultiply} /></button>
                                                                    <button name="previous" className="btn btn-info btn-sm btn_next_prev" value="previous" onClick={() => handelgeneratestep()}>Update <FontAwesomeIcon icon={faLongArrowAltRight} /></button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button name="previous" className="btn btn-secondary btn-sm btn_save_doc" value="previous" onClick={() => handelcancelstep('save')}>Cancel &nbsp;<FontAwesomeIcon icon={faMultiply} /></button>
                                                                    <button name="previous" className="btn btn-info btn-sm btn_next_prev" value="previous" onClick={() => handelgeneratestep()}>Generate <FontAwesomeIcon icon={faLongArrowAltRight} /></button>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            current === 1 ?
                                                <>
                                                    <div className='col-4 text-end'></div>
                                                    <div className='col-4 text-end'>
                                                        {
                                                            current === 1 ?
                                                                <>
                                                                    {
                                                                        props.application_type === 'EDIT' ?
                                                                            <>
                                                                                <button name="previous" className="btn btn-secondary btn-sm btn_next_prev" value="previous" onClick={() => handelcancelstep('EDIT')}>Cancel &nbsp;<FontAwesomeIcon icon={faMultiply} /></button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <button name="previous" className="btn btn-secondary btn-sm btn_next_prev" value="previous" onClick={() => handelcancelstep('save')}>Cancel &nbsp;<FontAwesomeIcon icon={faMultiply} /></button>
                                                                            </>
                                                                    }
                                                                    <button name="previous" className="btn btn-success btn-sm btn_save_doc" value="previous" onClick={() => handelnextstep('save')}>Save <FontAwesomeIcon icon={faSave} /></button>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className='col-4 text-end'>
                                                        {
                                                            current === 1 ?
                                                                <>
                                                                    <button name="previous" className="btn btn-info btn-sm btn_next_prev" value="previous" onClick={() => handelnextstep('next')}>Next <FontAwesomeIcon icon={faLongArrowAltRight} /></button>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : loadedapi === true && generalformdatas.length <= 0 ?
                    <>
                        <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                            <div id="grad1">
                                <div className="row">
                                    <div className="col-1"></div>
                                    <div className="col-10 text-center">
                                        {/* <h6 style={{ padding: "70px 0px" }}>No data found in card</h6> */}

                                        <div className='row'>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                            <div className='col-sm-6 mb-3'>
                                                <Skeleton baseColor="#d7dbda" count={1} height={30} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="container" style={{ padding: "10px 10px 50px 10px", backgroundColor: "#fff6f6" }}>
                            <div id="grad1">
                                <div className="row">
                                    <div className="col-1"></div>
                                    <div className="col-10 text-center">
                                        <h6 style={{ padding: "70px 0px" }}>No data found in card</h6>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </div>
                    </>
            }


            <Modal open={documentmodal} onClose={documentmodalclose} center
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'myuploadedmodals',
                }}>
                <div className='modal_cnt'>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <div className="con-bottom-inner pb-2">
                                        <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Uploaded Documents</span></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </section>

                    <section>
                        <div className="container mb-3 mt-3">
                            {documentmodal_datas.length > 0 ?
                                <>
                                    {documentmodal_datas.map((value_d, index2) => {
                                        return (
                                            <div className='row' key={index2}>
                                                <div className='col-6'>
                                                    <p> {value_d.display_name} <span className='float-end'>:</span></p>
                                                </div>
                                                <div className='col-6'>
                                                    <a href={value_d.doc_url} target="_blank">{value_d.doc_name}</a>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                                :
                                <><p className='text-center p-5'>No data found</p>
                                </>
                            }
                        </div>
                    </section>
                </div>
            </Modal>

            <Modal open={downloadappmodal} onClose={downloadappclose} center
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'myuploadedmodals',
                }}>
                <div className='modal_cnt'>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <div className="con-bottom-inner pb-2">
                                        <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Download application form</span></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </section>

                    <section>
                        <div className="container mb-3 mt-3">
                            {services_name_array.length > 0 ?
                                <>
                                    {services_name_array.map((value_d, index2) => {
                                        return (
                                            <div className='row' key={index2}>
                                                <div className='col-4'>
                                                    <p> Application form {index2 + 1} <span className='float-end'>:</span></p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='cursor-pointer' onClick={() => handeldownloadapplicationbyid(index2)}>{value_d} {userNo} - 00{referenceIdArray[index2]}</p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                                :
                                <><p className='text-center p-5'>No data found</p>
                                </>
                            }
                        </div>
                    </section>
                </div>
            </Modal>


            <Modal open={emailappmodal} onClose={emailappmodalclose} center
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'myuploadedmodals',
                }}>
                <div className='modal_cnt'>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <div className="con-bottom-inner pb-2">
                                        <h5><span style={{ color: '#6c757d', fontWeight: 700 }}>Email application form</span></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </section>

                    <section>
                        <div className="container mb-3 mt-3">
                            {services_name_array.length > 0 ?
                                <>
                                    {services_name_array.map((value_d, index2) => {
                                        return (
                                            <div className='row' key={index2}>
                                                <div className='col-4'>
                                                    <p> Application form {index2 + 1} <span className='float-end'>:</span></p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='cursor-pointer' onClick={() => handelemailapplicationbyid(index2)}>{value_d} {userNo} - 00{referenceIdArray[index2]}</p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </>
                                :
                                <><p className='text-center p-5'>No data found</p>
                                </>
                            }
                        </div>
                    </section>
                </div>
            </Modal>

        </>
    );

});
export default Multisteps;

