import React from 'react';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile,faPaperPlane,faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contactus(props) {

    return (
        <>
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section  style={{ backgroundColor: '#f2ffff'}}>
                        <div className="container" style={{ paddingTop: "150px", paddingBottom: "150px" }}>
                            <div className="row">
                                <div className="col-lg-12 text-center" style={{ paddingBottom: "50px" }}>
                                <h3 style={{ color: '#6c757d', fontWeight: 800 }}>Contact Us</h3>
                                </div>
                                <div className="col-lg-6 text-left">
                                <p style={{ color: '#6c757d', fontSize: '1rem' , paddingBottom: "10px" }}><FontAwesomeIcon color='#09988e' icon={faMobile} /> : +91 89710 63235</p>
                                <p style={{ color: '#6c757d',  fontSize: '1rem' ,paddingBottom: "10px" }}><FontAwesomeIcon color='#09988e' icon={faPaperPlane} /> : guide@gserves.com</p>
                                <p style={{ color: '#6c757d', fontSize: '1rem' }}><FontAwesomeIcon color='#09988e' icon={faEnvelope} /> : GSERVES INDIA LLP - S-1, COMFORT HEIGHTS , LB SHASTRINAGAR VIMANAPURA MAIN ROAD BANGALORE Bangalore KA 560017 IN</p>
                                </div>
                                <div className="col-lg-6">
                                        <img src="/assets/img/contactus_map.png" style={{ width: '100%' }} alt='service' />
                                </div></div>
                        </div>
                    </section>
            </main>
            <FooterComp />
        </>
    );
}

export default Contactus;