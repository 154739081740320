import './App.css';
import Pages from './pages';

function App() {
  return (
    <Pages />
  );
}

export default App
