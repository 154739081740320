import React, {useState, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import BannerMenuComp from '../components/bannermenu';
import SearchComp from '../components/search';
import { StepsProvider } from "react-step-builder";
import Multisteps from './Multisteps';
import { useNavigate } from "react-router-dom";
import * as device from 'react-device-detect';


function Applicationform(props) {

    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        setshowloader(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const childCompRef = useRef();
    const multipleCompRef = useRef();
    function refreshcartdata() {
        childCompRef.current.getcartdetails();
    }
    function refreshapplication() {
        multipleCompRef.current.getapplicationformdetails();
    }

    return (
        <>
            <HeaderComp ref={childCompRef} refreshapplication={() => { refreshapplication(); }} />
            <main role="main">
                <section id='main_banner'>
                    <Container fluid className='bg-semi-dark' style={{ paddingBottom: 40 }} >
                        <Container>
                            <div className='intro-section mobintro-section'>
                                <Row>
                                    <Col className='pdb-10'>
                                        <BannerMenuComp />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='pdb-20'>
                                        <SearchComp />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </Container>
                </section>

                <section>
                    <StepsProvider>
                        <Multisteps ref={multipleCompRef} application_type="INSERT" refid="" refreshcartdata={() => { refreshcartdata(); }} />
                    </StepsProvider>
                </section>


                <FooterComp />
            </main>
        </>
    );
}

export default Applicationform;

