import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope, faL } from '@fortawesome/free-solid-svg-icons';
import HeaderComp from '../components/header';
import FooterComp from '../components/footer';
import { axiosInstance } from '../Services';
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { Base64 } from 'js-base64';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function Verification(props) {
    const navigate = useNavigate();
    const [showloader, setshowloader] = useState(false);
    const [datafrom, setdatafrom] = useState('');
    useEffect(() => {
        if (localStorage.getItem('logging_status') !== true && localStorage.getItem('logging_status') !== 'true') {
            navigate("/");
        }
        var url = window.location.href;
        var parts = url.split('type=');
        setdatafrom(parts[1]);
        getusermobilenumber();
        setshowloader(false);
    }, []);

    function handelresendotp() {
        setFomdatas({
            mobile_otp: "",
        });
        handelonclicksendotp();
    }

    const [form_datas, setFomdatas] = useState({
        mobile_otp: '',
    });

    const [error_msgs, seterror_msgs] = useState({
        show_success_msg: false,
        success_msg: '',
        show_error_msg: false,
        error_msg: '',
    });

    const [form_errormsg, setformerrormsg] = useState({
        mobile_otp_errormsg: "",
    });

    function handleInputChange(event) {
        if (event.target.name === "mobile_otp") {
            setFomdatas({
                ...form_datas,
                mobile_otp: event.target.value
            });
        }
    }

    function formvalidation() {
        let mobile_otp_errormsg = '';
        const re = /^[0-9\b]+$/;
        if (!form_datas.mobile_otp) {
            mobile_otp_errormsg = "Mobile OTP cannot be blank";
        }
        else if (form_datas.mobile_otp.length !== 6) {
            mobile_otp_errormsg = "Enter Valid mobile OTP";
        }
        else if (re.test(form_datas.mobile_otp) === false) {
            mobile_otp_errormsg = "Enter Valid mobile OTP";
        }
        if (mobile_otp_errormsg) {
            setformerrormsg({
                mobile_otp_errormsg: mobile_otp_errormsg,
            });
            return false;
        }
        else {
            return true;
        }
    }

    function handelverification() {
        const validated = formvalidation();
        if (validated) {
            setshowloader(true);
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "userid": userid_o,
                "mobile_otp": form_datas.mobile_otp,
                "mobile_number":otp_form_datas.mobile_number
            }
            setformerrormsg({
                mobile_otp_errormsg: "",
            });
            axiosInstance.post('/Otp/verifyotp', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setFomdatas({
                            mobile_otp: '',
                            email_otp: '',
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 3000);
                        if (datafrom == 'qht4nWWPPQQVVSeqwrcc1yvr') {
                            navigate(-1);
                        }
                        if (datafrom == 'muecvt003zht55fewsvv') {
                            navigate("/emailverification");
                        }
                    }
                    else if (res.data.status === 'failed') {
                        seterror_msgs({
                            show_success_msg: false,
                            success_msg: '',
                            show_error_msg: true,
                            error_msg: res.data.msg
                        });
                        setTimeout(() => {
                            cleardatas();
                        }, 3000);
                    }
                    setshowloader(false);
                })
                .catch(() => {
                    seterror_msgs({
                        ...error_msgs,
                        show_error_msg: true,
                        error_msg: 'Something went wrong please try again'
                    });
                    setTimeout(() => {
                        cleardatas();
                    }, 3000);
                    setshowloader(false);
                });
        }
    }

    function cleardatas() {
        seterror_msgs({
            show_success_msg: false,
            success_msg: '',
            show_error_msg: false,
            error_msg: '',
        });
    }

    // ********************************* Modal

    const [showentermobileno, setshowentermobileno] = useState(false);
    const [usermobileno, setusermobileno] = useState('');
    const [usermobilenolasttwo, setusermobilenolasttwo] = useState('');
    const [mobileverification, setmobileverification] = useState(true);
    const [mobilestatus, setmobilestatus] = useState(false);

    const openmobileverification = () => {
        setmobileverification(true);
    }

    const closemobileverification = () => {
        setmobileverification(false);
        if (mobilestatus === false) {
            navigate(-1);
        }
    }

    const [otp_form_datas, setotp_form_datas] = useState({
        mobile_number: '',
    });

    const [otp_form_errormsg, setotp_form_errormsg] = useState({
        mobile_number_errormsg: "",
    });

    function getusermobilenumber() {
        setshowloader(true);
        let userid_o = 0;
        if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
            userid_o = Base64.decode(localStorage.getItem('gsud'));
        }
        let JSONvalue = {
            "userid": userid_o,
        }
        axiosInstance.post('/Userdetails/getmobileno', JSONvalue)
            .then((res) => {
                if (res.data.status === 'success') {
                    setusermobileno(res.data.data);
                    setotp_form_datas({
                        ...otp_form_datas,
                        mobile_number: res.data.data
                    });
                    var last2 = res.data.data.slice(-2);
                    setusermobilenolasttwo(last2);
                }
                setshowloader(false);
            })
            .catch(() => {
                setshowloader(false);
            });
    }

    function handleOTPInputChange(event) {
        if (event.target.name === "mobile_number") {
            setotp_form_datas({
                ...otp_form_datas,
                mobile_number: event.target.value
            });
        }
    }

    function handelchangenumber() {
        setshowentermobileno(true);
    }

    function handelchangenumber_d() {
        setmobileverification(true);
        setshowentermobileno(true);
    }

    function otpformvalidation() {
        let mobile_number_errormsg = '';
        var mobile_number = otp_form_datas.mobile_number.toString();
        if (!otp_form_datas.mobile_number) {
            mobile_number_errormsg = "* Mobile number cannot be blank";
        }
        else if (mobile_number.length != 10) {
            mobile_number_errormsg = "* Enter Valid mobile number";
        }
        if (mobile_number_errormsg) {

            console.log(mobile_number_errormsg);
            console.log(otp_form_datas.mobile_number);
            setotp_form_errormsg({
                mobile_number_errormsg: mobile_number_errormsg,
            });
            return false;
        }
        else {
            return true;
        }

    }

    function handelonclicksendotp() {
        const validated = otpformvalidation();
        if (validated) {
            var last2 = otp_form_datas.mobile_number.slice(-2);
            setusermobilenolasttwo(last2);
            setshowloader(true);
            let userid_o = 0;
            if (localStorage.getItem('logging_status') !== null && localStorage.getItem('logging_status') !== undefined) {
                userid_o = Base64.decode(localStorage.getItem('gsud'));
            }
            let JSONvalue = {
                "userid": userid_o,
                "mobile_number": otp_form_datas.mobile_number,
                "type":"mobile"
            }
            axiosInstance.post('/Otp/sendotp', JSONvalue)
                .then((res) => {
                    if (res.data.status === 'success') {
                        seterror_msgs({
                            show_success_msg: true,
                            success_msg: res.data.msg,
                            show_error_msg: false,
                            error_msg: ''
                        });
                        setmobileverification(false);
                        setTimeout(() => {
                            cleardatas();
                        }, 3000);
                    }
                    else if (res.data.status === 'failed') {

                    }
                    setshowloader(false);
                })
                .catch(() => {
                    setshowloader(false);
                });
        }
    }


    return (
        <>
            {showloader === true ?
                <div className='loader'>
                    <BounceLoader color={'rgb(9, 152, 142)'} loading={true} size={50} />
                </div>
                : null
            }
            <HeaderComp />
            <main role="main">
                <section className="container-fluid bg-semi-dark" style={{ paddingBottom: 50 }}>
                    <div className="container">
                        <div className="intro-section">
                            <div className="pdb-20">
                                <h1 className="white" style={{ fontSize: '0.5rem' }}>&nbsp;</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="con-bottom-inner">
                                    <h3><span style={{ color: '#6c757d', fontWeight: 800 }}>Mobile number verification</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className='text-center mb-4'>
                                    <p style={{ margin: 0, lineHeight: 2 }}>Enter the OTP which you received on your registered mobile number <br /> ********{usermobilenolasttwo} To verify and proceed next</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-2"></div> */}
                    </div>
                </section>

                <section className='mb-5 mt-2'>
                    <div className="container">
                        <div style={{ padding: 2 }}>
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="form-group mb-3">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="" style={{ padding: '.75rem' }}><FontAwesomeIcon icon={faMobile} /></span>
                                            </div>
                                            <input type="text" className="form-control" data-inputmask="'mask': '99999 99999'" placeholder="Mobile OTP" style={{ width: '60%' }} name="mobile_otp" value={form_datas.mobile_otp} onChange={handleInputChange} maxLength={6} />
                                        </div>
                                        {form_errormsg.mobile_otp_errormsg !== '' ?
                                            <span className='error_msg'>{form_errormsg.mobile_otp_errormsg}</span> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="btn btn-secondary btn-block w-100" onClick={handelverification} >
                                                Verify
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingTop: 10 }}>
                                        <div className="col-6 social text-start">
                                            <p onClick={() => handelchangenumber_d()} className='m-0' style={{ color: "#09988e", fontWeight: 700, cursor: 'pointer' }}>Change my number</p>
                                        </div>
                                        <div className="col-6 social text-end">
                                            <p onClick={() => handelresendotp()} className='m-0' style={{ color: "#09988e", fontWeight: 700, cursor: 'pointer' }}>Resend OTP</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    {
                        error_msgs.show_success_msg === true ?
                            <>
                                <div className="container success_msg_bx">
                                    <h4>{error_msgs.success_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    {
                        error_msgs.show_error_msg === true ?
                            <>
                                <div className="container error_msg_bx">
                                    <h4>{error_msgs.error_msg}</h4>
                                </div>
                            </> :
                            null
                    }
                    <br />
                </section>

                <Modal classNames={'mobileverifymodal'} open={mobileverification} onClose={closemobileverification} center closeOnOverlayClick={false}>
                    <div className="row mt-3 mb-3">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <div className="con-bottom-inner" style={{ paddingBottom: '10px' }}>
                                <h3><span style={{ fontWeight: 700, color: '#6c757d', textAlign: "right" }}>Verify your mobile</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className='mobileverify text-center'>
                        <div className='header'>
                            <img src='/assets/img/mobile.png' />
                        </div>
                    </div>

                    {
                        showentermobileno === true ?
                            <>
                                <div className="col-12">
                                    <div className="card-body" style={{ padding: "15px" }}>
                                        <div className="form-group mb-3">
                                            <p>Enter your new Mobile number</p>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="" style={{ padding: '.75rem' }}><FontAwesomeIcon icon={faMobile} /></span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="+91" disabled />
                                                <input type="text" className="form-control" data-inputmask="'mask': '99999 99999'" placeholder="Mobile number" style={{ width: '60%' }} name="mobile_number" value={otp_form_datas.mobile_number} onChange={handleOTPInputChange} maxLength={10} />
                                            </div>
                                            {otp_form_errormsg.mobile_number_errormsg !== '' ?
                                                <span className='error_msg'>{otp_form_errormsg.mobile_number_errormsg}</span> : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-7"></div>
                                        <div className="col-5">
                                            <button type="button" onClick={() => handelonclicksendotp()} className="btn btn-secondary w-100">Send</button>
                                        </div>
                                    </div>
                                </div>

                            </>
                            :
                            <>
                                <div className="col-12">
                                    <div className="card-body"
                                        style={{ padding: "15px", color: '#6c757d' }}>
                                        <p className='selectaadon'>Get a verification code</p>
                                        <p>GServes will send a verification code to ********{usermobilenolasttwo}</p>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-7">
                                            <h6 onClick={(e) => handelchangenumber(e)} style={{ fontWeight: 700, color: '#09988e', cursor: 'pointer' }}>Change my number</h6>
                                        </div>
                                        <div className="col-5">
                                            <button type="button" onClick={() => handelonclicksendotp()} className="btn btn-secondary w-100">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Modal>



            </main>
            <FooterComp />
        </>
    );
}

export default Verification;