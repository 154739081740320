import React, { useState, useLayoutEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { axiosInstance } from '../Services';
import { Link } from "react-router-dom";

function BannerMenuComp(props) {
    const [submenus, setSubmenus] = useState([]);
    const [menudetails, setMenudetails] = useState([]);
    const [activesubmenuname, setActiveSubmenuName] = useState('');

    useLayoutEffect(() => {
        axiosInstance.get('/Menudetails')
            .then((res) => {
                setMenudetails(res.data.data);
            }).catch((error) => {
                console.log('error');
            });
    }, []);

    function showDropdown(data, name) {
        var a = document.getElementsByClassName('banner_mega_menu');
        if (data !== undefined) {
            setSubmenus(data);
            setActiveSubmenuName(name);
            a[0].classList.remove('b_none');
            a[0].classList.add('b_block');
        }
        else {
            setSubmenus([]);
            setActiveSubmenuName('');
            a[0].classList.remove('b_block');
            a[0].classList.add('b_none');
        }
    }

    const hideDropdown = e => {
        var a = document.getElementsByClassName('banner_mega_menu');
        setSubmenus([]);
        a[0].classList.remove('b_block');
        a[0].classList.add('b_none');
    }

    function handelonclickhide() {
        hideDropdown();
    }

    var settings = {
        dots: false,
        infinite: menudetails.length > 5,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        arrows: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: menudetails.length > 5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: menudetails.length > 5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: menudetails.length > 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: menudetails.length > 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: menudetails.length > 3,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: menudetails.length > 2,
                }
            }
        ]
    };

    return (
        <>
            <div className='banner_menu' onMouseLeave={hideDropdown}>
                <Slider {...settings}>
                    {
                        menudetails.map((value, index) => {
                            return (
                                <div key={index} className='banner_menu_cnt'
                                    onMouseEnter={() => showDropdown(value.submenu, value.menu_name)}>
                                    <img src={value.dept_logo} alt="menu" />
                                    <p>{value.menu_name}</p>
                                </div>
                            );
                        })
                    }
                </Slider>

                <div className='banner_mega_menu b_none' onMouseLeave={hideDropdown}>
                    <div className='banner_mega_menu_header'>
                        <Row>
                            <Col>
                                <h3 className='hed_fnt_sc'>{activesubmenuname}</h3>
                            </Col>
                        </Row>
                    </div>
                    <div className='banner_mega_menu_content '>
                        <Row>
                            {submenus.map((value, index) => {
                                return (
                                    <Col lg={4} md={6} sm={6} xs={6} key={index} style={{ paddingBottom: 20 }}>
                                        <div className='megamenu_cnt'>
                                            <Link onClick={() => handelonclickhide()} to={'/serves/' + activesubmenuname.replace(/[ ,]+/g, "-") + '/' + value.url}>
                                                {value.menu_name}
                                            </Link>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BannerMenuComp;